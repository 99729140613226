import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox from '@material-ui/core/Checkbox'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
//import ApiService from '../../services/ApiService.js';
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select/Select'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Cancel from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import Family from '@material-ui/icons/People'
import SaveIcon from '@material-ui/icons/Save'
import Process from '@material-ui/icons/Settings'
import Warn from '@material-ui/icons/Warning'
import dashboardStyles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import AnagraphyAccordion from 'components/Accordion/AnagraphyAccordion'
//import ApprofondimentoListAccordion from "components/Accordion/ApprofondimentoListAccordion.js";
import DocumentiAccordion from 'components/Accordion/DocumentiAccordion.js'
import TitolareEffettivoCustomAccordion from 'components/Accordion/TitolareEffettivoCustomAccordion.js'
//import TeeHistoryAccordion from "components/Accordion/TeeHistoryAccordion.js";
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import DetailVisualizer from 'components/COMMON/DetailVisualizer.js'
import SingleColumnDetailVisualizer from 'components/COMMON/SingleColumnDetailVisualizer.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import { axiosApiCaller } from 'layouts/AxiosService'
import PropTypes from 'prop-types'
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import * as AuthService from 'services/AuthService.js'
import Warning from '../Typography/Warning'


const localStyles = {
  allCentered: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    msTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)'
  },
  allCenteredMessage: {
    textAlign: 'center',
    height: '30px',
    lineHeigth: '30px',
    padding: '0px'
  },
  completedRound: {
    color: '#34a853'
  },
  warningRound: {
    color: '#fba706'
  },
  errorRound: {
    color: '#ea4335'
  },
  partialRound: {
    background: 'grey',
    paddingTop: '12px',
    paddingBottom: '12px'
  }
}

const styles = {
  ...localStyles,
  ...dashboardStyles
}

class CompanyReportVisualizer extends React.Component {
  constructor(props) {
    super(props)
    this.cleanedData = this.cleanData(this.props.itemForDetail)

    this.state = this.getInitialState()
    this.listaDocumenti = this.props.listaDocumenti
    this.listaDocumentiDaEliminare = []

    this.handleChangeItem = this.handleChangeItem.bind(this)
    this.handleChangeForeignOwnersInDeleteCaseFromChild = this.handleChangeForeignOwnersInDeleteCaseFromChild.bind(
      this
    )
    this.handleChangeState = this.handleChangeState.bind(this)
    this.handleChangeEvasione = this.handleChangeEvasione.bind(this)
    this.handleChangeDocumentInDeleteCaseFromChild = this.handleChangeDocumentInDeleteCaseFromChild.bind(
      this
    )
    this.handleDownloadDocumentFromChild = this.handleDownloadDocumentFromChild.bind(
      this
    )
    this.handleViewDocumentFromChild = this.handleViewDocumentFromChild.bind(
      this
    )
    this.handleChangeSocietaCollegate = this.handleChangeSocietaCollegate.bind(
      this
    )
    this.handleChangeAnagrphyForm = this.handleChangeAnagrphyForm.bind(this)
    this.createAnagraphy = this.createAnagraphy.bind(this)
    this.handleEditAnagrphyForm = this.handleEditAnagrphyForm.bind(this)
    this.handleAddAnagrphyForm = this.handleAddAnagrphyForm.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.deleteAlert = this.deleteAlert.bind(this)
    this.handleChangeMotivo = this.handleChangeMotivo.bind(this)
    this.handleChangeEsito = this.handleChangeEsito.bind(this)
    this.addItem = this.addItem.bind(this)
    this.societaCollegateList = []
    this.societaCollegateLocalList = []
    this.deleteSocietaCollegateAlert = this.deleteSocietaCollegateAlert.bind(
      this
    )
    this.deleteSocietaCollegate = this.deleteSocietaCollegate.bind(this)
    this.handleChangeNotaMancatoCalcoloTEE = this.handleChangeNotaMancatoCalcoloTEE.bind(
      this
    )
    this.handleChangeNotaMancatoCalcoloCubo = this.handleChangeNotaMancatoCalcoloCubo.bind(
      this
    )
    this.handleChangeAccordo = this.handleChangeAccordo.bind(
      this
    )
    this.successAutoClose = this.successAutoClose.bind(this)

    // this.anagrphyList= []
    //this.getData();
  }

  /*
    getData() {
        ApiService().getAllTeeDiscoveredSubjectsByPlatformId(this.props.itemForDetail.platformId).then((res) => {
            if (res.data.success === true) {
                this.setState({ teeDiscoveredSubjectsHistories: res.data.payload });
            }
        })

        ApiService().getAllApprofondimentoRequestsByPlatformId(this.props.itemForDetail.platformId).then((res) => {
            if (res.data.success === true) {
                this.setState({ listApprofondimentoRequestsByPlatformId: res.data.payload });
            }
        })
    }
    */

  getInitialState() {
    return {
      errorMessage: this.props.errorMessage,
      imageIndex: -1,
      editMode: false,
      documentazione: this.cleanedData.documentazione
        ? this.cleanedData.documentazione
        : "",
      teeDiscoveredSubjectsHistories: [],
      listApprofondimentoRequestsByPlatformId: [],
      listaDocumenti: this.cleanedData.documenti
        ? this.cleanedData.documenti
        : [],
      isListaDocumentiChanged: false,
      pdfData: this.props.pdfData,
      pk: null,
      ragione_sociale: null,
      forma_giuridica: null,
      descrizione_forma_giuridica: null,
      piva: null,
      indirizzo: null,
      stato: null,
      citta: null,
      post_code: null,
      province: null,
      anagrphyList: [],
      anagraphy: null,
      alert: null,
      esito: 'OK',
      societaCollegateTmp: [],
      // motivo:null,
      // modalita_evasione:null

      societaCollegateLivello: null,
      societaCollegateAnagraphy: null,
      societaCollegatePercentage: null,
      currentSelectedAnagaphy: null,
      currentSelectedAnagaphyObject: null,
      societaCollegateList: [],
      societaCollegateLocalList: [],
      itemAlreadyAddedMessage: '',
      teeDiscoveredJuridicalSubject: '',
      cleanedData: this.cleanData(this.props.itemForDetail),
      listaDifferenze: [],
      tuplaTemporanea: false
    }
  }

  handleChangeForeignOwnersInDeleteCaseFromChild(newList) {
    this.cleanedData.foreignActualOwners = newList
  }

  getItemForDetail() {
    return this.props.itemForDetail
  }

  cleanData() {
    let theData = this.props.itemForDetail
    return theData
  }

  componentDidMount() {
    debugger;
    axiosApiCaller
      .get(
        AuthService.getTeeDiscoveredSubjectJHistoryByPlatformIdAndIdRichiesta(),
        {
          params: {
            idRichiesta: this.cleanedData.idRichiesta,
            platformId: this.cleanedData.platformId
          }
        }
      )
      .then(res => {
        this.setState({
          teeDiscoveredJuridicalSubject: res.data.payload,
          anagrphyList: res.data.payload.anagrafiche
        })
        this.setState({
          societaCollegateList: res.data.payload.societaCollegateList
        });
        this.societaCollegateList = res.data.payload.societaCollegateList
      })
      .catch(error => {
        console.log(error)
        console.log('called')
      })

    axiosApiCaller
      .post(
        AuthService.getAllTeeDiscoveredSubjectsByPlatformId() + '?platformId=' + this.cleanedData.platformId + '&idRichiesta=' + this.cleanedData.idRichiesta
      )
      .then(res => {
        this.setState({ listaDifferenze: res.data.payload.differenze });
        this.setState({ tuplaTemporanea: res.data.payload.tuplaTemporanea });
      })
      .catch(error => {
        console.log(error)
        console.log('called')
      })
  }



  componentWillUnmount() { }

  componentDidUpdate(nextProps) {
    if (nextProps.listaDocumenti !== this.props.listaDocumenti) {
      this.setState({ listaDocumenti: this.props.listaDocumenti })
      this.setState({
        isListaDocumentiChanged: !this.state.isListaDocumentiChanged
      })
    }
    if (nextProps.ispdfDataChanged !== this.props.ispdfDataChanged) {
      this.setState({ pdfData: this.props.pdfData })
    }

    if (nextProps.itemForDetail !== this.props.itemForDetail) {
      this.setState({ itemForDetail: this.props.itemForDetail })
    }
  }

  handleChangeSocietaCollegate(event) {
    if (event.target.name == 'societaCollegate') {
      this.cleanedData.societaCollegate = event.target.value
    }
  }

  handleChangeDocumentInDeleteCaseFromChild(newList, documentoDaEliminare) {
    //this.cleanedData.foreignActualOwners = newList;
    this.setState({ listaDocumenti: newList })
    this.setState({
      isListaDocumentiChanged: !this.state.isListaDocumentiChanged
    })
    this.listaDocumentiDaEliminare.push(documentoDaEliminare)
  }

  handleViewDocumentFromChild(newList, documentoDaScaricare) {
    this.setState({ pdfData: null })
    this.props.click(documentoDaScaricare)
  }

  handleDownloadDocumentFromChild(newList, documentoDaScaricare) {
    //this.setState({pdfData: null});
    this.props.download(documentoDaScaricare)
  }

  handleChangeItem(event) {
    if (event.target.id == 'partitaIva') {
      this.cleanedData.partitaIva = event.target.value
    } else if (event.target.id == 'indirizzo') {
      this.cleanedData.indirizzo = event.target.value
    } else if (event.target.id == 'localita') {
      this.cleanedData.localita = event.target.value
    } else if (event.target.id == 'cap') {
      this.cleanedData.cap = event.target.value
    } else if (event.target.id == 'provincia') {
      this.cleanedData.paeseProvincia = event.target.value
    } else if (event.target.id == 'note') {
      this.cleanedData.note = event.target.value
    } else if (event.target.id == 'robotId') {
      this.cleanedData.robotId = event.target.value
    } else if (event.target.id == 'documentazione') {
      this.cleanedData.documentazione = event.target.checked
      this.setState({ documentazione: event.target.checked }) //Cambiamento di stato
    } else if (event.target.id == 'numeroMonitoraggi') {
      this.cleanedData.numeroMonitoraggi = event.target.value
    }
  }

  formatSessionDateTime(dateTime) {
    var date = new Date(dateTime)
    if (date instanceof Date && isFinite(date)) {
      return new Intl.DateTimeFormat('it', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }).format(date)
    } else {
      return dateTime
    }
  }

  handleChangeState(event) {
    this.cleanedData.elaborationState = event.target.value
    this.setState({ editMode: this.state.editMode }) //Cambiamento fittizio
  }

  handleChangeEvasione(event) {
    if (event.target.value == 'NESSUNA') {
      this.cleanedData['modalita_evasione'] = null
    } else {
      this.cleanedData['modalita_evasione'] = event.target.value
    }
    this.setState({ editMode: this.state.editMode })
  }

  handleChangeMotivo(event) {
    if (event.target.value == 'NESSUNO') {
      this.cleanedData['motivo'] = null
    } else {
      this.cleanedData['motivo'] = event.target.value
    }

    this.setState({ editMode: this.state.editMode })
  }
  handleChangeEsito(event) {
    this.cleanedData['esito'] = event.target.value
    this.setState({ editMode: this.state.editMode })
  }

  handleChangeNotaMancatoCalcoloTEE(event) {
    if (event.target.value == 'NESSUNO') {
      this.cleanedData['notaMancatoCalcoloTEE'] = null
    } else {
      this.cleanedData['notaMancatoCalcoloTEE'] = event.target.value
    }

    this.setState({ editMode: this.state.editMode })
  }

  handleChangeNotaMancatoCalcoloCubo(event) {
    if (event.target.value === 'NESSUNO') {
      this.cleanedData['notaMancatoCalcoloCubo'] = null
    } else {
      this.cleanedData['notaMancatoCalcoloCubo'] = event.target.value
    }

    this.setState({ editMode: this.state.editMode })
  }

  handleChangeAccordo(event) {
    if (event.target.value === 'NESSUNO') {
      this.cleanedData['accordo'] = null
    } else {
      this.cleanedData['accordo'] = event.target.value
    }

    this.setState({ editMode: this.state.editMode })
  }
  // componentDidMount() {
  //     ReactDOM.findDOMNode(this).scrollIntoView();
  // }

  // deleteSocietaAlert(key, index,foreignOwner){
  //     this.setState({
  //         alert:
  //       <SweetAlert
  //         danger
  //         style={{ display: "block", marginTop: "140px" }}
  //         title="Questa Società è già collegata con uno o più beneficiari esteri"
  //         customButtons={
  //             <React.Fragment>
  //                 <div style={{ margin: 'auto', textAlign: "center" }}>

  //                     <Button
  //                         style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
  //                         color="success"
  //                         // onClick={() => { hideAlert() }}
  //                         round>
  //                         <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
  //           </Button>
  //                 </div>
  //             </React.Fragment>
  //         }
  //     >
  //         elimina prima i Titolari effettivi esteri collegati a questa Società
  //        <br />
  //     </SweetAlert>})
  // }

  render() {
    const { classes } = this.props
    this.cleanedData = this.cleanData(this.props.itemForDetail)
    this.societaCollegateList = []

    this.cleanedData.foreignActualOwners.map((prop, key) => {
      this.state.societaCollegateTmp = []
      let tmp = []
      if (prop.catenaSocietaCollegate != null) {
        prop.catenaSocietaCollegate.map(societaCollegate => {
          tmp.push({
            societaCollegateLivello: societaCollegate.livello,
            societaCollegatePercentage: '' + societaCollegate.shares,
            societaCollegateAnagraphy: societaCollegate
          })
        })
      }
      tmp.sort((a, b) =>
        a.societaCollegateLivello > b.societaCollegateLivello ? 1 : -1
      )
      tmp.push({
        societaCollegateLivello: 0,
        societaCollegatePercentage: '0',
        societaCollegateAnagraphy: {}
      })
      this.societaCollegateLocalList[key] = tmp
    })

    let monitoraggioInfo = null

    if (this.cleanedData.monitoraggioFlag === true) {
      monitoraggioInfo = (
        <h4
          style={{ margin: '0px 0px 15px 0px' }}
          className={classes.activatedCardTitle}
        >
          <b>Monitoraggio Attivo</b>
        </h4>
      )
    } else {
      monitoraggioInfo = (
        <h4
          style={{ margin: '0px 0px 15px 0px' }}
          className={classes.deactivatedCardTitle}
        >
          <b>Monitoraggio Non Attivo</b>
        </h4>
      )
    }

    let elaborationState = ''
    if (this.cleanedData.elaborationState == 'COMPLETATA') {
      elaborationState = (
        <div>
          <div style={localStyles.completedRound}>
            <b> {this.cleanedData.elaborationState}</b>
          </div>
        </div>
      )
    } else if (this.cleanedData.elaborationState == 'APPROFONDIRE') {
      elaborationState = (
        <div>
          <div style={localStyles.warningRound}>
            <b> {this.cleanedData.elaborationState}</b>
          </div>
        </div>
      )
    } else if (this.cleanedData.elaborationState == 'ERRORE') {
      elaborationState = (
        <div>
          <div style={localStyles.errorRound}>
            <b>{this.cleanedData.elaborationState}</b>
          </div>
        </div>
      )
    } else if (this.cleanedData.elaborationState == 'ELABORAZIONE') {
      elaborationState = (
        <div>
          <div style={localStyles.partialRound}>
            <b style={{ color: 'white' }}>
              {this.cleanedData.elaborationState}
            </b>
          </div>
        </div>
      )
    } else {
      elaborationState = this.cleanedData.elaborationState
    }

    let labelMarginLeft = ''
    let labelBackgroundColor = ''
    let labelBorder = ''
    switch (this.cleanedData.elaborationState) {
      case 'COMPLETATA':
        labelMarginLeft = '86%'
        labelBackgroundColor = 'rgb(52, 168, 83)'
        labelBorder = '1px solid rgb(34, 112, 55)'
        break
      case 'APPROFONDIRE':
        labelMarginLeft = '88%'
        labelBackgroundColor = 'rgb(255, 152, 0)'
        labelBorder = '1px solid rgb(170, 101, 0)'
        break
      case 'ERRORE':
        labelMarginLeft = '90%'
        labelBackgroundColor = 'rgb(215, 2, 6)'
        labelBorder = '1px solid rgb(143, 1, 4)'
        break
      case 'ELABORAZIONE':
        labelMarginLeft = '81%'
        labelBackgroundColor = 'rgb(178, 178, 178)'
        labelBorder = '1px solid rgb(118, 118, 118)'
        break
      case 'RICHIESTA':
        labelMarginLeft = '84%'
        labelBackgroundColor = 'rgb(23, 105, 255)'
        labelBorder = '1px solid rgb(15, 70, 170)'
        break
      case 'SOSPESA':
        labelMarginLeft = '88%'
        labelBackgroundColor = 'rgb(103, 17, 141)'
        labelBorder = '1px solid rgb(68, 11, 94)'
        break
      case 'ANNULLATA':
        labelMarginLeft = '88%'
        labelBackgroundColor = 'rgb(5, 23, 56)'
        labelBorder = '1px solid rgb(3, 15, 37)'
        break
    }

    let labelStyle = {
      display: 'inline-block',
      display: 'inline-block',
      marginLeft: labelMarginLeft,
      padding: '6px',
      padding: '6px',
      background: labelBackgroundColor,
      border: labelBorder,
      borderRadius: '4px'
    }
    const rightDetailsColumn = [
      {
        // Anagraphical data section
        inverted: false,
        badgeColor: 'success',
        badgeIcon: this.props.mainIcon,
        title: 'Dati Anagrafici',
        titleColor: 'success',
        body: (
          <div>
            <h6 style={labelStyle} className={classes.cardTitle}>
              <b style={{ color: 'white' }}>
                {' '}
                {this.cleanedData.elaborationState}
              </b>
            </h6>
            <div>
              <h3
                style={{ margin: '0px 0px 5px 0px', display: 'inline-block' }}
                className={classes.cardTitle}
              >
                <b>
                  <span>{this.cleanedData.ragioneSociale}</span>
                </b>
              </h3>
              {/*<span style={{ position: 'relative', bottom: '4px' }}><SmallMenu ragioneSociale={this.cleanedData.ragioneSociale} /></span> */}
            </div>
            {(() => {
              if (!this.props.editMode) {
                return (
                  <div>
                    <h6 className={classes.cardTitle}>
                      <b>Partita iva</b>: {this.cleanedData.partitaIva}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Sede</b>:{' '}
                      {this.cleanedData.indirizzo +
                        ', ' +
                        this.cleanedData.localita +
                        ', ' +
                        this.cleanedData.cap +
                        ', ' +
                        this.cleanedData.paeseProvincia}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Note</b>: {this.cleanedData.note}
                    </h6>

                    <h6 className={classes.cardTitle}>
                      <b>Stato elaborazione</b>:{' '}
                      {this.cleanedData.elaborationState}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Modalita evasione</b>:{' '}
                      {this.cleanedData.modalita_evasione}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Esito</b>: {this.cleanedData.esito}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Motivo</b>: {this.cleanedData.motivo}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Nota Mancato Calcolo TEE</b>:{' '}
                      {this.cleanedData.notaMancatoCalcoloTEE}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Nota Mancato Calcolo Cubo</b>:{' '}
                      {this.cleanedData.notaMancatoCalcoloCubo}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Accordo</b>:{' '}
                      {this.cleanedData.accordo}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>RobotId</b>:{' '}
                      {this.cleanedData.robotId}
                    </h6>
                    <h6 className={classes.cardTitle}>
                      <b>Documentazione</b>:{' '}
                      {this.cleanedData.documentazione
                        ? this.cleanedData.documentazione == true
                          ? 'Sì'
                          : 'No'
                        : 'No'}
                    </h6>

                    {this.cleanedData.numeroMonitoraggi != 0 &&
                      <h6 className={classes.cardTitle}>
                        <b>Numero Monitoraggi</b>:{' '}
                        {this.cleanedData.numeroMonitoraggi}
                      </h6>
                    }
                  </div>
                )
              } else {
                return (
                  <div>
                    <div>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='partitaIva'
                        label='Partita IVA'
                        defaultValue={this.cleanedData.partitaIva}
                        onChange={this.handleChangeItem}
                      />
                    </div>{' '}
                    <br></br>
                    <div>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='indirizzo'
                        label='Indirizzo'
                        defaultValue={this.cleanedData.indirizzo}
                        onChange={this.handleChangeItem}
                      />
                    </div>
                    <br></br>
                    <div className={classes.cardTitle}>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='localita'
                        label='Località'
                        defaultValue={this.cleanedData.localita}
                        onChange={this.handleChangeItem}
                      />
                    </div>
                    <br></br>
                    <div>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='cap'
                        label='CAP'
                        defaultValue={this.cleanedData.cap}
                        onChange={this.handleChangeItem}
                      />
                    </div>
                    <br></br>
                    <div>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='provincia'
                        label='Provincia o Nazione'
                        defaultValue={this.cleanedData.paeseProvincia}
                        onChange={this.handleChangeItem}
                      />
                    </div>
                    <br></br>
                    <div>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='note'
                        label='Note'
                        multiline
                        defaultValue={this.cleanedData.note}
                        rows={4}
                        onChange={this.handleChangeItem}
                        variant='outlined'
                      />
                    </div>
                    <br></br>
                    <div style={{ margin: '2%' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Stato elaborazione
                            </InputLabel>
                            <Select
                              id="statoElaborazione"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.cleanedData.elaborationState}
                              onChange={this.handleChangeState}
                              formControlProps={{
                                fullWidth: true
                              }}
                            >
                              <MenuItem
                                key={1}
                                id="APPROFONDIRE"
                                value={'APPROFONDIRE'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'APPROFONDIRE'}
                              </MenuItem>
                              <MenuItem
                                key={2}
                                id="ERRORE"
                                value={'ERRORE'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'ERRORE'}
                              </MenuItem>
                              <MenuItem
                                key={3}
                                id="ELABORAZIONE"
                                value={'ELABORAZIONE'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'ELABORAZIONE'}
                              </MenuItem>
                              <MenuItem
                                key={4}
                                id="COMPLETATA"
                                value={'COMPLETATA'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'COMPLETATA'}
                              </MenuItem>
                              <MenuItem
                                key={5}
                                id="RICHIESTA"
                                value={'RICHIESTA'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'RICHIESTA'}
                              </MenuItem>

                              <MenuItem
                                key={7}
                                id="ANNULLATA"
                                value={'ANNULLATA'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'ANNULLATA'}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>{' '}
                    </div>
                    <div style={{ margin: '2%' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Modalita evasione
                            </InputLabel>
                            <Select
                              id="modalitaEvasione"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.cleanedData.modalita_evasione}
                              onChange={this.handleChangeEvasione}
                              formControlProps={{
                                fullWidth: true
                              }}
                            >
                              <MenuItem
                                key={1}
                                value={'AUTOMATICA'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'AUTOMATICA'}
                              </MenuItem>
                              <MenuItem
                                key={2}
                                value={'OPERATORE_SEMPLICE'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'OPERATORE_SEMPLICE'}
                              </MenuItem>
                              <MenuItem
                                key={3}
                                value={'OPERATORE_COMPLESSO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'OPERATORE_COMPLESSO'}
                              </MenuItem>
                              <MenuItem
                                key={4}
                                value={'SCARTO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'SCARTO '}
                              </MenuItem>
                              <MenuItem
                                key={5}
                                value={'NESSUNA'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NESSUNA'}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div style={{ margin: '2%' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Esito
                            </InputLabel>
                            <Select
                              id="esito"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.cleanedData.esito}
                              onChange={this.handleChangeEsito}
                              formControlProps={{
                                fullWidth: true
                              }}
                            >
                              <MenuItem
                                key={1}
                                value={'OK'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'OK'}
                              </MenuItem>

                              <MenuItem
                                key={1}
                                value={'KO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'KO'}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div style={{ margin: '2%' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Motivo
                            </InputLabel>
                            <Select
                              id="motivo"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.cleanedData.motivo}
                              onChange={this.handleChangeMotivo}
                              formControlProps={{
                                fullWidth: true
                              }}
                            >
                              <Tooltip
                                title='(Dati insufficienti)'
                                key={1}
                                value={'DI'}
                              >
                                <MenuItem
                                  key={1}
                                  value={'DI'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'DI'}
                                </MenuItem>
                              </Tooltip>

                              <Tooltip
                                title='(Stato non Coperto)'
                                key={2}
                                value={'SNC'}
                              >
                                <MenuItem
                                  key={2}
                                  value={'SNC'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'SNC'}
                                </MenuItem>
                              </Tooltip>
                              <Tooltip
                                title='(Posizione non trovata)'
                                key={3}
                                value={'PNT'}
                              >
                                <MenuItem
                                  key={3}
                                  value={'PNT'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'PNT'}
                                </MenuItem>
                              </Tooltip>

                              <Tooltip
                                title='(Più di un candidato)'
                                key={4}
                                value={'PUC'}
                              >
                                <MenuItem
                                  key={4}
                                  value={'PUC'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'PUC'}
                                </MenuItem>
                              </Tooltip>

                              <Tooltip
                                title='(Altro)'
                                key={5}
                                value={'AL'}
                              >
                                <MenuItem
                                  key={5}
                                  value={'AL'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'AL'}
                                </MenuItem>
                              </Tooltip>
                              <Tooltip title='(Società radiata)' key={6} value={'RAD'}>
                                <MenuItem
                                  key={6}
                                  value={'RAD'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'RAD'}
                                </MenuItem>
                              </Tooltip>
                              <Tooltip title='(Società in liquidazione)' key={7} value={'LIQ'}>
                                <MenuItem
                                  key={7}
                                  value={'LIQ'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'LIQ'}
                                </MenuItem>
                              </Tooltip>
                              <Tooltip title='(Cambio di denominazione)' key={8} value={'DEN'}>
                                <MenuItem
                                  key={8}
                                  value={'DEN'}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                >
                                  {'DEN'}
                                </MenuItem>
                              </Tooltip>
                              <MenuItem
                                key={10}
                                value={'NESSUNO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NESSUNO'}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div style={{ margin: '2%' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Nota Mancato Calcolo TEE
                            </InputLabel>
                            <Select
                              id="notaMancatoCalcoloTEE"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.cleanedData.notaMancatoCalcoloTEE}
                              onChange={this.handleChangeNotaMancatoCalcoloTEE}
                              formControlProps={{
                                fullWidth: true
                              }}
                            >
                              <MenuItem
                                key={1}
                                value={'NO_SOCI'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NO_SOCI'}
                              </MenuItem>
                              <MenuItem
                                key={2}
                                value={'SOCI_MINORI_25'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'SOCI_MINORI_25'}
                              </MenuItem>
                              <MenuItem
                                key={3}
                                value={'ALTRO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'ALTRO'}
                              </MenuItem>
                              <MenuItem
                                key={4}
                                value={'NESSUNO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NESSUNO'}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div style={{ margin: '2%' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              Nota Mancato Calcolo Cubo
                            </InputLabel>
                            <Select
                              id="notaMancatoCalcoloCubo"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.cleanedData.notaMancatoCalcoloCubo}
                              onChange={this.handleChangeNotaMancatoCalcoloCubo}
                              formControlProps={{
                                fullWidth: true
                              }}
                            >
                              <MenuItem
                                key={1}
                                value={'NO_PART'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NO_PART'}
                              </MenuItem>
                              <MenuItem
                                key={2}
                                value={'NO_SOCI'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NO_SOCI'}
                              </MenuItem>
                              <MenuItem
                                key={3}
                                value={'ALTRO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'ALTRO'}
                              </MenuItem>
                              <MenuItem
                                key={4}
                                value={'NESSUNO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NESSUNO'}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div style={{ margin: '2%' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                              ACCORDO
                            </InputLabel>
                            <Select
                              id="accordo"
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.cleanedData.accordo}
                              onChange={this.handleChangeAccordo}
                              formControlProps={{
                                fullWidth: true
                              }}
                            >
                              <MenuItem
                                key={1}
                                value={'PATTO_PARASOCIALE'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'PATTO_PARASOCIALE'}
                              </MenuItem>
                              <MenuItem
                                key={2}
                                value={'TRUSTEE'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'TRUSTEE'}
                              </MenuItem>
                              <MenuItem
                                key={3}
                                value={'NESSUNO'}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                              >
                                {'NESSUNO'}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='robotId'
                        label='RobotId'
                        defaultValue={this.cleanedData.robotId}
                        onChange={this.handleChangeItem}
                      />
                    </div>
                    <br></br>
                    <FormControlLabel
                      style={{ float: 'left', margin: '2%' }}
                      checked={this.cleanedData.documentazione}
                      control={
                        <Checkbox
                          id='documentazione'
                          color='primary'
                          onChange={this.handleChangeItem}
                        />
                      }
                      label='Documentazione'
                      labelPlacement='start'
                    />
                    <div>
                      <TextField
                        style={{ width: '80%', float: 'left', margin: '2%' }}
                        id='numeroMonitoraggi'
                        label='NumeroMonitoraggi'
                        defaultValue={this.cleanedData.numeroMonitoraggi}
                        onChange={this.handleChangeItem}
                      />
                    </div>
                  </div>

                )
              }
            })()}
          </div>
        )
      }
    ]

    const leftDetailsColumn = [
      {
        inverted: false,
        badgeColor: 'danger',
        badgeIcon: Process,
        title: 'Dati di Processo',
        titleColor: 'warning',
        body: (
          <div>
            {monitoraggioInfo}
            <h6 className={classes.cardTitle}>
              <b>Monitoraggio</b>: {this.cleanedData.monitoraggio}
            </h6>
            <h6 className={classes.cardTitle}>
              <b>Data di aggiornamento</b>:{' '}
              {this.formatSessionDateTime(this.cleanedData.dataAggiornamento)}
            </h6>
          </div>
        )
      }
    ]

    const ownersDetailsColumn = [
      {
        inverted: false,
        badgeColor: 'warning',
        badgeIcon: Family,
        title: 'Soci e Titolari',
        titleColor: 'danger',
        body: (
          <Card>
            <CardHeader>
              <h3 className={classes.cardTitle}>
                <b>Titolari effettivi esteri</b>
              </h3>
              {this.props.editMode && (
              <div
                          style={{
                            display: 'flex',
                            width: '99%',
                            boxShadow:
                              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                            marginBottom: '15px',
                            paddingRight: '16px'
                          }}
                        >
                          <FontAwesomeIcon
                            color='green'
                            icon={faPlusSquare}
                            size='2x'
                            style={{
                              display: 'inline',
                              float: 'left',
                              paddingRight: '10px',
                              paddingLeft: '10px',
                              paddingTop: '18px'
                            }}
                            disabled
                          />

                          <div
                            style={{
                              float: 'left',
                              display: 'flex',
                              width: '100%',
                              marginBottom: '10px'
                            }}
                          >
                            <TextField
                              type='number'
                              name='societaCollegateLivello'
                              value={this.state.societaCollegateLivello}
                              label='Livello'
                              style={{ width: '10%', marginLeft: '2%' }}
                              onChange={event =>
                                this.changeSocietaCollegateForm(event)
                              }
                            />
                            <Select
                              name='societaCollegateAnagraphy'
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.state.currentSelectedAnagaphy}
                              onChange={event =>
                                this.changeSocietaCollegateForm(event)
                              }
                              formControlProps={{
                                fullWidth: true
                              }}
                              style={{ width: '40%', marginLeft: '2%' }}
                            >
                              {this.state.anagrphyList != null
                                ? this.state.anagrphyList.map(
                                  (value, index) => {
                                    return (
                                      <MenuItem
                                        id={index}
                                        value={value}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected
                                        }}
                                      >
                                        {' '}
                                        {value.ragione_sociale}{' '}
                                      </MenuItem>
                                    )
                                  }
                                )
                                : null}
                            </Select>
                            <TextField
                              name='societaCollegatePercentage'
                              value={this.state.societaCollegatePercentage}
                              style={{ width: '40%', marginLeft: '2%' }}
                              label='%'
                              onChange={event =>
                                this.changeSocietaCollegateForm(event)
                              }
                            />

                            <SaveIcon
                              style={{
                                color: `${this.state.societaCollegateLivello != null &&
                                  this.state.societaCollegateLivello != '' &&
                                  this.state.currentSelectedAnagaphy != null &&
                                  this.state.currentSelectedAnagaphy != '' &&
                                  this.state.societaCollegatePercentage !=
                                  null &&
                                  this.state.societaCollegatePercentage != ''
                                  ? 'green'
                                  : 'gray'
                                  }`,
                                marginTop: '18px',
                                float: 'right',
                                position: 'relative',
                                marginRight: '6px',
                                marginLeft: '14px'
                              }}
                              onClick={
                                this.state.societaCollegateLivello == null ||
                                  this.state.societaCollegateLivello == '' ||
                                  this.state.currentSelectedAnagaphy == null ||
                                  this.state.currentSelectedAnagaphy == '' ||
                                  this.state.societaCollegatePercentage == null ||
                                  this.state.societaCollegatePercentage == ''
                                  ? null
                                  : () => this.addItem()
                              }
                            ></SaveIcon>
                          </div>
                        </div>)}
              {/* end global Tee */}
              
            </CardHeader>
            <CardBody>
              {(() => {
                if (
                  this.cleanedData.societaCollegateList.length > 0 || this.cleanedData.foreignActualOwners.length > 0
                ) {
                  return (
                    <div>
                      {this.cleanedData.societaCollegateList.map((value, index) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              width: '99%',
                              boxShadow:
                                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                              marginBottom: '10px',
                              padding: '8px'
                            }}
                          >
                            <div
                              style={{
                                float: 'left',
                                display: 'flex',
                                width: '100%',
                                padding: '5px'
                              }}
                            >
                              <label>Livello</label>
                              <input
                                id={index}
                                label='Livello'
                                type='number'
                                name='societaCollegateLivello'
                                disabled
                                value={value.livello}
                                style={{
                                  width: '5%',
                                  marginLeft: '2%',
                                  border: 'none',
                                  height: '16px'
                                }}
                              />
                              <label>Ragione sociale</label>
                              <input
                                id={index}
                                name='societaCollegateAnagraphy'
                                disabled
                                value={
                                  value.ragione_sociale
                                    ? value.ragione_sociale
                                    : value.ragioneSociale
                                }
                                style={{
                                  width: '31%',
                                  marginLeft: '2%',
                                  border: 'none'
                                }}
                              />
                              <label>Share</label>{' '}
                              <input
                                id={index}
                                name='societaCollegatePercentage'
                                disabled
                                style={{
                                  width: 'auto',
                                  marginLeft: '2%',
                                  border: 'none',
                                  width: `${this.props.editMode ? '25%' : '31%'
                                    }`
                                }}
                                value={value.shares}
                              />
                              {this.props.editMode ? (
                                <IconButton
                                  color='secondary'
                                  aria-label='elimina'
                                  style={{ padding: '0px', marginLeft: '10px' }}
                                >
                                  <DeleteIcon
                                    onClick={event =>
                                      this.deleteSocietaCollegateAlert(
                                        event,
                                        index
                                      )
                                    }
                                  />
                                </IconButton>
                              ) : null}
                            </div>
                          </div>
                        )
                      })}

                      {/* global TEE */}

                      {/* {this.props.editMode ? (
                        // <div style={{ display: "flex", width: "100%" }}>
                        <div
                          style={{
                            display: 'flex',
                            width: '99%',
                            boxShadow:
                              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                            marginBottom: '15px',
                            paddingRight: '16px'
                          }}
                        >
                          <FontAwesomeIcon
                            color='green'
                            icon={faPlusSquare}
                            size='2x'
                            style={{
                              display: 'inline',
                              float: 'left',
                              paddingRight: '10px',
                              paddingLeft: '10px',
                              paddingTop: '18px'
                            }}
                            disabled
                          />

                          <div
                            style={{
                              float: 'left',
                              display: 'flex',
                              width: '100%',
                              marginBottom: '10px'
                            }}
                          >
                            <TextField
                              type='number'
                              name='societaCollegateLivello'
                              value={this.state.societaCollegateLivello}
                              label='Livello'
                              style={{ width: '10%', marginLeft: '2%' }}
                              onChange={event =>
                                this.changeSocietaCollegateForm(event)
                              }
                            />
                            <Select
                              name='societaCollegateAnagraphy'
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              displayEmpty
                              value={this.state.currentSelectedAnagaphy}
                              onChange={event =>
                                this.changeSocietaCollegateForm(event)
                              }
                              formControlProps={{
                                fullWidth: true
                              }}
                              style={{ width: '40%', marginLeft: '2%' }}
                            >
                              {this.state.anagrphyList != null
                                ? this.state.anagrphyList.map(
                                  (value, index) => {
                                    return (
                                      <MenuItem
                                        id={index}
                                        value={value}
                                        classes={{
                                          root: classes.selectMenuItem,
                                          selected:
                                            classes.selectMenuItemSelected
                                        }}
                                      >
                                        {' '}
                                        {value.ragione_sociale}{' '}
                                      </MenuItem>
                                    )
                                  }
                                )
                                : null}
                            </Select>
                            <TextField
                              name='societaCollegatePercentage'
                              value={this.state.societaCollegatePercentage}
                              style={{ width: '40%', marginLeft: '2%' }}
                              label='%'
                              onChange={event =>
                                this.changeSocietaCollegateForm(event)
                              }
                            />

                            <SaveIcon
                              style={{
                                color: `${this.state.societaCollegateLivello != null &&
                                  this.state.societaCollegateLivello != '' &&
                                  this.state.currentSelectedAnagaphy != null &&
                                  this.state.currentSelectedAnagaphy != '' &&
                                  this.state.societaCollegatePercentage !=
                                  null &&
                                  this.state.societaCollegatePercentage != ''
                                  ? 'green'
                                  : 'gray'
                                  }`,
                                marginTop: '18px',
                                float: 'right',
                                position: 'relative',
                                marginRight: '6px',
                                marginLeft: '14px'
                              }}
                              onClick={
                                this.state.societaCollegateLivello == null ||
                                  this.state.societaCollegateLivello == '' ||
                                  this.state.currentSelectedAnagaphy == null ||
                                  this.state.currentSelectedAnagaphy == '' ||
                                  this.state.societaCollegatePercentage == null ||
                                  this.state.societaCollegatePercentage == ''
                                  ? null
                                  : () => this.addItem()
                              }
                            ></SaveIcon>
                          </div>
                        </div>
                      ) : null} */}
                      <p style={{ float: 'left', color: 'red' }}>
                        {this.state.itemAlreadyAddedMessage}
                      </p>
                    </div>
                  )
                }
              })()}

              {this.state.cleanedData.foreignActualOwners != undefined
                ? this.state.cleanedData.foreignActualOwners.map(
                  (foreignActualOwner, index) => {
                    return (
                      <ExpansionPanel id={`tee${index}`} defaultExpanded={false}>
                        <ExpansionPanelSummary
                          classes={{
                            content: classes.expansionPanelSummaryContent,
                            expandIcon:
                              classes.expansionPanelSummaryExpandIcon
                          }}
                        // style={{minHeight:"10px",height:"28px"}}
                        >
                          <h6 style={{ display: 'inline', float: 'right' }}>
                            {foreignActualOwner.completeName}
                          </h6>
                        </ExpansionPanelSummary>

                        <TitolareEffettivoCustomAccordion
                          active={0}
                          editMode={this.props.editMode}
                          pdfData={this.state.pdfData}
                          foreignActualOwner={foreignActualOwner}
                          anagrphyList={this.state.anagrphyList}
                          societaCollegateLocalList={
                            this.societaCollegateLocalList
                          }
                          listener={this}
                          index={index}
                        />
                      </ExpansionPanel>
                    )
                  }
                )
                : null}

              {this.props.editMode ? (
                <ExpansionPanel id="aggiungiNuovoTEEParent" defaultExpanded={false}>
                  <ExpansionPanelSummary
                    classes={{
                      content: classes.expansionPanelSummaryContent,
                      expandIcon: classes.expansionPanelSummaryExpandIcon
                    }}
                  >
                    <FontAwesomeIcon
                      color='green'
                      icon={faPlusSquare}
                      size='3x'
                      style={{
                        display: 'inline',
                        float: 'left',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                        paddingTop: '10px'
                      }}
                    />
                    <h4 id="aggiungiNuovoTEE" style={{ display: 'inline', float: 'right' }}>
                      {' '}
                      Aggiungi Nuovo titolare estero effettivo
                    </h4>
                  </ExpansionPanelSummary>

                  <TitolareEffettivoCustomAccordion
                    id="aggiungiNuovoTEEAccordion"
                    editMode={this.props.editMode}
                    anagrphyList={this.state.anagrphyList}
                    // foreignActualOwner={{}}
                    listener={this}
                    index={-1}
                  />
                </ExpansionPanel>
              ) : null}
            </CardBody>
          </Card>
        )
      }
    ]

    const ownersAnagraphyColumn = [
      {
        inverted: false,
        badgeColor: 'warning',
        badgeIcon: Family,
        title: 'Soci e Titolari',
        titleColor: 'danger',
        body: (
          <Card>
            <CardHeader>
              <h3 className={classes.cardTitle}>
                <b>Società</b>
              </h3>
            </CardHeader>
            <CardBody>
              <AnagraphyAccordion
                editMode={this.props.editMode}
                collapses={this.state.anagrphyList}
                handleChangeAnagrphyForm={this.handleChangeAnagrphyForm}
                createAnagraphy={this.createAnagraphy}
                listener={this}
                tipoDocumentiList={this.props.tipoDocumentiList}
              />

              {/* {this.props.editMode ? <AnagraphyAccordion
                                editMode={this.props.editMode}
                                collapses={this.state.anagrphyList}
                                handleChangeAnagrphyForm={this.handleChangeAnagrphyForm}
                                createAnagraphy={this.createAnagraphy}
                                listener={this}
                                mode="add"
                                addMode={true}
                            /> :  <AnagraphyAccordion
                            // active={0}
                            editMode={this.props.editMode}
                            collapses={this.state.anagrphyList}
                            handleChangeAnagrphyForm={this.handleChangeAnagrphyForm}
                            createAnagraphy={this.createAnagraphy}
                            listener={this}
                        />} */}
            </CardBody>
          </Card>
        )
      }
    ]

    const listaDifferenze = [
      {
        inverted: false,
        badgeColor: 'warning',
        badgeIcon: Family,
        title: 'Variazioni',
        titleColor: 'danger',
        body: (
          <Card>
            <CardHeader>
              <h3 className={classes.cardTitle}>
                <b>Variazioni</b>
              </h3>
            </CardHeader>
            <CardBody>
              <ExpansionPanel>
                <ExpansionPanelSummary
                  classes={{
                    content: classes.expansionPanelSummaryContent,
                    expandIcon: classes.expansionPanelSummaryExpandIcon
                  }}
                >
                  <b>Clicca qui per visualizzare i dettagli</b>
                  <div style={{ marginLeft: '35%' }}>
                    {this.state.tuplaTemporanea ? (
                      <Button
                        color='success'
                        round
                        onClick={() => {
                          this.ConfermaTeeDJS();
                        }}
                      >
                        Conferma&nbsp;
                      </Button>
                    ) : null}

                    <Button
                      style={{ background: '#ff5c5c' }}
                      round
                      onClick={() => {
                        this.RipristinoTeeDJS();
                      }}
                    >
                      Mantieni dati precedenti&nbsp;
                    </Button>
                  </div>
                </ExpansionPanelSummary>
                {this.state.listaDifferenze.map((value, index) => {
                  return (
                    <div>
                      <b> {value.tipoVariazione} </b>
                      <p>{value.descrizione}</p>
                    </div>
                  )
                }
                )}
              </ExpansionPanel>
            </CardBody>
          </Card>
        )
      }
    ]

    /*
        const ownersRequestHistoryColumn = [
            {
                inverted: true,
                badgeColor: "warning",
                badgeIcon: Family,
                title: "Soci e Titolari",
                titleColor: "danger",
                body: (
                    <Card>
                        <CardHeader>
                            <h3 className={classes.cardTitle}><b>Storico</b></h3>
                        </CardHeader>
                        <CardBody>
                            {this.state.teeDiscoveredSubjectsHistories.map((value, index) => {
                                return (
                                    <TeeHistoryAccordion
                                        active={0}
                                        item={value}
                                        keyHeaderMaps={[{ "key": "note", "header": "Note", "key": "elaborationState", "header": "Stato richiesta" }]}

                                    />)
                            })}
                        </CardBody>
                    </Card>
                )
            }
        ]
        */

    /*
        const listApprofondimentiColumn = [
            {
                inverted: true,
                badgeColor: "warning",
                badgeIcon: Family,
                title: "Soci e Titolari",
                titleColor: "danger",
                body: (
                    <Card>
                        <CardHeader>
                            <h3 className={classes.cardTitle}><b>Approfondimenti</b></h3>
                        </CardHeader>



                        <CardBody>
                            {this.state.listApprofondimentoRequestsByPlatformId.map((value, index) => {
                                return (<ApprofondimentoListAccordion
                                    active={0}
                                    item={value}
                                    keyHeaderMaps={[{ "key": "note", "header": "Note" }]}

                                />)
                            })}


                        </CardBody>
                    </Card>
                )
            }
        ]
        */

    const documentsDetailsColumn = [
      {
        inverted: false,
        badgeColor: 'warning',
        badgeIcon: Family,
        title: 'Soci e Titolari',
        titleColor: 'danger',
        body: (
          <Card>
            <CardHeader>
              <h3 className={classes.cardTitle}>
                <b>Documenti</b>
              </h3>
            </CardHeader>
            <CardBody>
              <DocumentiAccordion
                active={0}
                editMode={this.props.editMode}
                collapses={
                  this.state.listaDocumenti ? this.state.listaDocumenti : []
                }
                isListaDocumentiChanged={this.state.isListaDocumentiChanged}
                onChange={this.handleChangeDocumentInDeleteCaseFromChild}
                click={this.handleViewDocumentFromChild}
                //pdfData={this.props.pdfData}
                download={this.handleDownloadDocumentFromChild}
                pdfData={this.state.pdfData}
              />
            </CardBody>
          </Card>
        )
      }
    ]

    let maybeErrorMsg = null
    if (this.props.errorMessage !== null) {
      maybeErrorMsg = (
        <div className={classes.allCenteredMessage}>
          <Warning>
            <Warn style={{ verticalAlign: 'middle' }} />
            &nbsp;&nbsp;ATTENZIONE: {this.props.errorMessage} I dati mostrati
            sono quelli disponibili nella sezione generale.
          </Warning>
        </div>
      )
    }

    // addForeignActualOwner = event => {}
    return (
      <div style={{ textAlign: 'left' }}>
        {this.state.alert}
        {maybeErrorMsg}
        <GridContainer style={{ textAlign: 'center' }}>
          <GridItem xs={12} sm={12} md={12}>
            <DetailVisualizer
              simple
              leftDetails={leftDetailsColumn}
              rightDetails={rightDetailsColumn}
              showIcons={true}
              showTitles={true}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <SingleColumnDetailVisualizer
              simple
              details={ownersDetailsColumn}
              showIcons={true}
              showTitles={true}
              iconType={'Soci'}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <SingleColumnDetailVisualizer
              simple
              details={ownersAnagraphyColumn}
              showIcons={true}
              showTitles={true}
              iconType={'Soci'}
            />
          </GridItem>
          {this.state.listaDocumenti.length > 0 ? (
            <GridItem xs={12} sm={12} md={12}>
              <SingleColumnDetailVisualizer
                simple
                details={documentsDetailsColumn}
                showIcons={true}
                showTitles={true}
              />
            </GridItem>
          ) : null}
          {!this.props.itemForDetail.monitoraggioUguale && this.props.itemForDetail.rispostaMonitoraggio ? (
            <GridItem xs={12} sm={12} md={12}>
              <SingleColumnDetailVisualizer
                simple
                details={listaDifferenze}
                showIcons={true}
                showTitles={true}
              />
            </GridItem>
          ) : null}
        </GridContainer>
      </div>
    )
  }

  RipristinoTeeDJS() {
    axiosApiCaller
      .post(
        AuthService.ripristinoTeeDJS() +
        '?platformId=' +
        this.cleanedData.platformId +
        '&idRichiesta=' +
        this.cleanedData.idRichiesta
      )
      .then(res => {
        return (window.location.href = 'richieste-monitoraggio')
      })
      .catch(error => {
        return (window.location.href = 'richieste-monitoraggio')
      })
  }

  ConfermaTeeDJS() {
    axiosApiCaller
      .post(
        AuthService.confermaTeeDJS() +
        '?platformId=' +
        this.cleanedData.platformId +
        '&idRichiesta=' +
        this.cleanedData.idRichiesta
      )
      .then(res => {
        return (window.location.href = 'richieste-monitoraggio')
      })
      .catch(error => {
        return (window.location.href = 'richieste-monitoraggio')
      })
  }

  updateTee = (foreignOwner, index) => {
    let foreignOwnersList = this.state.cleanedData.foreignActualOwners
    if (index == -1) {
      foreignOwnersList.push(foreignOwner)
    } else {
      // foreignOwnersList.push(index, foreignOwner)
      foreignOwnersList.splice(index, 1, foreignOwner)
    }
    this.setState({ foreignOwnersList: foreignOwnersList })

    axiosApiCaller
      .post(
        AuthService.updateTeeCollegate() +
        '?platformId=' +
        this.cleanedData.platformId +
        '&idRichesta=' +
        this.cleanedData.idRichiesta,
        foreignOwnersList
      )
      .then(res => {
        // this.setState({ anagrphyList: res.data.payload })
        this.successAutoClose('Successo', 'operazione eseguita con successo')
      })
      .catch(error => {
      })
  }

  deleteTee(event, index) {
    let cl = this.state.cleanedData
    let sl = cl.foreignActualOwners
    sl.splice(index, 1)
    cl.foreignActualOwners = sl
    this.setState({ cleanedData: cl })

    axiosApiCaller
      .post(
        AuthService.updateTeeCollegate() +
        '?platformId=' +
        this.cleanedData.platformId +
        '&idRichesta=' +
        this.cleanedData.idRichiesta,
        this.state.cleanedData.foreignActualOwners
      )
      .then(res => {
        // this.setState({ anagrphyList: res.data.payload })
        this.successAutoClose('Successo', 'operazione eseguita con successo')
      })
      .catch(error => {
        console.log(error)
      })

    this.hideAlert()
  }
  deleteTeeAlert(event, index) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '140px' }}
          title='Sei sicuro di voler eliminare questo articolo'
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color='danger'
                  onClick={() => {
                    this.hideAlert()
                  }}
                  round
                >
                  <Cancel style={{ fontSize: '28px' }} />
                  &nbsp;Annulla
                </Button>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                  color='success'
                  onClick={() => {
                    this.deleteTee(event, index)
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: '28px' }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {/* elimina prima i Titolari effettivi esteri collegati a questa Società */}
          <br />
        </SweetAlert>
      )
    })
  }

  deleteSocietaCollegate(event, index) {
    let sl = this.state.societaCollegateList
    sl.splice(index, 1)
    this.state.societaCollegateLocalList = sl
    this.cleanedData.societaCollegateList = sl
    this.setState({ societaCollegateList: sl })

    axiosApiCaller
      .post(
        AuthService.addSocietaCollegate() +
        '?platformId=' +
        this.cleanedData.platformId +
        '&idRequest=' +
        this.cleanedData.idRichiesta,
        sl
      )
      .then(res => {
        // this.successAutoClose ("Successo", "operazione eseguita con successo")
        this.setState({ societaCollegateList: sl })
      })
      .catch(error => {
        console.log(error)
      })


    this.hideAlert()
  }
  deleteSocietaCollegateAlert(event, index) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '140px' }}
          title='Sei sicuro di voler eliminare questo articolo'
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color='danger'
                  onClick={() => {
                    this.hideAlert()
                  }}
                  round
                >
                  <Cancel style={{ fontSize: '28px' }} />
                  &nbsp;Annulla
                </Button>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                  color='success'
                  onClick={() => {
                    this.deleteSocietaCollegate(event, index)
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: '28px' }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {/* elimina prima i Titolari effettivi esteri collegati a questa Società */}
          <br />
        </SweetAlert>
      )
    })
  }

  handleChangeAnagrphyForm(event) {
    if (event.target.id == 'pk') {
      this.state.pk = event.target.value
    } else if (event.target.id == 'ragione_sociale') {
      this.state.ragione_sociale = event.target.value
    } else if (event.target.id == 'stato') {
      this.state.stato = event.target.value
    } else if (event.target.id == 'indirizzo') {
      this.state.indirizzo = event.target.value
    } else if (event.target.id == 'citta') {
      this.state.citta = event.target.value
    } else if (event.target.id == 'piva') {
      this.state.piva = event.target.value
    } else if (event.target.id == 'descrizione_forma_giuridica') {
      this.state.descrizione_forma_giuridica = event.target.value
    } else if (event.target.id == 'forma_giuridica') {
      this.state.forma_giuridica = event.target.value
    } else if (event.target.id == 'post_code') {
      this.state.post_code = event.target.value
    } else if (event.target.id == 'province') {
      this.state.province = event.target.value
    }
  }

  addItem() {
    let societaCollegate = this.state.currentSelectedAnagaphy

    societaCollegate.shares = this.state.societaCollegatePercentage
    societaCollegate.livello = this.state.societaCollegateLivello
    societaCollegate.ragioneSociale = societaCollegate.ragione_sociale
    societaCollegate.formaGiuridicaDesc =
      societaCollegate.descrizione_forma_giuridica
    societaCollegate.formaGiuridica = societaCollegate.forma_giuridica
    societaCollegate.town = societaCollegate.citta
    societaCollegate.post_code = societaCollegate.post_code
    let sl = this.state.societaCollegateList

    // if (!this.state.societaCollegateList.includes(societaCollegate)) {
    sl.push(societaCollegate)

    this.cleanedData.societaCollegateList = sl



    axiosApiCaller
      .post(
        AuthService.addSocietaCollegate() +
        '?platformId=' +
        this.cleanedData.platformId +
        '&idRequest=' +
        this.cleanedData.idRichiesta,
        sl
      )
      .then(res => {
        // this.successAutoClose ("Successo", "operazione eseguita con successo")
        this.setState({ societaCollegateList: sl })
      })
      .catch(error => {
        console.log(error)
      })

    // this.setState({ itemAlreadyAddedMessage: '' })
    // } else {
    //   this.setState({ itemAlreadyAddedMessage: 'elemento già aggiunto' })
    // }

    this.setState({
      societaCollegateLivello: '',
      societaCollegatePercentage: '',
      currentSelectedAnagaphy: ''
    })
  }

  successAutoClose(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '140px' }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color='error'
                  onClick={() => {
                    this.hideAlert()
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: '28px' }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    })
    setTimeout(this.hideAlert, 2000, false)
  }

  changeSocietaCollegateForm(event) {
    if (event.target.name == 'societaCollegateLivello') {
      this.setState({ societaCollegateLivello: event.target.value })
    } else if (event.target.name == 'societaCollegateAnagraphy') {
      this.setState({ currentSelectedAnagaphy: event.target.value })
    } else if (event.target.name == 'societaCollegatePercentage') {
      this.setState({ societaCollegatePercentage: event.target.value })
    }
  }

  handleEditAnagrphyForm(event, key, mode) {
    console.log(key, mode)
    let tmpAnagrphyList = this.state.anagrphyList
    if (mode == 'add') {
      if (event.target.id == 'pk') {
        this.state.pk = event.target.value
      } else if (event.target.id == 'ragione_sociale') {
        this.state.ragione_sociale = event.target.value
      } else if (event.target.id == 'stato') {
        this.state.stato = event.target.value
      } else if (event.target.id == 'indirizzo') {
        this.state.indirizzo = event.target.value
      } else if (event.target.id == 'citta') {
        this.state.citta = event.target.value
      } else if (event.target.id == 'piva') {
        this.state.piva = event.target.value
      } else if (event.target.id == 'descrizione_forma_giuridica') {
        this.state.descrizione_forma_giuridica = event.target.value
      } else if (event.target.id == 'forma_giuridica') {
        this.state.forma_giuridica = event.target.value
      } else if (event.target.id == 'post_code') {
        this.state.post_code = event.target.value
      } else if (event.target.id == 'province') {
        this.state.province = event.target.value
      }
    } else {
      tmpAnagrphyList[key][event.target.id] = event.target.value
      this.setState({ anagrphyList: tmpAnagrphyList })
    }
  }
  createAnagraphy(event, anagraphy) {
    // var requestObject = {
    //     pk: this.state.pk,
    //     ragione_sociale: this.state.ragione_sociale,
    //     forma_giuridica: this.state.forma_giuridica,
    //     descrizione_forma_giuridica: this.state.descrizione_forma_giuridica,
    //     piva: this.state.piva,
    //     indirizzo: this.state.indirizzo,
    //     stato: this.state.stato,
    //     citta: this.state.citta,
    //     post_code: this.state.post_code,
    //     province: this.state.province,
    //     platformId: this.cleanedData.platformId,
    //     idRichiesta: this.cleanedData.idRichiesta
    // }
    anagraphy.platformId = this.cleanedData.platformId
    anagraphy.idRichiesta = this.cleanedData.idRichiesta

    axiosApiCaller
      .post(AuthService.postAnagraphy(), anagraphy)
      .then(res => {
        this.setState({ anagrphyList: res.data.payload })
      })
      .catch(error => {
        console.log(error)
      })
  }
  updateAnagraphy(key) {
    let requestObject = this.state.anagrphyList[key]
    requestObject.index = '' + key
    requestObject.platformId = this.cleanedData.platformId
    requestObject.idRichiesta = this.cleanedData.idRichiesta
    return axiosApiCaller
      .post(AuthService.updateAnagraphy(), requestObject)
      .then(res => {
        this.setState({ anagrphyList: res.data.payload })
      })
      .catch(error => {
        console.log(error)
      })
  }

  clearAnagraphy(event) {
    // prop.pk = "";
    // this.state.ragione_sociale = "";
    // this.state.forma_giuridica = "";
    // this.state.descrizione_forma_giuridica = "";
    // this.state.piva = "";
    // this.state.indirizzo = "";
    // this.state.stato = "";
    // this.state.citta = "";
    // this.state.post_code = "";
    // this.state.province = "";
    // this.cleanedData.platformId = "";
    // this.cleanedData.idRichiesta = "";
  }
  handleAddAnagrphyForm(event) {
    if (event.target.id == 'pk') {
      this.state.pk = event.target.value
    } else if (event.target.id == 'ragione_sociale') {
      this.state.ragione_sociale = event.target.value
    } else if (event.target.id == 'stato') {
      this.state.stato = event.target.value
    } else if (event.target.id == 'indirizzo') {
      this.state.indirizzo = event.target.value
    } else if (event.target.id == 'citta') {
      this.state.citta = event.target.value
    } else if (event.target.id == 'piva') {
      this.state.piva = event.target.value
    } else if (event.target.id == 'descrizione_forma_giuridica') {
      this.state.descrizione_forma_giuridica = event.target.value
    } else if (event.target.id == 'forma_giuridica') {
      this.state.forma_giuridica = event.target.value
    } else if (event.target.id == 'post_code') {
      this.state.post_code = event.target.value
    } else if (event.target.id == 'province') {
      this.state.province = event.target.value
    }
  }
  deleteAnagraphy(key) {
    this.deleteAlert('Sei sicuro di voler eliminare questo articolo', '', key)
  }

  delete(key) {
    this.hideAlert()
    let requestObject = this.state.anagrphyList[key]
    requestObject.index = '' + key
    requestObject.platformId = this.cleanedData.platformId
    requestObject.idRichiesta = this.cleanedData.idRichiesta

    let dataExist = false
    let connectedData = 0
    this.cleanedData.foreignActualOwners.map(foreignActualOwner => {
      if (foreignActualOwner.catenaSocietaCollegate != null) {
        if (foreignActualOwner.catenaSocietaCollegate.length > 0) {
          foreignActualOwner.catenaSocietaCollegate.map(societaCollegate => {
            if (
              societaCollegate.ragioneSociale == requestObject.ragione_sociale
            ) {
              dataExist = true
              connectedData = connectedData + 1
            }
          })
        }
      }
    })
    if (!dataExist) {
      axiosApiCaller
        .post(AuthService.deleteAnagraphy(), requestObject)
        .then(res => {
          this.setState({ anagrphyList: res.data.payload })
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      this.setState({
        alert: (
          <SweetAlert
            danger
            style={{ display: 'block', marginTop: '140px' }}
            title='Questa Società è già collegata con uno o più beneficiari esteri'
            customButtons={
              <React.Fragment>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                  <Button
                    style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                    color='success'
                    onClick={() => {
                      this.hideAlert()
                    }}
                    round
                  >
                    <CheckCircle style={{ fontSize: '28px' }} />
                    &nbsp;Ok
                  </Button>
                </div>
              </React.Fragment>
            }
          >
            elimina prima i Titolari effettivi esteri collegati a questa Società
            <br />
          </SweetAlert>
        )
      })
    }
  }

  hideAlert(redirectToListView) {
    this.setState({ alert: null })
  }

  deleteAlert(primaryMsg, secondaryMsg, key) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '140px' }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color='danger'
                  onClick={() => {
                    this.hideAlert()
                  }}
                  round
                >
                  <Cancel style={{ fontSize: '28px' }} />
                  &nbsp;Annulla
                </Button>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                  color='success'
                  onClick={() => {
                    this.delete(key)
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: '28px' }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    })
  }
}

CompanyReportVisualizer.propTypes = {
  itemForDetail: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  mainIcon: PropTypes.object.isRequired
}

export default withStyles(styles)(CompanyReportVisualizer)
