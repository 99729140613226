import DateFnsUtils from "@date-io/date-fns";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import BuildIcon from "@material-ui/icons/Build";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RemoveRedEyeRounded from "@material-ui/icons/RemoveRedEye";
import SearchIcon from "@material-ui/icons/Search";
import ToggleOn from "@material-ui/icons/ToggleOn";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import axios from "axios";
import FilterableMultiRequestsManagement from "components/COMMON/FilterableMultiRequestManagement";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { format } from "date-fns";
import {
  axiosApiCaller,
  HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
} from "layouts/AxiosService";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { LoopCircleLoading } from "react-loadingg";
import { Link } from "react-router-dom";
import * as AuthService from "services/AuthService.js";
import Swal from "sweetalert2";
import OperationMenu from "views/COMMON/OperationMenu";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import * as GlobalList from "services/GlobalList.js";

const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "23px",
    height: "23px",
    top: "0px",
    left: "2px",
    position: "relative"
  },
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px"
  },
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px",
    width: "80px",
    height: "auto",
    minWidth: "auto"
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px",
    width: "auto",
    height: "auto",
    minWidth: "auto"
  }
};

const styles = {
  ...localStyles,
  ...dashboardStyles,
  ...checkBoxStyles
};

// COSTANTI PER I VALORI NON DEFINITI O NULLI
export const NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE = "(Non disponibile)";
export const NULL_STRATEGIA_SUBSTITUTION_VALUE = "Nessuna Strategia";
export const NULL_STATO_SUBSTITUTION_VALUE = "Nessun Stato di Elaborazione";

const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000;
class RequestHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.lastRefreshTime = new Date();

    this.onKeyUp = this.onKeyUp.bind(this);
    this.setCurrentIncompletedReqList = this.setCurrentIncompletedReqList.bind(
      this
    );
    this.hideAlert = this.hideAlert.bind(this);
    this.handleShowMonitoredChange = this.handleShowMonitoredChange.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
    this.downloadOutputAlert = this.downloadOutputAlert.bind(this);
    this.handleChangeDownloadAmount = this.handleChangeDownloadAmount.bind(
      this
    );
    this.sendJsonConfirmationAlert = this.sendJsonConfirmationAlert.bind(this);
    this.handleSentAndExported = this.handleSentAndExported.bind(this);
    this.handleExportFilter = this.handleExportFilter.bind(this);
    this.handleSentFilter = this.handleSentFilter.bind(this);
    this.handleApplicationFilter = this.handleApplicationFilter.bind(this);
    this.handleStateFilter = this.handleStateFilter.bind(this);
    this.handleMotivoFilter = this.handleMotivoFilter.bind(this);
    this.sendJsonAlert = this.sendJsonAlert.bind(this);
    this.setAllList = this.setAllList.bind(this);
    this.handleModalitaEvasioneFilter = this.handleModalitaEvasioneFilter.bind(this);
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.extractAllCustomersListFromBackend();
    }
  }

  getInitialState() {
    let date = new Date();

    return {
      alert: null,
      loadedLists: false,
      usersSessionsList: [],
      customersList: [],
      applicationFilterList: localStorage.getItem("applicationFilter")
        ? JSON.parse(localStorage.getItem("applicationFilter"))
        : [],
      filterDateList: localStorage.getItem("filterDate")
        ? JSON.parse(localStorage.getItem("filterDate"))
        : [],
      filterSentList: localStorage.getItem("filterSent")
        ? JSON.parse(localStorage.getItem("filterSent"))
        : [],
      filterStateList: localStorage.getItem("filterState")
        ? JSON.parse(localStorage.getItem("filterState"))
        : [],
      motivoList: localStorage.getItem("motivoList")
        ? JSON.parse(localStorage.getItem("motivoList"))
        : [],
      filterExportList: localStorage.getItem("filterExport")
        ? JSON.parse(localStorage.getItem("filterExport"))
        : [],
      robotIdFilterList: localStorage.getItem("robotIdFilter")
        ? JSON.parse(localStorage.getItem("robotIdFilter"))
        : [],
      monitoraggioFilterList: localStorage.getItem("monitoraggioFilter")
        ? JSON.parse(localStorage.getItem("monitoraggioFilter"))
        : [],
      modalitaEvasioneFilterList: localStorage.getItem("modalitaEvasioneFilter")
        ? JSON.parse(localStorage.getItem("modalitaEvasioneFilter"))
        : [], 
      offSet: - 1,
      totalElements: 0,
      numberQueryResults: 0,
      pageNumber: 0,
      tmpCustomersList: [],
      currentIncompletedRequestList: [],
      currentIncompletedRequestId: "",
      incompletesReqDivIsVisible: false,
      usersSessionsChartLabels: [],
      usersSessionsChartSeries: [],
      usersSessionsChartMax: 100,
      currentMinutesRefreshDelay: 0,
      currentLoggedUserCanManageVisualizeResult: AuthService.authenticathedUserCanManageUsers(),
      forzaInvio: false,
      startDate: localStorage.getItem("startDate")
        ? new Date(localStorage.getItem("startDate"))
        : date.setDate(date.getDate() - 7),
      endDate: localStorage.getItem("endDate")
        ? new Date(localStorage.getItem("endDate"))
        : new Date(),
      startDateValid: false,
      endDateValid: false,
      buttonUploadDisabled: false,
      extractValue: localStorage.getItem("extractValue")
        ? localStorage.getItem("extractValue")
        : "TUTTI",
      dataValue: localStorage.getItem("dataValue")
        ? localStorage.getItem("dataValue")
        : "Data Creazione",
      sentValue: localStorage.getItem("sentValue")
        ? localStorage.getItem("sentValue")
        : "Non Inviata",
      applicationValue: localStorage.getItem("applicationValue")
        ? localStorage.getItem("applicationValue")
        : "TUTTI",
      statoValue: localStorage.getItem("statoValue")
        ? localStorage.getItem("statoValue")
        : "RICHIESTA",
      country: localStorage.getItem("country")
        ? localStorage.getItem("country")
        : "",
      motivo: localStorage.getItem("motivo")
        ? localStorage.getItem("motivo")
        : "NESSUNO",
      robotId: localStorage.getItem("robotId")
        ? localStorage.getItem("robotId")
        : "TUTTI",
      modalitaEvasioneValue: localStorage.getItem("modalitaEvasione")
        ? localStorage.getItem("modalitaEvasione")
        : "NESSUNA",
      order: "desc",
      orderBy: 2,
      canceldMonitoringList: [],
      content: false
    };
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  caricamento(primaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <div style={{ height: "100px" }}>
                  <LoopCircleLoading />
                </div>
              </div>
            </React.Fragment>
          }
        ></SweetAlert>
      )
    });
  }
  ricalcolo(primaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <div style={{ height: "100px" }}>
                  <Button
                    style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                    color="cancel"
                    onClick={() => {
                      this.hideAlert();
                    }}
                    round
                  >
                    &nbsp;Cancel
                  </Button>
                </div>
              </div>
            </React.Fragment>
          }
        ></SweetAlert>
      )
    });
  }

  componentWillMount() {
    if (this.state.sentValue != undefined && !localStorage.getItem("sentValue"))
      localStorage.setItem("sentValue", this.state.sentValue);

    if (this.state.dataValue != undefined && !localStorage.getItem("dataValue"))
      localStorage.setItem("dataValue", this.state.dataValue);

    if (
      this.state.statoValue != undefined &&
      !localStorage.getItem("statoValue")
    )
      localStorage.setItem("statoValue", this.state.statoValue);
  }

  componentDidMount() {
    if (localStorage.getItem("startDate") != undefined) {
      this.handleChangeStartDate(new Date(localStorage.getItem("startDate")));
      this.setState({ startDate: new Date(localStorage.getItem("startDate")) });
    }

    if (localStorage.getItem("endDate") != undefined) {
      this.handleChangeEndDate(new Date(localStorage.getItem("endDate")));
    }

    if (localStorage.getItem("denominazione") != undefined) {
      this.handleChangeNameAfterReload(localStorage.getItem("denominazione"));
    }
    this.extractAllCustomersListFromBackend();

    if (localStorage.getItem("motivoList") == undefined) {
      this.setAllList();
    }


    /*
    //FUNZIONANTE
        axiosApiCaller.get("https://mocki.io/v1/1e06df8c-5b90-43fd-9b78-d1b230781b8f")
        .then((res) => {
          console.log(res.data.motivo)
          console.log("$$$$$$")
          motivoList = res.data.motivo
          //localStorage.setItem("motivo", state[state.indexOf(motivo)]);
    
        /*
        //FUNZIONANTE
            axiosApiCaller.get("https://mocki.io/v1/1e06df8c-5b90-43fd-9b78-d1b230781b8f")
            .then((res) => {
              console.log(res.data.motivo)
              console.log("$$$$$$")
              motivoList = res.data.motivo
              //localStorage.setItem("motivo", state[state.indexOf(motivo)]);
        
          })
          .catch((error) => {
              console.log('COMUNICATION ERROR WITH APP BACKEND SERVICE..');
              console.log(JSON.stringify(error.message));
              this.deniedOperation('Impossibile visualizzare le informazioni', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
          });*/
  }

  componentWillUnmount() { }

  handleChangeNameAfterReload(newValue) {
    this.setState({ name: newValue });
    localStorage.setItem("denominazione", newValue);
    if (newValue != null) {
      if (newValue.length < 3) {
        this.setState({ nameValid: false });
        this.setState({
          errorMessage: "La lunghezza della denominazione non è valida"
        });
      } else {
        this.setState({ nameValid: true });
        this.setState({ errorMessage: null });
      }
    } else {
      this.setState({ nameValid: false });
      this.setState({ errorMessage: "La denominazione non può essere vuota" });
    }
  }

  handleChangeName(event) {
    let newValue = event.target.value;
    this.setState({ name: newValue });

    localStorage.setItem("denominazione", newValue);
    if (newValue != null) {
      if (newValue.length < 3) {
        this.setState({ nameValid: false });
        this.setState({
          errorMessage: "La lunghezza della denominazione non è valida"
        });
      } else {
        this.setState({ nameValid: true });
        this.setState({ errorMessage: null });
      }
    } else {
      this.setState({ nameValid: false });
      this.setState({ errorMessage: "La denominazione non può essere vuota" });
    }
  }

  handleChangeCountry(event) {
    let newValue = event.target.value;
    this.setState({ country: newValue });

    localStorage.setItem("country", newValue);
  }

  handleShowMonitoredChange(event) {
    this.setState({ showMonitored: event.target.checked });
    if (event.target.checked) {
      this.showMonitoredLabel = "Includi le posizioni monitorate";
    } else {
      this.showMonitoredLabel = "Escludi le posizioni monitorate";
    }
  }

  handleFiltering(filter) { }

  resetFilters() {
    this.setState({
      showMonitored: true
    });
  }

  downloadRigheIncomplete(idRequest) {
    axios
      .get(
        AuthService.downloadIncompleteRequests() + "?idRequest=" + idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Richieste_incomplete.xlsx");
        link.click();
      })
      .catch(error => console.log(error));
  }

  render() {
    const { classes } = this.props;

    const pHeaderStyle = {
      paddingTop: "10px",
      color: "white",
      fontSize: "15px",
      fontWeight: "450",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      textAlign: "center"
    };
    let headersList;
    if (this.state.currentLoggedUserCanManageVisualizeResult) {
      headersList = [
        "DENOMINAZIONE",
        "NAZIONE",
        "STATO",
        "DATA RICHIESTA",
        "ULTIMA MODIFICA",
        "CONTROPARTI",
        "EVASE",
        "SCARTATE",
        "INVIA",
        "ESPORTATA",
        "OPERAZIONI"
      ];
      if (AuthService.getApplicationName() === "ADMINISTRATION_PANEL") {
        headersList.unshift("APPLICAZIONE CLIENT");
      }
    } else {
      headersList = [
        "DENOMINAZIONE",
        "NAZIONE",
        "STATO",
        "DATA RICHIESTA",
        "ULTIMA MODIFICA",
        "CONTROPARTI",
        "EVASE",
        "SCARTATE",
        "INVIA",
        "ESPORTATA",
        "OPERAZIONI"
      ];
    }
    let fieldsList = [
      "ragioneSociale",
      "country",
      "elaborationState",
      "creationDatetime",
      "lastEditDatetime",
      "requestsNumber",
      "completedRequests",
      "incompletedRequestButton",
      "sendJson",
      "exported",
      "operationMenu"
    ];
    if (AuthService.getApplicationName() === "ADMINISTRATION_PANEL") {
      fieldsList.unshift("applicationName");
    }

    let showMonitoredControl = null;
    if (this.state.showMonitored === true) {
      showMonitoredControl = (
        <a href="#">
          <ToggleOn
            onClick={event => this.handleShowNotMonitoredChange(event)}
            style={{
              float: "right",
              margin: "0px 0px 0px 0px",
              width: "34px",
              height: "34px"
            }}
          />
        </a>
      );
    }

    let incompletesDivStyle = {
      display: this.state.incompletesReqDivIsVisible ? "block" : "none"
    };

    const incompletesHeaderStyle = {
      margin: "auto",
      position: "relative",
      bottom: "16px",
      width: "97%",
      height: "45px",
      background:
        "linear-gradient(to right, rgb(23, 105, 255),rgb(156, 191, 255))",
      borderRadius: "4px"
    };

    let annulledList = this.state.currentIncompletedRequestList.filter(
      req => req.elaborationState == "ANNULLATA"
    );
    let rowsAnnulledList = [];
    rowsAnnulledList = annulledList.map(req => (
      <tr>
        <td style={{ paddingBottom: "10px" }}>
          {req.ragioneSociale == null || req.ragioneSociale == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.ragioneSociale
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.customerId == null || req.customerId == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.customerId
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>{req.elaborationMessage}</td>
        &nbsp;&nbsp;&nbsp;
      </tr>
    ));

    let annulledReqTable = (
      <table style={{ fontSize: "0.9em" }}>
        <tr>
          <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Customer ID</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Causa Annullamento</th>
          &nbsp;&nbsp;&nbsp;
        </tr>
        {rowsAnnulledList}
      </table>
    );

    let suspendedList = this.state.currentIncompletedRequestList.filter(
      req => req.elaborationState == "SOSPESA"
    );
    let rowsSuspendedList = [];
    rowsSuspendedList = suspendedList.map(req => (
      <tr>
        <td style={{ paddingBottom: "10px" }}>
          {req.ragioneSociale == null || req.ragioneSociale == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.ragioneSociale
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.paeseProvincia == null || req.paeseProvincia == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.paeseProvincia
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.localita == null || req.localita == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.localita
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.indirizzo == null || req.indirizzo == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.indirizzo
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
      </tr>
    ));

    let suspendedReqTable = (
      <table style={{ fontSize: "0.9em" }}>
        <tr>
          <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Paese</th>&nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Località</th>&nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Indirizzo</th>&nbsp;&nbsp;&nbsp;
        </tr>
        {rowsSuspendedList}
      </table>
    );

    let anulledRequestCommentP = (
      <p
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontSize: "0.95em",
          fontWeight: "bold"
        }}
      >
        Non sono presenti richieste annullate
      </p>
    );
    let suspendedRequestCommentP = (
      <p
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontSize: "0.95em",
          fontWeight: "bold"
        }}
      >
        Non sono presenti richieste sospese
      </p>
    );

    let rowsCanceldMonitoringList = this.state.canceldMonitoringList.map(
      req => (
        <tr>
          <td style={{ paddingBottom: "10px" }}>{req.customerId}</td>
          &nbsp;&nbsp;&nbsp;
          <td style={{ paddingBottom: "10px" }}>{req.ragioneSociale}</td>
          &nbsp;&nbsp;&nbsp;
        </tr>
      )
    );
    let canceledMonitoringReqTable = (
      <table style={{ fontSize: "0.9em" }}>
        <tr>
          <th style={{ paddingBottom: "14px" }}>Customer ID</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>
          &nbsp;&nbsp;&nbsp;
        </tr>
        {rowsCanceldMonitoringList}
      </table>
    );
    let canceldMonitoringRequestCommentP = (
      <p
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontSize: "0.95em",
          fontWeight: "bold"
        }}
      >
        Non ci sono presenti richieste di cancellazione del monitoraggio
      </p>
    );

    return (
      <div>
        {this.state.alert}

        <div style={incompletesDivStyle} className="incompletes-div-style">
          <div style={incompletesHeaderStyle}>
            <p style={pHeaderStyle}>LISTA RICHIESTE INCOMPLETE</p>
          </div>

          <p
            style={{
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              fontSize: "1.03em",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            RICHIESTE ANNULLATE:
          </p>
          {annulledList.length > 0 ? annulledReqTable : anulledRequestCommentP}

          <br />

          <p
            style={{
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              fontSize: "1.03em",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            RICHIESTE SOSPESE:
          </p>
          {suspendedList.length > 0
            ? suspendedReqTable
            : suspendedRequestCommentP}

          <br />

          <p
            style={{
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              fontSize: "1.03em",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            {/* RICHIESTE DI CANCELAZIONE MONITORAGGIO: */}
            ALTRE RICHIESTE:
          </p>
          {this.state.canceldMonitoringList.length > 0
            ? canceledMonitoringReqTable
            : canceldMonitoringRequestCommentP}

          <br />

          <div style={{ display: suspendedList.length > 0 ? "block" : "none" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.9em",
                fontWeight: "bold"
              }}
            >
              Vuoi processare le richieste sospese nonostante i dati siano
              incompleti?
            </p>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                style={{ marginLeft: "70px", fontSize: "13px" }}
                color="secondary"
                onClick={() => {
                  this.setState({ incompletesReqDivIsVisible: false });
                }}
                round
              >
                Chiudi&nbsp;
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                disabled={this.state.forzaInvio}
                style={{ marginRight: "70px", fontSize: "13px" }}
                color="behance"
                onClick={() => {
                  this.sendSuspendedRequests(
                    this.state.currentIncompletedRequestId,
                    this.state.currentIncompletedRequestList
                  );
                  this.setState({ forzaInvio: true });
                }}
                round
              >
                Forza Invio&nbsp;
              </Button>
              <IconButton
                style={{ marginRight: "70px", fontSize: "16px" }}
                color="primary"
                background-color="RoyalBlue"
                aria-label="download"
                onClick={() => {
                  this.downloadRigheIncomplete(
                    this.state.currentIncompletedRequestId
                  );
                }}
              >
                Scarica richieste incomplete&nbsp;
                <CloudDownloadIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: suspendedList.length > 0 ? "none" : "block",
              textAlign: "center"
            }}
          >
            <Button
              style={{ fontSize: "13px" }}
              color="secondary"
              onClick={() => {
                this.setState({ incompletesReqDivIsVisible: false });
              }}
              round
            >
              Chiudi&nbsp;
            </Button>
          </div>
        </div>

        <Table style={{ display: "inline-table" }}>
          <TableBody>
            <TableRow style={{ display: "flex" }}>
              <TableCell style={{ width: "15%", border: "none" }}>
                <CustomInput
                  success={this.state.nameValid == true}
                  error={this.state.nameValid == false}
                  labelText="Denominazione"
                  inputProps={{
                    value: this.state.name,
                    disabled: this.props.readOnly,
                    type: "text",
                    onChange: event => {
                      this.handleChangeName(event);
                    }
                  }}
                  customStyle={{ width: "115%", paddingTop: "5px" }}
                />
              </TableCell>

              <TableCell style={{ width: "19%", border: "none" }}>
                <CustomInput
                  labelText="Nazione"
                  inputProps={{
                    value: this.state.country,
                    disabled: this.props.readOnly,
                    type: "text",
                    onChange: event => {
                      this.handleChangeCountry(event);
                    }
                  }}
                  customStyle={{ width: "100%", paddingTop: "5px" }}
                />
              </TableCell>

              <TableCell style={{ width: "51%", border: "none" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ width: "30%" }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Data inizio"
                    value={this.state.startDate}
                    onChange={event => {
                      this.handleChangeStartDate(event);
                    }}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                  />

                  <KeyboardDatePicker
                    style={{ marginLeft: "5%", width: "30%" }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Data fine"
                    value={this.state.endDate}
                    onChange={event => {
                      this.handleChangeEndDate(event);
                    }}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                  />
                </MuiPickersUtilsProvider>
              </TableCell>

              <TableCell style={{ width: "25%", border: "none",marginLeft: "-17%" }}>
                <FormControl
                  style={{ width: "60%", paddingTop: "16px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Filtro data
                  </InputLabel>

                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.dataValue}
                    onChange={this.handleDataFilter}
                  >
                    {this.state.filterDateList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    {/* 
                    <MenuItem key={-1} name={"-1"} value="Data Creazione">
                      Data Creazione
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Data Modifica">
                      Data Modifica
                    </MenuItem>*/}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell style={{ width: "25%", border: "none", marginLeft: "-8%" }}>
                <FormControl
                  style={{ width: "70%", paddingTop: "16px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Filtro applicazione
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.applicationValue}
                    onChange={this.handleApplicationFilter}
                  >
                    {this.state.applicationFilterList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>

            <TableRow style={{ display: "flex" }}>
             

              <TableCell style={{ width: "15%", border: "none" }}>
                <FormControl
                  style={{ width: "100%", paddingTop: "5px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Filtra inviata
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.sentValue}
                    onChange={this.handleSentFilter}
                  >
                    {this.state.filterSentList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    {/* 
                    <MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Inviata">
                      INVIATA
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="Non Inviata">
                      NON INVIATA
                    </MenuItem>*/}
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell style={{ width: "15%", border: "none" }}>
                <FormControl
                  style={{ width: "100%", paddingTop: "5px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Filtro esportato
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.extractValue}
                    onChange={this.handleExportFilter}
                  >
                    {this.state.filterExportList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}

                    {/* 
                    <MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Esportata">
                      Esportata
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="Non esportato">
                      Non Esportata
                    </MenuItem>*/}
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell style={{ width: "15%", border: "none" }}>
                <FormControl
                  style={{ width: "100%", paddingTop: "5px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Filtra stato
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.statoValue}
                    onChange={this.handleStateFilter}
                  >
                    {this.state.filterStateList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}

                    {/* 
                    <MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="RICHIESTA">
                      RICHIESTA
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="APPROFONDIRE">
                      APPROFONDIRE
                    </MenuItem>
                    <MenuItem key={2} name={"2"} value="ELABORAZIONE">
                      ELABORAZIONE
                    </MenuItem>
                    <MenuItem key={3} name={"3"} value="ERRORE">
                      ERRORE
                    </MenuItem>
                    <MenuItem key={4} name={"4"} value="COMPLETATA">
                      COMPLETATA
                    </MenuItem>
                    <MenuItem key={5} name={"5"} value="ANNULLATA">
                      ANNULLATA
                    </MenuItem>
                    <MenuItem key={6} name={"6"} value="SOSPESA">
                      SOSPESA
                    </MenuItem>*/}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell style={{ width: "15%", border: "none" }}>
                <FormControl
                  style={{ width: "100%", paddingTop: "5px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Motivo
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.motivo}
                    onChange={this.handleMotivoFilter}
                  >
                    {this.state.motivoList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    {/* 
                    <MenuItem key={-1} name={"-1"} value="NESSUNO">
                    NESSUNO
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="DI">
                    DI
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="SNC">
                    SNC
                    </MenuItem>
                    <MenuItem key={2} name={"2"} value="PNT">
                    PNT
                    </MenuItem>
                    <MenuItem key={3} name={"3"} value="PUC">
                    PUC
                    </MenuItem>
                    <MenuItem key={4} name={"4"} value="RAD">
                    RAD
                    </MenuItem>
                    <MenuItem key={5} name={"5"} value="LIQ">
                    LIQ
                    </MenuItem>
                    <MenuItem key={6} name={"6"} value="DEN">
                    DEN
                    </MenuItem>*/}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell style={{ width: "30%", border: "none" }}>
                <FormControl
                  style={{ width: "50%", paddingTop: "5px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Filtro robotId
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.robotId}
                    onChange={this.handleRobotIdFilter}
                  >
                    {this.state.robotIdFilterList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    {/*<MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Con robotId">
                      CON ROBOT-ID
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="Senza robotId">
                      SENZA ROBOT-ID
                    </MenuItem>*/}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell style={{ width: "25%", border: "none", marginLeft: "-15%" }}>
                <FormControl
                  style={{ width: "50%", paddingTop: "5px" }}
                  className={classes.formControl}
                >
                  <InputLabel style={{ paddingTop: "6px" }} id="select-modalita-evasione">
                    Filtro Modalità Evasione
                  </InputLabel>
                  <Select
                    labelId="select-modalita-evasione-label"
                    id="select-filter-export"
                    defaultValue={this.state.modalitaEvasioneValue}
                    onChange={this.handleModalitaEvasioneFilter}
                  >
                    {this.state.modalitaEvasioneFilterList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
              </TableCell>

            </TableRow>
            <TableRow style={{ display: "flex" }}>
            <TableCell style={{ width: "30%", border: "none" }}>
               
                <Button
                  style={{ top: "8px", left: "20px", width: "35%" }}
                  color="success"
                  onClick={() => {
                    this.extractAllCustomersListFromBackend();
                  }}
                  onK
                  round
                  class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-91 makeStyles-success-101 makeStyles-round-122"
                >
                  Filtra
                  <SearchIcon style={{ fontSize: "28px", marginLeft: "5%" }} />
                </Button>

              </TableCell>
              <TableCell style={{ width: "30%", border: "none", marginLeft: "-12%" }}>
                <FormControl
                  style={{ width: "100%", paddingTop: "5px" }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px" }}
                    id="select-approfondimento"
                  >
                    Menu Operazioni
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue="Seleziona operazione"
                  >
                    <MenuItem key={-1} name={"-1"} value="Seleziona operazione">
                      Seleziona operazione
                    </MenuItem>

                    <MenuItem
                      key={0}
                      name={"0"}
                      value="Scarica selezionati"
                      onClick={this.downloadOutputAlert}
                    >
                      Scarica selezionati
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </MenuItem>

                    <MenuItem
                      key={1}
                      name={"1"}
                      value="Upload soci executives"
                      onClick={() => {
                        this.uploadAndSendMulti();
                      }}
                    >
                      Upload soci executives
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudUploadIcon
                          style={{ margin: "0px 0px 0px 15px" }}
                        />
                      </IconButton>
                    </MenuItem>

                    <MenuItem
                      key={2}
                      name={"2"}
                      value="Elabora"
                      onClick={() => {
                        this.uploadFileNuovoTracciato();
                      }}
                    >
                      Elabora
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudUploadIcon
                          style={{ margin: "0px 0px 0px 15px" }}
                        />
                      </IconButton>
                    </MenuItem>

                    <MenuItem
                      key={3}
                      name={"3"}
                      value="Invia json selezionati"
                      onClick={this.sendJsonAlert}
                    >
                      Invia json selezionati
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </MenuItem>

                    <MenuItem
                      key={4}
                      name={"4"}
                      value="Upload LUX"
                      onClick={() => {
                        this.upload("Lux");
                      }}
                    >
                      Calcola selezionati LUX
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>

                    <MenuItem
                      key={4}
                      name={"4"}
                      value="Upload UK"
                      onClick={() => {
                        this.upload("Uk");
                      }}
                    >
                      Calcola selezionati UK
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>

                    <MenuItem
                      key={4}
                      name={"4"}
                      value="Upload ITA"
                      onClick={() => {
                        this.upload("Ita");
                      }}
                    >
                      Calcola selezionati ITA
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={5}
                      name={"5"}
                      value="Ricarica selezionati"
                      onClick={() => {
                        this.ricaricaPosizioniSelezionate();
                      }}
                    >
                      Ricarica posizioni selezionate
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FilterableMultiRequestsManagement
              allItemsList={this.state.customersList}
              headersList={headersList}
              fieldsList={fieldsList}
              totalElements={this.state.customersList.length}
              offset={this.state.offSet}
              pageNumber={this.state.pageNumber}
              listener={this}
              order={this.state.order}
              orderBy={this.state.orderBy}
              content={this.state.content}
              numberQueryResults={this.state.numberQueryResults}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  deniedOperation(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
    setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
  }

  //questo metodo manda una chiamata al backend per popolare i filtri solo se localStorage è vuoto, a localStorage pieno setta lo stato dei filtri

  setAllList() {
    // GlobalList.setAllList();
    // GlobalList.getAllList();

    let motivoList = [];
    let filterExportList = [];
    let applicationFilterList = [];
    let filterDateList = [];
    let filterSentList = [];
    let filterStateList = [];
    let robotIdFilterList = [];
    let modalitaEvasioneFilterList = [];
    let monitoraggioFilterList = [];
    let applicationName = AuthService.getApplicationName();
    let queryString = "?applicationName=" + applicationName;

    const fetchData = () => {
      try {


        axiosApiCaller
          .get(AuthService.getGlobalList() + queryString)
          //axiosApiCaller.get("https://mocki.io/v1/40f24199-a685-4dbc-8f08-abfe35a3cb2d")
          .then(res => {
            if (res.data.success === true) {
              debugger;
              console.log("fetch");

              motivoList = res.data.payload.motiviList;
              filterExportList = res.data.payload.filterExportList;
              applicationFilterList = res.data.payload.applicationFilterList;
              filterDateList = res.data.payload.filterDateList;
              filterSentList = res.data.payload.filterSentList;
              filterStateList = res.data.payload.filterStateList;
              robotIdFilterList = res.data.payload.robotIdFilterList;
              modalitaEvasioneFilterList = res.data.payload.modalitaEvasioneFilterList;
              monitoraggioFilterList =
                res.data.payload.monitoraggioFilterList;
            }
          })
          .then(() => {
            localStorage.setItem("motivoList", JSON.stringify(motivoList));
            localStorage.setItem(
              "filterExport",
              JSON.stringify(filterExportList)
            );
            localStorage.setItem(
              "applicationFilter",
              JSON.stringify(applicationFilterList)
            );
            localStorage.setItem(
              "filterDate",
              JSON.stringify(filterDateList)
            );
            localStorage.setItem(
              "filterSent",
              JSON.stringify(filterSentList)
            );
            localStorage.setItem(
              "filterState",
              JSON.stringify(filterStateList)
            );
            localStorage.setItem(
              "robotIdFilter",
              JSON.stringify(robotIdFilterList)
            );
            localStorage.setItem(
              "modalitaEvasioneFilter",
              JSON.stringify(modalitaEvasioneFilterList)
            );
            localStorage.setItem(
              "monitoraggioFilter",
              JSON.stringify(monitoraggioFilterList)
            );
          })
          .then(() => {
            this.setState({
              motivoList: JSON.parse(localStorage.getItem("motivoList"))
            });
            this.setState({
              filterExportList: JSON.parse(
                localStorage.getItem("filterExport")
              )
            });
            this.setState({
              applicationFilterList: JSON.parse(
                localStorage.getItem("applicationFilter")
              )
            });
            this.setState({
              filterDateList: JSON.parse(localStorage.getItem("filterDate"))
            });
            this.setState({
              motivoList: JSON.parse(localStorage.getItem("motivoList"))
            });
            this.setState({
              filterSentList: JSON.parse(localStorage.getItem("filterSent"))
            });
            this.setState({
              filterStateList: JSON.parse(localStorage.getItem("filterState"))
            });
            this.setState({
              robotIdFilterList: JSON.parse(
                localStorage.getItem("robotIdFilter")
              )
            });
            this.setState({
              modalitaEvasioneFilterList: JSON.parse(
                localStorage.getItem("modalitaEvasioneFilter")
              )
            });
            this.setState({ loadedLists: true });
          });

      } catch (error) {
        console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
        console.log(JSON.stringify(error.message));
        // this.deniedOperation('Impossibile visualizzare le informazioni', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
      }
    };
    const data = fetchData();
    console.log("%%%%%%%%%%%");
    console.log(`Questi sono i DATI ${data}`);
  }

  sendSuspendedRequests(suspendedReqId, currentIncompletedRequestList) {
    let address = AuthService.getSuspendedReqAddress();

    axiosApiCaller
      .post(address, currentIncompletedRequestList, {
        params: {
          idRequest: suspendedReqId
        }
      })
      .then(res => {
        return (window.location.href = "storico-richieste");
      })
      .catch(error => {
        console.log("dentro axios, catch");
        console.log(error);
      });
  }

  downloadOutput() {
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazione"); //querySearch è quello che ha inserito l'utente in alto a sx (il periodo)
    let appName = AuthService.getApplicationName();
    if (
      appName === "ADMINISTRATION_PANEL" &&
      localStorage.getItem("applicationValue") !== "TUTTI" &&
      localStorage.getItem("applicationValue") !== null
    ) {
      appName = localStorage.getItem("applicationValue");
    }
    axios
      .post(
        AuthService.getBackendApiForOutput(),
        {
          // applicationName: "TEE",
          applicationName: appName,
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValue"),
          sentValue: localStorage.getItem("sentValue"),
          stateValue: localStorage.getItem("statoValue"),
          country: localStorage.getItem("country"),
          robotId: localStorage.getItem("robotId"),
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasione"),
          dataValue: localStorage.getItem("dataValue"),
          motivo: localStorage.getItem("motivo")
        },
        {
          responseType: "blob",

          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        this.hideAlert();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Richieste" + format(new Date(), "dd-MM-yyyy") + ".xlsx"
        );
        link.click();
        window.location.reload();
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForOutput(),
                  {
                    //applicationName: "TEE",
                    applicationName: appName,
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount
                  },
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    "Richieste" + format(new Date(), "dd-MM-yyyy") + ".xlsx"
                  );
                  link.click();
                  window.location.reload();
                })
                .catch(error => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch(error => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  sendJsonOutput() {
    debugger;
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazione"); //querySearch è quello che ha inserito l'utente in alto a sx (il periodo)
    let appName = AuthService.getApplicationName();
    if (
      localStorage.getItem("applicationValue") !== "TUTTI" &&
      localStorage.getItem("applicationValue") !== null
    ) {
      appName = localStorage.getItem("applicationValue");
    }

    axios
      .post(
        AuthService.getBackendApiForSendMassiveJson(),
        {
          //applicationName: "TEE",
          applicationName: appName,
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValue"),
          sentValue: localStorage.getItem("sentValue"),
          stateValue: localStorage.getItem("statoValue"),
          country: localStorage.getItem("country"),
          robotId: localStorage.getItem("robotId"),
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasione"),
          dataValue: localStorage.getItem("dataValue"),
          motivo: localStorage.getItem("motivo")
        },
        {
          //responseType: 'blob',

          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        let title = "";
        if (response.data.success == false) {
          title = "Errori in invio Json";
          this.errorSendAlert(title, response.data.message);
        } else {
          title = "File inviati con successo";
          this.redirectAfterSend(title, response.data.message);
        }
      })
      .catch(error => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForSendMassiveJson(),
                  {
                    //applicationName: "TEE",
                    applicationName: appName,
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount
                  },
                  {
                    //responseType: 'blob',
                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  let title = "";
                  if (response.data.success == false) {
                    title = "Errori in invio Json";
                    this.errorSendAlert(title, response.data.message);
                  } else {
                    title = "File inviati con successo";
                    this.redirectAfterSend(title, response.data.message);
                  }
                })
                .catch(error => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch(error => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  handleSentAndExported() {
    let temp = [];
    let selectedValue = localStorage.getItem("sentValue");
    let exportedValue = localStorage.getItem("extractValue");
    if (selectedValue == "Inviata") {
      if (exportedValue == "TUTTI") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            (cus.sent == true && cus.exported == true) ||
            cus.exported == false
          ) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.exported == true && cus.sent == true) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Non esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == true && cus.exported == false) {
            temp.push(cus);
          }
        }
      }
    } else if (selectedValue == "Non Inviata") {
      if (exportedValue == "TUTTI") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            (cus.sent == false && cus.exported == true) ||
            cus.exported == false
          ) {
            temp.push(cus);
          }
        }
      }
      if (exportedValue == "Esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == false && cus.exported == true) {
            temp.push(cus);
          }
        }
      }
      if (exportedValue == "Non esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == false && cus.exported == false) {
            temp.push(cus);
          }
        }
      }
    } else if (selectedValue == "TUTTI") {
      if (exportedValue == "TUTTI") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            cus.sent == true ||
            (cus.sent == false && cus.exported == true) ||
            cus.exported == false
          ) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Non esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            cus.sent == false ||
            (cus.sent == true && cus.exported == false)
          ) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == false || (cus.sent == true && cus.exported == true)) {
            temp.push(cus);
          }
        }
      }
    }
    temp = this.handleExportFilterAfterReload(temp);
    temp = this.handleSentFilterAfterReload(temp);
    this.setState({ customersList: temp });
  }

  handleStateFilter(event) {
    let stateValue = event.target.value;
    let state = this.state.filterStateList;
    /*
        let state = [
          "TUTTI",
          "RICHIESTA",
          "APPROFONDIRE",
          "ELABORAZIONE",
          "ERRORE",
          "COMPLETATA",
          "ANNULLATA",
          "SOSPESA",
        ];*/

    localStorage.setItem("statoValue", state[state.indexOf(stateValue)]);
  }
  handleMotivoFilter(event) {
    let motivo = event.target.value;

    let state = this.state.motivoList;
    /*
      let state = [
        "NESSUNO",
        "DI",
        "SNC",
        "PNT",
        "PUC",
        "RAD",
        "LIQ",
        "DEN",
      ];
  */
    localStorage.setItem("motivo", state[state.indexOf(motivo)]);
  }

  handleDataFilter(event) {
    let dataValue = event.target.value;

    //let state = ["Data Creazione", "Data Modifica"];
    let state = this.state.filterDateList;

    localStorage.setItem("dataValue", state[state.indexOf(dataValue)]);
  }

  handleSentFilter(event) {
    let selectedValue = event.target.value;
    //let sent = ["TUTTI", "Inviata", "Non Inviata"];
    let sent = this.state.filterSentList;
    localStorage.setItem("sentValue", sent[sent.indexOf(selectedValue)]);
  }

  handleApplicationFilter(event) {
    debugger;
    //let app = ["TUTTI", "TEE", "VOLKSBANK","TEE2"];
    let app = this.state.applicationFilterList;

    localStorage.setItem(
      "applicationValue",
      app[app.indexOf(event.target.value)]
    );
  }

  handleSentFilterAfterReload(customers) {
    let selectedValue = localStorage.getItem("sentValue");
    let tmp = [];
    if (!selectedValue) {
      tmp = customers;
    } else if (selectedValue === "Inviata") {
      for (let cus of customers) {
        if (cus.sent === true) {
          tmp.push(cus);
        }
      }
    } else if (selectedValue === "Non Inviata") {
      for (let cus of customers) {
        if (cus.sent === false || cus.sent == null) {
          tmp.push(cus);
        }
      }
    } else {
      tmp = customers;
    }

    return tmp;
  }

  handleExportFilterAfterReload(customers) {
    let exported = ["TUTTI", "Esportata", "Non esportata"];
    let exported_enum = ["TUTTI", "ESPORTATA", "NON_ESPORTATA"];

    let selectedValue = localStorage.getItem("extractValue");
    //  this.handleSentAndExported();

    let tmp = [];
    if (!selectedValue) {
      tmp = customers;
    } else if (selectedValue === "Esportata") {
      for (let cus of customers) {
        if (cus.exported === true) {
          tmp.push(cus);
        }
      }
    } else if (selectedValue === "Non esportata") {
      for (let cus of customers) {
        if (cus.exported === false) {
          tmp.push(cus);
        }
      }
    } else {
      tmp = customers;
    }
    return tmp;
  }

  handleExportFilter(event) {
    debugger;

    let selectedValue = event.target.value;
    let exported = this.state.filterExportList;
    console.log(this.state.filterExportList);
    //let exported = ["TUTTI", "Esportata", "Non esportato"];
    let exported_enum = ["TUTTI", "ESPORTATA", "NON_ESPORTATA"];

    localStorage.setItem(
      "extractValue",
      exported[exported.indexOf(selectedValue)]
    );
  }

  handleRobotIdFilter(event) {
    let robotIdValue = event.target.value;
    debugger;
    //console.log(this.state.robotIdFilterList)
    console.log(localStorage.getItem("robotIdFilter"));
    //let robotId = this.state.robotIdFilterList;
    let robotId = JSON.parse(localStorage.getItem("robotIdFilter"));

    localStorage.setItem("robotId", robotId[robotId.indexOf(robotIdValue)]);
  }

  handleModalitaEvasioneFilter(event) {
    let modalitaEvasione = event.target.value;
    debugger;

    let modalitaEvasioneValue = JSON.parse(localStorage.getItem("modalitaEvasioneFilter"));
    //let modalitaEvasioneValue = this.state.modalitaEvasioneFilterList;
    localStorage.setItem("modalitaEvasione",modalitaEvasioneValue[modalitaEvasioneValue.indexOf(modalitaEvasione)]);

  }

  extractAllCustomers() { }

  extractAllCustomersListFromBackend() {
    debugger;
    this.setState({ content: false });
    let querySearch = localStorage.getItem("denominazione"); //querySearch è quello che ha inserito l'utente in alto a sx (il periodo)
    let appName = AuthService.getApplicationName();
    if (
      appName === "ADMINISTRATION_PANEL" &&
      localStorage.getItem("applicationValue") !== "TUTTI" &&
      localStorage.getItem("applicationValue") !== null
    ) {
      appName = localStorage.getItem("applicationValue");
    }

    axiosApiCaller
      .post(AuthService.getBackendApiTeeAccountingList(), {
        applicationName: appName,
        querySearch: querySearch,
        start: this.state.startDate,
        end: this.state.endDate,
        exportedValue: localStorage.getItem("extractValue"),
        sentValue: localStorage.getItem("sentValue"),
        stateValue: localStorage.getItem("statoValue"),
        country: localStorage.getItem("country"),
        robotId: localStorage.getItem("robotId"),
        modalitaEvasioneValue: localStorage.getItem("modalitaEvasione"),
        dataValue: localStorage.getItem("dataValue"),
        motivo: localStorage.getItem("motivo")
      })
      .then(res => {
        console.log(res);
        //il then/catch è quello della vecchia fetch, va cambiato (eventualmente)
        if (res.data.success === true) {
          this.state.content = true;

          let requestHistory = JSON.parse(
            localStorage.getItem("requestHistory") || "[]"
          );

          if (requestHistory.length > 0) {
            localStorage.removeItem("requestHistory");
            localStorage.setItem(
              "requestHistory",
              JSON.stringify(res.data.payload)
            );
          } else {
            localStorage.setItem(
              "requestHistory",
              JSON.stringify(res.data.payload)
            );
          }
          //debugger
          let customers = this.formatData(res.data.payload);

          customers = this.createOperationMenu(customers);
          customers = this.setResponseDownload(customers);
          customers = this.setSendJson(customers);

          // customers = this.setUploadIcon(customers)

          if (this.state.currentLoggedUserCanManageVisualizeResult) {
            customers = this.setRequestClickable(customers);
            customers = this.setIncompleteRequestButton(customers);
          }
          customers = this.handleExportFilterAfterReload(customers);
          customers = this.handleSentFilterAfterReload(customers);

          this.setState({ customersList: customers });
          this.setState({ tmpCustomersList: customers });
          this.setState({ totalElements: res.data.totalElements });
          this.setState({ numberQueryResults: res.data.totalElements });
          this.resetFilters({ offSet: res.data.offSet });
          this.resetFilters({ pageNumber: res.data.pageNumber });
          this.setState({ currentMinutesRefreshDelay: 0 });
          this.lastRefreshTime = new Date();
          // setInterval(() => {
          //   this.setState({
          //     currentMinutesRefreshDelay: Math.floor(
          //       (new Date() - this.lastRefreshTime) / 1000 / 60
          //     ),
          //   });
          // }, 30001);
          this.hideAlert();
        } else {
          this.state.content = true;
          this.deniedOperation(
            "Impossibile visualizzare le informazioni riguardanti i clienti.",
            "Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma."
          );
        }
      })
      .catch(error => {
        this.state.content = true;
        console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
        console.log(JSON.stringify(error.message));
        this.deniedOperation(
          "Impossibile visualizzare le informazioni riguardanti i clienti.",
          "Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma."
        );
      });
  }

  // CLEAN DATA FROM NULL VALUE
  cleanData(rawData) {
    let cleanedData = [];
    if (!rawData || rawData == null) {
      return cleanedData;
    }
    rawData.forEach(rawElement => {
      if (!rawElement.ragioneSociale || rawElement.ragioneSociale == null) {
        rawElement.ragioneSociale = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
      }
      if (!rawElement.indirizzo || rawElement.indirizzo == null) {
        rawElement.indirizzo = "";
      }
      if (!rawElement.localita || rawElement.localita == null) {
        rawElement.localita = "";
      }
      if (!rawElement.cap || rawElement.cap == null) {
        rawElement.cap = "";
      }
      if (!rawElement.paeseProvincia || rawElement.paeseProvincia == null) {
        rawElement.paeseProvincia = "";
      }
      if (!rawElement.partitaIva || rawElement.partitaIva == null) {
        rawElement.partitaIva = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
      }
      if (
        !rawElement.dataAggiornamento ||
        rawElement.dataAggiornamento == null
      ) {
        rawElement.dataAggiornamento = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
        rawElement.aggiornato = "No";
      } else {
        var d1 = Date.parse(rawElement.dataAggiornamento);
        if (
          AuthService.getLoggedUserPreviousSession() != null &&
          AuthService.getLoggedUserPreviousSession().startTime != null
        ) {
          var d2 = Date.parse(
            AuthService.getLoggedUserPreviousSession().startTime
          );
          rawElement.aggiornato = d1 > d2 ? "Si" : "No";
        } else {
          rawElement.aggiornato = "Si";
        }
        rawElement.dtAggiornamento = this.formatSessionDateTime(
          rawElement.dataAggiornamento
        );
      }
      if (!rawElement.monitoraggio || rawElement.monitoraggio == null) {
        rawElement.monitoraggio = "No";
      }

      if (
        !rawElement.foreignActualOwners ||
        rawElement.foreignActualOwners == null
      ) {
        rawElement.foreignActualOwnersCount = 0;
      } else {
        rawElement.foreignActualOwnersCount =
          rawElement.foreignActualOwners.length;
        rawElement.foreignActualOwners.forEach(ownerElement => {
          if (!ownerElement.name || ownerElement.name == null) {
            ownerElement.name = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.role || ownerElement.role == null) {
            ownerElement.role = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.dateOfBirth || ownerElement.dateOfBirth == null) {
            ownerElement.dateOfBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (
            !ownerElement.appointedNotifiedOn ||
            ownerElement.appointedNotifiedOn == null
          ) {
            ownerElement.appointedNotifiedOn = "";
          }
          if (!ownerElement.nationality || ownerElement.nationality == null) {
            ownerElement.nationality = "";
          }
          if (
            !ownerElement.countryOfResidence ||
            ownerElement.countryOfResidence == null
          ) {
            ownerElement.countryOfResidence = "";
          }
          if (
            !ownerElement.societaCollegate ||
            ownerElement.societaCollegate == null
          ) {
            ownerElement.societaCollegate = "";
          }
          if (!ownerElement.note || ownerElement.note == null) {
            ownerElement.note = "";
          }
        });
      }
      cleanedData.push(rawElement);
    });
    return cleanedData;
  }

  formatData(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].creationDatetime = this.formatSessionDateTime(
        list[i].creationDatetime
      );
      list[i].lastEditDatetime = this.formatSessionDateTime(
        list[i].lastEditDatetime
      );
    }

    console.log(list);
    return list;
  }

  formatSessionDateTime(dateTime) {
    //console.log(JSON.stringify(dateTime));
    var date = new Date(dateTime);
    return new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }).format(date);
  }

  setIncompleteRequestButton(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].incompletedRequestButton = (
        <div style={{ minWidth: "140px" }}>
          <RemoveRedEyeRounded
            fontSize="inherit"
            style={{
              fontSize: "30px",
              color: "#757575",
              float: "left",
              cursor: "pointer"
            }}
            onClick={() => {
              this.setCurrentIncompletedReqList(
                list[i].incompletedReqList,
                list[i].cancelMonitoringList,
                list[i].id
              );
            }}
            round
          />
          <span
            style={{
              paddingLeft: "9px",
              marginTop: "5px",
              display: "inline-block"
            }}
          >
            {list[i].incompletedRequests}&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </div>
      );
    }

    return list;
  }

  setCurrentIncompletedReqList(incompleteList, cancelMonitoringList, id) {
    this.setState(
      {
        currentIncompletedRequestList: incompleteList,
        canceldMonitoringList: cancelMonitoringList,
        currentIncompletedRequestId: id
      },
      () => {
        this.setState({ incompletesReqDivIsVisible: true });
      }
    );
  }

  setRequestClickable(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].clickableId = (
        <Link
          to={{
            pathname: "/consolle/request-result",
            state: { idRequest: list[i].id }
          }}
        >
          <span>{list[i].id}</span>
        </Link>
      );
    }

    return list;
  }

  setResponseDownload(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].responseDownload = (
        <IconButton
          aria-label="download"
          onClick={() => {
            this.getFileWithResults(list[i].id);
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
      );
    }

    return list;
  }

  setSendJson(list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].sent != undefined && list[i].sent == true) {
        //TODO Sostituire con spunta verde come esportata
        list[i].sendJson = (
          <span>
            <FontAwesomeIcon color="green" icon={faCheck} />
          </span>
        );
      } else {
        list[i].sendJson = (
          <IconButton
            aria-label="UnarchiveIcon"
            onClick={() => {
              this.sendJsonConfirmationAlert(list[i].id);
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        );
      }
    }

    return list;
  }

  createOperationMenu(list) {
    for (var i = 0; i < list.length; i++) {
      let id = list[i].id;
      list[i].operationMenu = (
        <OperationMenu
          menus={[
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => {
                this.downloadFileOfRequest(id);
              }}
            >
              {" "}
              INPUT
              <CloudDownloadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />{" "}
            </p>,

            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => {
                this.downloadInpuWithPlatformId(id);
              }}
            >
              {" "}
              INPUT CON PLATFORMID
              <CloudDownloadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />{" "}
            </p>,

            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.getFileWithResults(id)}
            >
              {" "}
              RISULTATI
              <CloudDownloadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>,
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.uploadAndSend("-partners", id)}
            >
              SOCI{" "}
              <CloudUploadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>,
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.uploadAndSend("-executives", id)}
            >
              EXECUTIVES
              <CloudUploadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>,
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.uploadAndSend("-elabora", id)}
            >
              ELABORA
              <CloudUploadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>
          ]}
          listener={this}
        ></OperationMenu>
      );
    }
    return list;
  }

  sendJsonConfirmationAlert(idRequest) {
    console.log(idRequest);
    let primaryMsg = "Sending Json file...";
    let secondaryMsg = "Are you sure you want to send the file?";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="success"
                  onClick={() => {
                    this.sendJsonOnS3(idRequest);
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="danger"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }

  redirectAfterSend(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    window.location.replace("/consolle/storico-richieste");
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }

  errorSendAlert(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="danger"
                  onClick={() => {
                    this.hideAlert();
                    window.location.replace("/consolle/storico-richieste");
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }
  sendJsonOnS3(idRequest) {
    axios
      .get(
        AuthService.getBackendApiTeeSendJsonOnS3() + "?idRequest=" + idRequest,
        {
          // responseType: "blob",
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        if (response.data.success === true) {
          console.log("il campo success è true " + response.data.message);
          let title = "Json file sent";
          let message = response.data.message;
          this.redirectAfterSend(title, message);
        } else {
          let title = "Error sending Json";
          let message = response.data.message;
          this.errorSendAlert(title, message);

          console.log("il campo success è false " + response.data.message);
        }
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.getBackendApiTeeSendJsonOnS3() +
                  "?idRequest=" +
                  idRequest,
                  {
                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  if (response.data.success === true) {
                    let title = "Json file sent";
                    let message = response.data.message;
                    this.redirectAfterSend(title, message);
                  } else {
                  }
                });
            })
            .catch(error => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  getFileWithResults(idRequest) {
    axios
      .get(
        AuthService.getBackendApiTeeResponseFileDownload() +
        "?idRequest=" +
        idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Analysis_results.xlsx");
        //document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.getBackendApiTeeResponseFileDownload() +
                  "?idRequest=" +
                  idRequest,
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "Analysis_results.xlsx");
                  link.click();
                });
            })
            .catch(error => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  formatSessionDateTime(dateTime) {
    //console.log(JSON.stringify(dateTime));
    var date = new Date(dateTime);
    return new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }).format(date);
  }

  uploadAndSend(contenutoFile, id) {
    //Mentre carico i soci (partners) non posso caricare executives finché il processo non è terminato
    this.setState({ buttonUploadDisabled: true });
    //this.state.buttonUploadDisabled = true;
    this.setUploadIcon(this.state.customersList);
    (async () => {
      const { value: file } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          // 'accept': 'text/*',
          accept: ".xlsx",
          "aria-label": "Upload file"
        }
      });

      if (file) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("idRichiesta", id);

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let address =
          AuthService.getBackendApiTeeExternalSources() + contenutoFile;
        let address2 =
          AuthService.getBackendApiTeeExternalSources() + "/new-file-upload"; //da cancellare
        let address3 =
          AuthService.getBackendApiTeeExternalSources() +
          "/file-upload" +
          contenutoFile; //da cancellare

        //Mentre carico i soci (partners) non posso caricare executives finché il processo non è terminato
        this.setState({ buttonUploadDisabled: true });
        this.state.buttonUploadDisabled = true;
        this.setUploadIcon(this.state.customersList);

        axios
          .post(address3, formData, {
            headers: headerWithAuth
          })
          .then(res => {
            //Quando mi arriva il risultato riabilito i pulsanti
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              if (this.state.alert !== null) {
                this.hideAlert();
              }
              this.showRedirectAlert(title, message);
            } else {
              console.log("CARICAMENTO DOCUMENTO FALLITO");
            }
          })
          .catch(error => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
          });
      }
    })();
  }

  uploadAndSendMulti() {
    this.setState({ buttonUploadDisabled: true });
    this.setUploadIcon(this.state.customersList);
    (async () => {
      const { value: files } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          accept: ".xlsx",
          multiple: "multiple",
          "aria-label": "Upload file"
        }
      });

      if (files != null ? files.length > 0 : false) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }

        // formData.append("idRichiesta", id);

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let apiUrlToCall =
          AuthService.getBackendApiTeeExternalSources() +
          "/upload-soci-executives";
        this.hideAlert();
        this.caricamento("Upload in corso...");
        axiosApiCaller
          .post(apiUrlToCall, formData, {
            headers: headerWithAuth
          })

          .then(res => {
            this.hideAlert();
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              this.showRedirectAlert(title, message);
            } else {
              this.deniedOperation(
                "CARICAMENTO DOCUMENTO FALLITO",
                res.data.message
              );
            }
          })
          .catch(error => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
            this.deniedOperation(
              "COMUNICATION ERROR WITH APP BACKEND SERVICE..",
              error.message
            );
          });
      }
    })();
  }

  uploadFileNuovoTracciato() {
    this.setState({ buttonUploadDisabled: true });
    this.setUploadIcon(this.state.customersList);
    (async () => {
      const { value: file } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          accept: ".xlsx",
          multiple: "multiple",
          "aria-label": "Upload file"
        }
      });

      if (file != null ? file.length > 0 : false) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        for (let i = 0; i < file.length; i++) {
          formData.append("file", file[i]);
          formData.append("idRichiestaInput", "");
        }

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let apiUrlToCall =
          AuthService.getBackendApiTeeExternalSources() +
          "/upload-file-nuovo-tracciato";
        this.hideAlert();
        this.caricamento("Upload in corso...");
        axiosApiCaller
          .post(apiUrlToCall, formData, {
            headers: headerWithAuth
          })

          .then(res => {
            this.hideAlert();
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              this.showRedirectAlert(title, message);
            } else {
              this.deniedOperation(
                "CARICAMENTO DOCUMENTO FALLITO",
                res.data.message
              );
            }
          })
          .catch(error => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
            this.deniedOperation(
              "COMUNICATION ERROR WITH APP BACKEND SERVICE..",
              error.message
            );
          });
      }
    })();
  }

  upload(type) {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                { }
                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: event => {
                      this.handleChangeDownloadAmount(event);
                    }
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.caricamento("Download in corso...");

                    if (type === "Lux") this.search("LUX");
                    else if (type === "Uk") this.search("UK");
                    else if (type === "Ita") this.search("ITA");
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }

  ricaricaPosizioniSelezionate() {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                { }
                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: event => {
                      this.handleChangeDownloadAmount(event);
                    }
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.ricalcolo("Ricalcolo posizioni in corso...");
                    this.invokeRicaricaPosizioniSelezionate();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }
  showTimeNoticeAlert(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }

  showRedirectAlert(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Link to="/consolle/storico-richieste">
                  <Button
                    style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                    color="success"
                    onClick={() => {
                      this.hideAlert();
                    }}
                    round
                  >
                    <CheckCircle style={{ fontSize: "28px" }} />
                    &nbsp;Ok
                  </Button>
                </Link>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }

  uploadAndSend(contenutoFile, id) {
    (async () => {
      const { value: file } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          // 'accept': 'text/*',
          accept: ".xlsx",
          "aria-label": "Upload file"
        }
      });

      if (file) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("idRichiestaInput", id);

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let address =
          AuthService.getBackendApiTeeExternalSources() + contenutoFile;
        let address2 =
          AuthService.getBackendApiTeeExternalSources() + "/new-file-upload"; //da cancellare
        let address3 = "";
        if (contenutoFile === "-elabora") {
          address3 =
            AuthService.getBackendApiTeeExternalSources() +
            "/upload-file-nuovo-tracciato";
        } else {
          address3 =
            AuthService.getBackendApiTeeExternalSources() +
            "/file-upload" +
            contenutoFile; //da cancellare
        }

        //Mentre carico i soci (partners) non posso caricare executives finché il processo non è terminato
        this.setState({ buttonUploadDisabled: true });
        this.state.buttonUploadDisabled = true;
        this.setUploadIcon(this.state.customersList);

        axios
          .post(address3, formData, {
            headers: headerWithAuth
          })
          .then(res => {
            //Quando mi arriva il risultato riabilito i pulsanti
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              if (this.state.alert !== null) {
                this.hideAlert();
              }
              this.showRedirectAlert(title, message);
            } else {
              console.log("CARICAMETO DEL DOCUMENTO FALLITO");
            }
          })
          .catch(error => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
            if (
              error.response &&
              error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
            ) {
              const originalRequest = error.config;
              originalRequest._retry = true;
              let refreshToken2 = localStorage.getItem("loggedRefreshToken");
              let tokenVecchio2 = localStorage.getItem("loggedToken");
              let username2 = localStorage.getItem("loginEmail");
              return axiosApiCaller
                .post(AuthService.getRefreshedToken(), {
                  refreshToken: refreshToken2,
                  tokenVecchio: tokenVecchio2,
                  username: username2
                })
                .then(rest => {
                  localStorage.removeItem("loggedToken");
                  localStorage.removeItem("loggedRefreshToken");
                  localStorage.setItem(
                    "loggedToken",
                    rest.data.payload.jwtToken
                  );
                  localStorage.setItem(
                    "loggedRefreshToken",
                    rest.data.payload.jwtTokenRefresh
                  );
                  AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
                  AuthService.setLoggedUserJwtTokenRefresh(
                    rest.data.payload.jwtTokenRefresh
                  );
                  originalRequest.headers["Authorization"] =
                    rest.data.payload.jwtToken;
                  originalRequest.headers["TokenRefresh"] =
                    rest.data.payload.jwtTokenRefresh;
                  axios.defaults.headers["Authorization"] =
                    rest.data.payload.jwtToken;
                  headerWithAuth = AuthService.getAuthHeaderAsObj();
                  axios
                    .post(address3, formData, {
                      headers: headerWithAuth
                    })
                    .then(res => {
                      //Quando mi arriva il risultato riabilito i pulsanti
                      this.setState({ buttonUploadDisabled: false });
                      this.state.buttonUploadDisabled = false;
                      this.setUploadIcon(this.state.customersList);
                      if (res.data.success === true) {
                        let title = res.data.message;
                        let message = "";
                        if (this.state.alert !== null) {
                          this.hideAlert();
                        }
                        this.showRedirectAlert(title, message);
                      } else {
                        console.log("CARICAMETO DEL DOCUMENTO FALLITO");
                      }
                    })
                    .catch(error => {
                      console.log(error.response);
                      AuthService.setLoggedUserJwtToken(null);
                      AuthService.setLoggedUserJwtTokenRefresh(null);
                      return (window.location.href = "/home");
                    });
                })
                .catch(error => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            }
          });
      }
    })();
  }

  setUploadIcon(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].uploadSoci = (
        <IconButton
          aria-label="upload soci"
          disabled={this.state.buttonUploadDisabled}
          onClick={e => {
            this.uploadAndSend("-partners", list[i].id);
          }}
        >
          <CloudUploadIcon />
        </IconButton>
      );

      list[i].uploadExecutives = (
        <IconButton
          aria-label="upload executives"
          disabled={this.state.buttonUploadDisabled}
          onClick={e => {
            this.uploadAndSend("-executives", list[i].id);
          }}
        >
          <CloudUploadIcon />
        </IconButton>
      );
    }

    return list;
  }

  downloadFileOfRequest(idRequest) {
    axios
      .get(
        AuthService.getBackendApiTeeRequestFileDownload() +
        "?idRequest=" +
        idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Input.xlsx");
        link.click();
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.getBackendApiTeeRequestFileDownload() +
                  "?idRequest=" +
                  idRequest,
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "Input.xlsx");
                  link.click();
                });
            })
            .catch(error => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  downloadInpuWithPlatformId(idRequest) {
    axios
      .get(
        AuthService.downloadInputWithPlatformId() + "?idRequest=" + idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Input_with_platformId.xlsx");
        link.click();
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.downloadInputWithPlatformId() +
                  "?idRequest=" +
                  idRequest,
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "Input_with_platformId.xlsx");
                  link.click();
                })
                .catch(error => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch(error => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }
  handleSelected(param, filterType) {
    let tmp = [];
    if (param === "Esportata") {
      for (let cus of this.state.tmpCustomersList) {
        if (cus.exported === true) {
          tmp.push(cus);
        }
      }
    } else if (param === "Non esportata") {
      for (let cus of this.state.tmpCustomersList) {
        if (cus.exported === false) {
          tmp.push(cus);
        }
      }
    } else {
      tmp = this.state.tmpCustomersList;
    }

    this.setState({ customersList: tmp });
  }

  handleChangeStartDate(date) {
    this.setState({ startDate: date });
    localStorage.setItem("startDate", date);
  }

  handleChangeEndDate(date) {
    this.setState({ endDate: date });
    localStorage.setItem("endDate", date);
  }

  downloadOutputAlert() {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: event => {
                      this.handleChangeDownloadAmount(event);
                    }
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.caricamento("Download in corso...");
                    this.downloadOutput();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }

  sendJsonAlert() {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: event => {
                      this.handleChangeDownloadAmount(event);
                    }
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.caricamento("Invio in corso...");
                    this.sendJsonOutput();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      )
    });
  }

  handleExportFilter(event) {
    let selectedValue = event.target.value;
    let exported = ["TUTTI", "Esportata", "Non esportata"];

    localStorage.setItem(
      "extractValue",
      exported[exported.indexOf(selectedValue)]
    );
  }

  handleChangeDownloadAmount(event) {
    localStorage.setItem("downoadAmount", event.target.value);
    this.setState({ downoadAmount: event.target.value });
  }

  handlePgeChanged(oldPage, newPage, rowsPerPage) {
    let customersFromStorage = JSON.parse(
      localStorage.getItem("requestHistory") || "[]"
    );
    let offSet = newPage * rowsPerPage;
    if (newPage > oldPage) {
      if (
        newPage > 0 &&
        newPage % 2 === 0 &&
        customersFromStorage[newPage * (2 * rowsPerPage)] === undefined
      ) {
        this.getNextPageData(offSet);
      }
    }
    this.setState({ pageNumber: newPage });
  }
  getNextPageData(offSet) {
    let querySearch = this.state.name; //querySearch è quello che ha inserito l'utente in alto a sx
    let appName = AuthService.getApplicationName();
    axiosApiCaller
      .post(AuthService.getBackendApiTeeAccountingList(), {
        applicationName: appName,
        querySearch: querySearch,
        start: this.state.startDate,
        end: this.state.endDate,
        offSet: offSet,
        country: this.state.country
      })
      .then(res => {
        if (res.data.success === true) {
          let loadedRequest = JSON.parse(
            localStorage.getItem("requestHistory") || "[]"
          );

          for (let data of res.data.payload) {
            loadedRequest.push(data);
          }

          localStorage.setItem("requestHistory", JSON.stringify(loadedRequest));

          let customers = this.formatData(loadedRequest);

          customers = this.setResponseDownload(customers);

          customers = this.setUploadIcon(customers);
          if (this.state.currentLoggedUserCanManageVisualizeResult) {
            customers = this.setRequestClickable(customers);
            customers = this.setIncompleteRequestButton(customers);
          }
          this.setState({ customersList: customers });
          this.setState({ tmpCustomersList: customers });
          this.setState({ totalElements: res.data.totalElements });
          this.setState({ numberQueryResults: res.data.totalElements });
          this.resetFilters({ offSet: res.data.offSet });
          this.resetFilters({ pageNumber: res.data.pageNumber });
          this.setState({ currentMinutesRefreshDelay: 0 });
        }
      });
  }

  search(robot_Type) {
    let querySearch = localStorage.getItem("denominazione");
    let appName = AuthService.getApplicationName();
    if (
      appName === "ADMINISTRATION_PANEL" &&
      localStorage.getItem("applicationValue") !== "TUTTI" &&
      localStorage.getItem("applicationValue") !== null
    ) {
      appName = localStorage.getItem("applicationValue");
    }

    axios
      .post(
        AuthService.getBackendApiForSearchRobot(),
        {
          // applicationName: "TEE",
          applicationName: appName,
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValue"),
          sentValue: localStorage.getItem("sentValue"),
          stateValue: localStorage.getItem("statoValue"),
          country: localStorage.getItem("country"),
          robotId: localStorage.getItem("robotId"),
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasione"),
          dataValue: localStorage.getItem("dataValue"),
          motivo: localStorage.getItem("motivo"),
          robotType: robot_Type,
          rispostaMonitoraggio: false
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        this.hideAlert();
        window.location.reload();
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");

          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForSearchRobot(),
                  {
                    //applicationName: "TEE",
                    applicationName: appName,
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount
                  },
                  {
                    responseType: "application/json",
                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  window.location.reload();
                })
                .catch(error => {
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch(error => {
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  invokeRicaricaPosizioniSelezionate() {
    let querySearch = localStorage.getItem("denominazione");
    let appName = AuthService.getApplicationName();
    if (
      appName === "ADMINISTRATION_PANEL" &&
      localStorage.getItem("applicationValue") !== "TUTTI" &&
      localStorage.getItem("applicationValue") !== null
    ) {
      appName = localStorage.getItem("applicationValue");
    }

    axios
      .post(
        AuthService.getBackendApiForReloadPositions(),
        {
          //applicationName: "TEE",
          applicationName: appName,
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValue"),
          sentValue: localStorage.getItem("sentValue"),
          stateValue: localStorage.getItem("statoValue"),
          country: localStorage.getItem("country"),
          robotId: localStorage.getItem("robotId"),
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasione"),
          dataValue: localStorage.getItem("dataValue"),
          motivo: localStorage.getItem("motivo"),
          rispostaMonitoraggio: false
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        this.hideAlert();
        window.location.reload();
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");

          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForReloadPositions(),
                  {
                    //applicationName: "TEE",
                    applicationName: appName,
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount
                  },
                  {
                    responseType: "application/json",
                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  window.location.reload();
                })
                .catch(error => {
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch(error => {
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }
}
export default withStyles(styles)(RequestHistory);
