import React from "react";
import { withRouter } from "react-router-dom";
import {
  axiosApiCaller,
  HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED,
} from "layouts/AxiosService";
import * as AuthService from "services/AuthService.js";

export function setAllList() {
  console.log("local");

  let motivoList = [
    "NESSUNO",
    "DI",
    "SNC",
    "PNT",
    "PUC",
    "RAD",
    "LIQ",
    "DEN",
    "ALTRO",
  ];

  localStorage.setItem("motivoList", JSON.stringify(motivoList));

  let filterExportList = ["TUTTI", "Esportata", "Non esportata"];
  localStorage.setItem("filterExport", JSON.stringify(filterExportList));

  let applicationFilterList = ["TUTTI", "TEE", "VOLKSBANK", "TEE2"];
  localStorage.setItem(
    "applicationFilter",
    JSON.stringify(applicationFilterList)
  );

  let filterDateList = ["Data Creazione", "Data Modifica", "Ultimo Invio"];
  localStorage.setItem("filterDate", JSON.stringify(filterDateList));

  let filterSentList = ["TUTTI", "Inviata", "Non Inviata"];
  localStorage.setItem("filterSent", JSON.stringify(filterSentList));

  let filterStateList = [
    "TUTTI",
    "RICHIESTA",
    "APPROFONDIRE",
    "ELABORAZIONE",
    "ERRORE",
    "COMPLETATA",
    "ANNULLATA",
    "SOSPESA",
  ];
  localStorage.setItem("filterState", JSON.stringify(filterStateList));

  let robotIdFilterList = ["TUTTI", "Con robotId", "Senza robotId"];
  localStorage.setItem("robotIdFilter", JSON.stringify(robotIdFilterList));

  let monitoraggioFilterList = [
    "TUTTI",
    "Monitoraggio Uguale",
    "Monitoraggio Differente",
  ];
  localStorage.setItem(
    "monitoraggioFilter",
    JSON.stringify(monitoraggioFilterList)
  );
 

  let modalitaEvasioneFilterList = [
    "NESSUNA",
    "AUTOMATICA", 
    "OPERATORE_COMPLESSO", 
    "OPERATORE_SEMPLICE", 
    "SCARTO",];
  localStorage.setItem(
    "modalitaEvasioneFilter",
     JSON.stringify(modalitaEvasioneFilterList)
    );
}

//funzione  per prelevare liste da backend

export function getAllList() {
  let motivoList = [];
  let filterExportList = [];
  let applicationFilterList = [];
  let filterDateList = [];
  let filterSentList = [];
  let filterStateList = [];
  let robotIdFilterList = [];
  let monitoraggioFilterList = [];
  let applicationName = AuthService.getApplicationName();
  let queryString = "?applicationName=" + applicationName;
  let modalitaEvasioneFilterList = [];

  const fetchData = () => {
    try {
      axiosApiCaller
        .get(AuthService.getGlobalList() + queryString)
        //axiosApiCaller.get("https://mocki.io/v1/40f24199-a685-4dbc-8f08-abfe35a3cb2d")

        .then((res) => {
          if (res.data.success === true) {
            console.log("fetch");

            motivoList = res.data.payload.motiviList;
            console.log(motivoList);

            filterExportList = res.data.payload.filterExportList;
            applicationFilterList = res.data.payload.applicationFilterList;
            filterDateList = res.data.payload.filterDateList;
            filterSentList = res.data.payload.filterSentList;
            filterStateList = res.data.payload.filterStateList;
            robotIdFilterList = res.data.payload.robotIdFilterList;
            monitoraggioFilterList = res.data.payload.monitoraggioFilterList;
            modalitaEvasioneFilterList = res.data.payload.modalitaEvasioneFilterList;
          }
        })
        .then(() => {
          localStorage.setItem("motivoList", JSON.stringify(motivoList));
          localStorage.setItem(
            "filterExport",
            JSON.stringify(filterExportList)
          );
          localStorage.setItem(
            "applicationFilter",
            JSON.stringify(applicationFilterList)
          );
          localStorage.setItem("filterDate", JSON.stringify(filterDateList));
          localStorage.setItem("filterSent", JSON.stringify(filterSentList));
          localStorage.setItem("filterState", JSON.stringify(filterStateList));
          localStorage.setItem(
            "robotIdFilter",
            JSON.stringify(robotIdFilterList)
          );
          localStorage.setItem(
            "monitoraggioFilter",
            JSON.stringify(monitoraggioFilterList)
          );
          localStorage.setItem(
            "modalitaEvasioneFilter",
            JSON.stringify(modalitaEvasioneFilterList)
          );
        });
    } catch (error) {
      console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
      console.log(JSON.stringify(error.message));
      // this.deniedOperation('Impossibile visualizzare le informazioni', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
    }
  };
  const data = fetchData();
  console.log("%%%%%%%%%%%");
  console.log(`Questi sono i DATI ${data}`);
}
