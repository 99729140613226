import DateFnsUtils from "@date-io/date-fns";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import BuildIcon from "@material-ui/icons/Build";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RemoveRedEyeRounded from "@material-ui/icons/RemoveRedEye";
import SearchIcon from "@material-ui/icons/Search";
import ToggleOn from "@material-ui/icons/ToggleOn";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import axios from "axios";
import FilterableMultiRequestsManagement from "components/COMMON/FilterableMultiRequestManagement";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import GridItem from "components/Grid/GridItem.js";
// import DateFnsUtils from '@date-io/date-fns';
import { format } from "date-fns";
import {
  axiosApiCaller,
  HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
} from "layouts/AxiosService";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { LoopCircleLoading } from "react-loadingg";
import { Link } from "react-router-dom";
import * as AuthService from "services/AuthService.js";
import Swal from "sweetalert2";
import OperationMenu from "views/COMMON/OperationMenu";
import * as GlobalList from "services/GlobalList.js";
import { getAllList } from "services/GlobalList";

const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "23px",
    height: "23px",
    top: "0px",
    left: "2px",
    position: "relative",
  },
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px",
  },
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px",
    width: "80px",
    height: "auto",
    minWidth: "auto",
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px",
    width: "auto",
    height: "auto",
    minWidth: "auto",
  },
};

const styles = {
  ...localStyles,
  ...dashboardStyles,
  ...checkBoxStyles,
};

// COSTANTI PER I VALORI NON DEFINITI O NULLI
export const NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE = "(Non disponibile)";
export const NULL_STRATEGIA_SUBSTITUTION_VALUE = "Nessuna Strategia";
export const NULL_STATO_SUBSTITUTION_VALUE = "Nessun Stato di Elaborazione";

const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000;

class RequestHistoryMonitoraggio extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.lastRefreshTime = new Date();

    this.onKeyUp = this.onKeyUp.bind(this);
    this.setCurrentIncompletedReqList = this.setCurrentIncompletedReqList.bind(
      this
    );
    this.hideAlert = this.hideAlert.bind(this);
    this.handleShowMonitoredChange = this.handleShowMonitoredChange.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
    this.downloadOutputAlert = this.downloadOutputAlert.bind(this);
    this.handleChangeDownloadAmount = this.handleChangeDownloadAmount.bind(
      this
    );
    this.sendJsonConfirmationAlert = this.sendJsonConfirmationAlert.bind(this);
    this.handleSentAndExported = this.handleSentAndExported.bind(this);
    this.handleExportFilter = this.handleExportFilter.bind(this);
    this.handleSentFilter = this.handleSentFilter.bind(this);
    this.handleApplicationFilter = this.handleApplicationFilter.bind(this);
    this.handleStateFilter = this.handleStateFilter.bind(this);
    this.handleMotivoFilter = this.handleMotivoFilter.bind(this);
    this.sendJsonAlert = this.sendJsonAlert.bind(this);
    this.setAllList = this.setAllList.bind(this);
    this.handleModalitaEvasioneFilter = this.handleModalitaEvasioneFilter.bind(this);


  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.extractAllCustomersListFromBackend();
    }
  }

  getInitialState() {
    let date = new Date();
    return {
      alert: null,
      usersSessionsList: [],
      customersList: [],
      applicationFilterList: [],
      filterDateList: [],
      filterSentList: [],
      filterStateList: [],
      motivoList: [],
      filterExportList: [],
      robotIdFilterList: [],
      monitoraggioFilterList: [],
      offSet: -1,
      totalElements: 0,
      numberQueryResults: 0,
      pageNumber: 0,
      tmpCustomersList: [],
      currentIncompletedRequestList: [],
      currentIncompletedRequestId: "",
      incompletesReqDivIsVisible: false,
      usersSessionsChartLabels: [],
      usersSessionsChartSeries: [],
      modalitaEvasioneFilterList: [],
      usersSessionsChartMax: 100,
      currentMinutesRefreshDelay: 0,
      currentLoggedUserCanManageVisualizeResult: AuthService.authenticathedUserCanManageUsers(),
      forzaInvio: false,
      startDate: localStorage.getItem("startDateMonitoraggio")
        ? new Date(localStorage.getItem("startDateMonitoraggio"))
        : date.setDate(date.getDate() - 7),
      endDate: localStorage.getItem("endDateMonitoraggio")
        ? new Date(localStorage.getItem("endDateMonitoraggio"))
        : new Date(),
      startDateValid: false,
      endDateValid: false,
      buttonUploadDisabled: false,
      applicationValue: localStorage.getItem("applicationValue")
        ? localStorage.getItem("applicationValue")
        : "TUTTI",
      extractValue: localStorage.getItem("extractValueMonitoraggio")
        ? localStorage.getItem("extractValueMonitoraggio")
        : "TUTTI",
      dataValue: localStorage.getItem("dataValueMonitoraggio")
        ? localStorage.getItem("dataValueMonitoraggio")
        : "Data Creazione",
      monitoraggioValue: localStorage.getItem("monitoraggioValue")
        ? localStorage.getItem("monitoraggioValue")
        : "TUTTI",
      robotId: localStorage.getItem("robotIdMonitoraggio")
        ? localStorage.getItem("robotIdMonitoraggio")
        : "TUTTI",
      sentValue: localStorage.getItem("sentValueMonitoraggio")
        ? localStorage.getItem("sentValueMonitoraggio")
        : "Non Inviata",
      statoValue: localStorage.getItem("statoValueMonitoraggio")
        ? localStorage.getItem("statoValueMonitoraggio")
        : "RICHIESTA",
      country: localStorage.getItem("countryMonitoraggio")
        ? localStorage.getItem("countryMonitoraggio")
        : "",
      motivo: localStorage.getItem("motivoMonitoraggio")
        ? localStorage.getItem("motivoMonitoraggio")
        : "NESSUNO",
      modalitaEvasioneValue: localStorage.getItem("modalitaEvasioneMonitoraggio")
        ? localStorage.getItem("modalitaEvasioneMonitoraggio")
        : "NESSUNA",
      order: "desc",
      orderBy: 2,
      canceldMonitoringList: [],
      content: false,
      numeroMonitoraggi:0,
      requestHistory:[]
    };
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  caricamento(primaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <div style={{ height: "100px" }}>
                  <LoopCircleLoading />
                </div>
              </div>
            </React.Fragment>
          }
        ></SweetAlert>
      ),
    });
  }
  ricalcolo(primaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <div style={{ height: "100px" }}>
                  <Button
                    style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                    color="cancel"
                    onClick={() => {
                      this.hideAlert();
                    }}
                    round
                  >
                    &nbsp;Cancel
                  </Button>
                </div>
              </div>
            </React.Fragment>
          }
        ></SweetAlert>
      ),
    });
  }
  componentWillMount() {
    if (
      this.state.sentValue != undefined &&
      !localStorage.getItem("sentValueMonitoraggio")
    ) {
      localStorage.setItem("sentValueMonitoraggio", this.state.sentValue);
    }
    if (
      this.state.dataValue != undefined &&
      !localStorage.getItem("dataValueMonitoraggio")
    ) {
      localStorage.setItem("dataValueMonitoraggio", this.state.dataValue);
    }
    if (
      this.state.statoValue != undefined &&
      !localStorage.getItem("statoValueMonitoraggio")
    ) {
      localStorage.setItem("statoValueMonitoraggio", this.state.statoValue);
    }
  }

  componentDidMount() {
    if (localStorage.getItem("startDateMonitoraggio") != undefined) {
      this.handleChangeStartDate(
        new Date(localStorage.getItem("startDateMonitoraggio"))
      );
      this.setState({
        startDate: new Date(localStorage.getItem("startDateMonitoraggio")),
      });
    }

    if (localStorage.getItem("endDateMonitoraggio") != undefined) {
      this.handleChangeEndDate(
        new Date(localStorage.getItem("endDateMonitoraggio"))
      );
    }

    if (localStorage.getItem("denominazioneMonitoraggio") != undefined) {
      this.handleChangeNameAfterReload(
        localStorage.getItem("denominazioneMonitoraggio")
      );
    }
    this.extractAllCustomersListFromBackend();

    this.setAllList();
  }

  componentWillUnmount() {
    /*localStorage.removeItem('startDate')
    localStorage.removeItem('endDate')
    localStorage.removeItem('extractValue')
    localStorage.removeItem('sentValue')
    localStorage.removeItem('statoValue')
    localStorage.removeItem('denominazione')
    localStorage.removeItem('country')
    localStorage.removeItem('dataValue')*/
  }

  handleChangeNameAfterReload(newValue) {
    this.setState({ name: newValue });
    localStorage.setItem("denominazioneMonitoraggio", newValue);
    if (newValue != null) {
      if (newValue.length < 3) {
        this.setState({ nameValid: false });
        this.setState({
          errorMessage: "La lunghezza della denominazione non è valida",
        });
      } else {
        this.setState({ nameValid: true });
        this.setState({ errorMessage: null });
      }
    } else {
      this.setState({ nameValid: false });
      this.setState({ errorMessage: "La denominazione non può essere vuota" });
    }
  }

  handleChangeName(event) {
    let newValue = event.target.value;
    this.setState({ name: newValue });

    localStorage.setItem("denominazioneMonitoraggio", newValue);
    if (newValue != null) {
      if (newValue.length < 3) {
        this.setState({ nameValid: false });
        this.setState({
          errorMessage: "La lunghezza della denominazione non è valida",
        });
      } else {
        this.setState({ nameValid: true });
        this.setState({ errorMessage: null });
      }
    } else {
      this.setState({ nameValid: false });
      this.setState({ errorMessage: "La denominazione non può essere vuota" });
    }
  }

  handleChangeCountry(event) {
    let newValue = event.target.value;
    this.setState({ country: newValue });

    localStorage.setItem("countryMonitoraggio", newValue);
  }

  handleShowMonitoredChange(event) {
    this.setState({ showMonitored: event.target.checked });
    if (event.target.checked) {
      this.showMonitoredLabel = "Includi le posizioni monitorate";
    } else {
      this.showMonitoredLabel = "Escludi le posizioni monitorate";
    }
  }

  handleFiltering(filter) { }

  resetFilters() {
    this.setState({
      showMonitored: true,
    });
  }

  //**download requests with incomplete data**/
  downloadRigheIncomplete(idRequest) {
    axios
      .get(
        AuthService.downloadIncompleteRequests() + "?idRequest=" + idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Richieste_incomplete.xlsx");
        link.click();
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { classes } = this.props;
    const uploadDivStyle = {
      margin: "40px 0px 40px 8px",
      paddingBottom: "28px",
      background: "white",
      boxShadow: "0px 0px 6px rgb(210, 210, 210)",
      borderRadius: "7px",
    };
    const uploadDivHeaderStyle = {
      margin: "auto",
      position: "relative",
      bottom: "16px",
      width: "97%",
      height: "45px",
      background:
        "linear-gradient(to right, rgb(140, 177, 73),rgb(192, 213, 149))",
      borderRadius: "4px",
    };
    const pHeaderStyle = {
      paddingTop: "10px",
      color: "white",
      fontSize: "15px",
      fontWeight: "450",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      textAlign: "center",
    };
    //Attenzione il bottone dettaglio non viene passato nella lista dei fields
    //let headersList=["Dowload file", "Responses", "ID Request","State","Creation Date", "Last Modified","Requests Number","Results Number", "Results"];
    let headersList;
    if (this.state.currentLoggedUserCanManageVisualizeResult) {
      headersList = [
        // 'INPUT',
        // 'INPUT CON PLATFORMID',
        //"APPLICAZIONE CLIENT",
        "DENOMINAZIONE",
        "NAZIONE",
        "STATO",
        "DATA RICHIESTA",
        "ULTIMA MODIFICA",
        "CONTROPARTI",
        "EVASE",
        "MONITORAGGIO UGUALE",
        "SCARTATE",

        // 'RISULTATI',
        // 'SOCI',
        // 'EXECUTIVES',
        "INVIA",
        "ESPORTATA",
        "OPERAZIONI",
        "NUMERO MONITORAGGI",
      ];
      if (AuthService.getApplicationName() === "ADMINISTRATION_PANEL") {
        headersList.unshift("APPLICAZIONE CLIENT")
      }
    } else {
      headersList = [
        // 'INPUT',
        // 'INPUT CON PLATFORMID',
        "APPLICAZIONE CLIENT",
        "DENOMINAZIONE",
        "NAZIONE",
        "STATO",
        "DATA RICHIESTA",
        "ULTIMA MODIFICA",
        "CONTROPARTI",
        "EVASE",
        "MONITORAGGIO UGUALE",
        "SCARTATE",
        // 'RISULTATI',
        // 'SOCI',
        // 'EXECUTIVES',
        "INVIA",
        "ESPORTATA",
        "OPERAZIONI",
        "NUMERO MONITORAGGI",
      ];
    }
    let fieldsList = [
      // 'clickableId',
      //"applicationName",
      "ragioneSociale",
      "country",
      "elaborationState",
      "creationDatetime",
      "lastEditDatetime",
      "requestsNumber",
      "completedRequests",
      "monitoraggioUguale",
      "incompletedRequestButton",

      // 'responseDownload',
      // 'uploadSoci',
      // 'uploadExecutives',
      "sendJson",
      "exported",
      "operationMenu",
      "numeroMonitoraggi",
    ];
    if (AuthService.getApplicationName() === "ADMINISTRATION_PANEL") {
      fieldsList.unshift("applicationName")
    }

    let showMonitoredControl = null;
    if (this.state.showMonitored === true) {
      showMonitoredControl = (
        <a href="#">
          <ToggleOn
            onClick={(event) => this.handleShowNotMonitoredChange(event)}
            style={{
              float: "right",
              margin: "0px 0px 0px 0px",
              width: "34px",
              height: "34px",
            }}
          />
        </a>
      );
    }

    let incompletesDivStyle = {
      display: this.state.incompletesReqDivIsVisible ? "block" : "none",
    };

    const incompletesHeaderStyle = {
      margin: "auto",
      position: "relative",
      bottom: "16px",
      width: "97%",
      height: "45px",
      background:
        "linear-gradient(to right, rgb(23, 105, 255),rgb(156, 191, 255))",
      borderRadius: "4px",
    };

    let annulledList = this.state.currentIncompletedRequestList.filter(
      (req) => req.elaborationState == "ANNULLATA"
    );
    let rowsAnnulledList = [];
    rowsAnnulledList = annulledList.map((req) => (
      <tr>
        <td style={{ paddingBottom: "10px" }}>
          {req.ragioneSociale == null || req.ragioneSociale == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.ragioneSociale
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.customerId == null || req.customerId == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.customerId
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>{req.elaborationMessage}</td>
        &nbsp;&nbsp;&nbsp;
      </tr>
    ));

    let annulledReqTable = (
      <table style={{ fontSize: "0.9em" }}>
        <tr>
          <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Customer ID</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Causa Annullamento</th>
          &nbsp;&nbsp;&nbsp;
        </tr>
        {rowsAnnulledList}
      </table>
    );

    let suspendedList = this.state.currentIncompletedRequestList.filter(
      (req) => req.elaborationState == "SOSPESA"
    );
    let rowsSuspendedList = [];
    rowsSuspendedList = suspendedList.map((req) => (
      <tr>
        <td style={{ paddingBottom: "10px" }}>
          {req.ragioneSociale == null || req.ragioneSociale == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.ragioneSociale
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.paeseProvincia == null || req.paeseProvincia == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.paeseProvincia
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.localita == null || req.localita == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.localita
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
        <td style={{ paddingBottom: "10px" }}>
          {req.indirizzo == null || req.indirizzo == "" ? (
            <b style={{ color: "red" }}>MISSING</b>
          ) : (
            req.indirizzo
          )}
        </td>
        &nbsp;&nbsp;&nbsp;
      </tr>
    ));

    let suspendedReqTable = (
      <table style={{ fontSize: "0.9em" }}>
        <tr>
          <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Paese</th>&nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Località</th>&nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Indirizzo</th>&nbsp;&nbsp;&nbsp;
        </tr>
        {rowsSuspendedList}
      </table>
    );

    let anulledRequestCommentP = (
      <p
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontSize: "0.95em",
          fontWeight: "bold",
        }}
      >
        Non sono presenti richieste annullate
      </p>
    );
    let suspendedRequestCommentP = (
      <p
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontSize: "0.95em",
          fontWeight: "bold",
        }}
      >
        Non sono presenti richieste sospese
      </p>
    );

    let rowsCanceldMonitoringList = this.state.canceldMonitoringList.map(
      (req) => (
        <tr>
          <td style={{ paddingBottom: "10px" }}>{req.customerId}</td>
          &nbsp;&nbsp;&nbsp;
          <td style={{ paddingBottom: "10px" }}>{req.ragioneSociale}</td>
          &nbsp;&nbsp;&nbsp;
        </tr>
      )
    );
    let canceledMonitoringReqTable = (
      <table style={{ fontSize: "0.9em" }}>
        <tr>
          <th style={{ paddingBottom: "14px" }}>Customer ID</th>
          &nbsp;&nbsp;&nbsp;
          <th style={{ paddingBottom: "14px" }}>Ragione Sociale</th>
          &nbsp;&nbsp;&nbsp;
        </tr>
        {rowsCanceldMonitoringList}
      </table>
    );
    let canceldMonitoringRequestCommentP = (
      <p
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontSize: "0.95em",
          fontWeight: "bold",
        }}
      >
        Non ci sono presenti richieste di cancellazione del monitoraggio
      </p>
    );

    return (
      <div>
        {this.state.alert}

        <div style={incompletesDivStyle} className="incompletes-div-style">
          <div style={incompletesHeaderStyle}>
            <p style={pHeaderStyle}>LISTA RICHIESTE INCOMPLETE</p>
          </div>

          <p
            style={{
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              fontSize: "1.03em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            RICHIESTE ANNULLATE:
          </p>
          {annulledList.length > 0 ? annulledReqTable : anulledRequestCommentP}

          <br />

          <p
            style={{
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              fontSize: "1.03em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            RICHIESTE SOSPESE:
          </p>
          {suspendedList.length > 0
            ? suspendedReqTable
            : suspendedRequestCommentP}

          <br />

          <p
            style={{
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              fontSize: "1.03em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {/* RICHIESTE DI CANCELAZIONE MONITORAGGIO: */}
            ALTRE RICHIESTE:
          </p>
          {this.state.canceldMonitoringList.length > 0
            ? canceledMonitoringReqTable
            : canceldMonitoringRequestCommentP}

          <br />

          <div style={{ display: suspendedList.length > 0 ? "block" : "none" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "0.9em",
                fontWeight: "bold",
              }}
            >
              Vuoi processare le richieste sospese nonostante i dati siano
              incompleti?
            </p>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                style={{ marginLeft: "70px", fontSize: "13px" }}
                color="secondary"
                onClick={() => {
                  this.setState({ incompletesReqDivIsVisible: false });
                }}
                round
              >
                Chiudi&nbsp;
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                disabled={this.state.forzaInvio}
                style={{ marginRight: "70px", fontSize: "13px" }}
                color="behance"
                onClick={() => {
                  this.sendSuspendedRequests(
                    this.state.currentIncompletedRequestId,
                    this.state.currentIncompletedRequestList
                  );
                  this.setState({ forzaInvio: true });
                }}
                round
              >
                Forza Invio&nbsp;
              </Button>
              <IconButton
                style={{ marginRight: "70px", fontSize: "16px" }}
                color="primary"
                background-color="RoyalBlue"
                aria-label="download"
                onClick={() => {
                  this.downloadRigheIncomplete(
                    this.state.currentIncompletedRequestId
                  );
                }}
              >
                Scarica richieste incomplete&nbsp;
                <CloudDownloadIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: suspendedList.length > 0 ? "none" : "block",
              textAlign: "center",
            }}
          >
            <Button
              style={{ fontSize: "13px" }}
              color="secondary"
              onClick={() => {
                this.setState({ incompletesReqDivIsVisible: false });
              }}
              round
            >
              Chiudi&nbsp;
            </Button>
          </div>
        </div>

        <GridContainer>
          <FormControl fullWidth onKeyPress={this.onKeyUp}>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
            >
              <CustomInput
                success={this.state.nameValid == true}
                error={this.state.nameValid == false}
                labelText="Denominazione"
                inputProps={{
                  value: this.state.name,
                  disabled: this.props.readOnly,
                  type: "text",
                  onChange: (event) => {
                    this.handleChangeName(event);
                  },
                }}
                customStyle={{ minWidth: "200px", paddingTop: "5px" }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <CustomInput
                labelText="Nazione"
                inputProps={{
                  value: this.state.country,
                  disabled: this.props.readOnly,
                  type: "text",
                  onChange: (event) => {
                    this.handleChangeCountry(event);
                  },
                }}
                customStyle={{ minWidth: "200px", paddingTop: "5px" }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data inizio"
                  value={this.state.startDate}
                  //maxDate={this.state.endDate}
                  onChange={(event) => {
                    this.handleChangeStartDate(event);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data fine"
                  value={this.state.endDate}
                  //maxDate={new Date()}
                  //minDate={this.state.startDate}
                  onChange={(event) => {
                    this.handleChangeEndDate(event);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Filtro data
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.dataValue}
                    onChange={this.handleDataFilter}
                  >
                    {this.state.filterDateList.map((item, index) =>
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>)}
                    {/*<MenuItem key={-1} name={"-1"} value="Data Creazione">
                      Data Creazione
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Data Modifica">
                      Data Modifica
                    </MenuItem>*/}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Filtro applicazione
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.extractValue}
                    onChange={this.handleApplicationFilter}
                  >
                    {this.state.applicationFilterList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Filtro esportato
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.extractValue}
                    onChange={this.handleExportFilter}
                  >
                    {this.state.filterExportList.map((item, index) =>
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>)}
                    {/*
                    <MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Esportata">
                      Esportata
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="Non esportato">
                      Non Esportata
                    </MenuItem>*/}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Filtra inviata
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.sentValue}
                    onChange={this.handleSentFilter}
                  >
                    {this.state.filterSentList.map((item, index) =>
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>)}
                    {/* 
                    <MenuItem key={-1} name={"-1"} value="TUTTI">
                      Tutti
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Inviata">
                      Inviata
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="Non Inviata">
                      Non Inviata
                    </MenuItem>*/}
                  </Select>
                </FormControl>

                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Filtra stato
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.statoValue}
                    onChange={this.handleStateFilter}
                  >
                    {this.state.filterStateList.map((item, index) =>
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>)}
                    {/*<MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="RICHIESTA">
                      RICHIESTA
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="APPROFONDIRE">
                      APPROFONDIRE
                    </MenuItem>
                    <MenuItem key={2} name={"2"} value="ELABORAZIONE">
                      ELABORAZIONE
                    </MenuItem>
                    <MenuItem key={3} name={"3"} value="ERRORE">
                      ERRORE
                    </MenuItem>
                    <MenuItem key={4} name={"4"} value="COMPLETATA">
                      COMPLETATA
                    </MenuItem>
                    <MenuItem key={5} name={"5"} value="ANNULLATA">
                      ANNULLATA
                    </MenuItem>
                    <MenuItem key={6} name={"6"} value="SOSPESA">
                      SOSPESA
                    </MenuItem>*/}
                  </Select>
                </FormControl>

                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Filtro monitoraggio
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.monitoraggioValue}
                    onChange={this.handleMonitoraggioFilter}
                  >
                    {this.state.monitoraggioFilterList.map((item, index) =>
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>)}
                    {/*}
                    <MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem
                      key={0}
                      name={"0"}
                      value="Monitoraggio Differente"
                    >
                      Monitoraggio Differente
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="Monitoraggio Uguale">
                      Monitoraggio Uguale
                    </MenuItem>*/}
                  </Select>
                </FormControl>

                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Filtro robotId
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.robotId}
                    onChange={this.handleRobotIdFilter}
                  >
                    {this.state.robotIdFilterList.map((item, index) =>
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>)}
                    {/*<MenuItem key={-1} name={"-1"} value="TUTTI">
                      TUTTI
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="Con robotId">
                      Con robotId
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="Senza robotId">
                      Senza robotId
                    </MenuItem>*/}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Motivo
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue={this.state.motivo}
                    onChange={this.handleMotivoFilter}
                  >
                    {this.state.motivoList.map((item, index) =>
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>)}
                    {/*
                    <MenuItem key={-1} name={"-1"} value="NESSUNO">
                    NESSUNO
                    </MenuItem>
                    <MenuItem key={0} name={"0"} value="DI">
                    DI
                    </MenuItem>
                    <MenuItem key={1} name={"1"} value="SNC">
                    SNC
                    </MenuItem>
                    <MenuItem key={2} name={"2"} value="PNT">
                    PNT
                    </MenuItem>
                    <MenuItem key={3} name={"3"} value="PUC">
                    PUC
                    </MenuItem>
                    <MenuItem key={4} name={"4"} value="RAD">
                    RAD
                    </MenuItem>
                    <MenuItem key={5} name={"5"} value="LIQ">
                    LIQ
                    </MenuItem>
                    <MenuItem key={6} name={"6"} value="DEN">
                    DEN
                    </MenuItem>*/}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    width: "15%",
                    height:"20%",
                    paddingTop: "30px",
                    paddingLeft: "3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                      style={{ marginBottom: "40px", marginLeft: "16%" , fontSize:"12px", marginTop:"-15px"}}
                      id="select-approfondimento"
                  >
                    Numero Monitoraggi
                  </InputLabel>             
                  <input
            id= {''}
            type='number'
            name='numeroMonitoraggi'
            value={this.state.numeroMonitoraggi}
            label=''
            style={{
              width: '30%',
              marginLeft: '20px',
              marginTop: '10px',
              paddingBottom:'0px',
              border: 'none',
              borderBottom: '1px solid #858585',
              height: '20px'
              //   minHeight: '40px'
            }}
            onChange={this.handleFormChange}
            disabled={''}
          />
                </FormControl>
                <FormControl
                  style={{ width: "15%", paddingTop: "16px", marginLeft: "-3%" }}
                  className={classes.formControl}
                >
                  <InputLabel style={{ paddingTop: "8px" }} id="select-modalita-evasione">
                    Filtro Modalità Evasione
                  </InputLabel>
                  <Select
                    labelId="select-modalita-evasione-label"
                    id="select-filter-export"
                    defaultValue={this.state.modalitaEvasioneValue}
                    onChange={this.handleModalitaEvasioneFilter}
                  >
                    {this.state.modalitaEvasioneFilterList?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  style={{ fontSize: "15px", margin: "20px 40px 0px 40px" }}
                  color="success"
                  onClick={() => {
                    this.extractAllCustomersListFromBackend();
                  }}
                  onK
                  round
                  class="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-91 makeStyles-success-101 makeStyles-round-122"
                >
                  Filtra&nbsp;&nbsp;&nbsp;
                  <SearchIcon style={{ fontSize: "28px" }} />
                </Button>
                <FormControl
                  style={{
                    width: "15%",
                    paddingTop: "16px",
                    paddingBottom: "50px",
                    paddingLeft: "3%",
                    marginLeft: "-3%",
                  }}
                  className={classes.formControl}
                >
                  <InputLabel
                    style={{ paddingTop: "8px", marginLeft: "16%" }}
                    id="select-approfondimento"
                  >
                    Menu Operazioni
                  </InputLabel>
                  <Select
                    labelId="select-filter-label"
                    id="select-filter-export"
                    defaultValue="Seleziona operazione"
                  >
                    <MenuItem key={-1} name={"-1"} value="Seleziona operazione">
                      Seleziona operazione
                    </MenuItem>
                    <MenuItem
                      key={0}
                      name={"0"}
                      value="Scarica selezionati"
                      onClick={this.downloadOutputAlert}
                    >
                      Scarica selezionati
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={1}
                      name={"1"}
                      value="Upload soci executives"
                      onClick={() => {
                        this.uploadAndSendMulti();
                      }}
                    >
                      Upload soci executives
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudUploadIcon
                          style={{ margin: "0px 0px 0px 15px" }}
                        />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={2}
                      name={"2"}
                      value="Elabora"
                      onClick={() => {
                        this.uploadFileNuovoTracciato();
                      }}
                    >
                      Elabora
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudUploadIcon
                          style={{ margin: "0px 0px 0px 15px" }}
                        />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={3}
                      name={"3"}
                      value="Invia json selezionati"
                      onClick={this.sendJsonAlert}
                    >
                      Invia json selezionati
                      <IconButton
                        style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={4}
                      name={"4"}
                      value="Upload LUX"
                      onClick={() => {
                        this.upload('Lux');
                      }}
                    >
                      Calcola selezionati LUX
                      <IconButton style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}>
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={4}
                      name={"4"}
                      value="Upload UK"
                      onClick={() => {
                        this.upload('Uk');
                      }}
                    >
                      Calcola selezionati UK
                      <IconButton style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}>
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>

                    <MenuItem
                      key={4}
                      name={"4"}
                      value="Upload ITA"
                      onClick={() => {
                        this.upload('Ita');
                      }}
                    >
                      Calcola selezionati ITA
                      <IconButton style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}>
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={5}
                      name={"5"}
                      value="Ricarica posizioni selezionate"
                      onClick={() => {
                        this.ricaricaPosizioniSelezionate();
                      }}
                    >
                      Ricarica posizioni selezionate
                      <IconButton style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}>
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>
                    <MenuItem
                      key={6}
                      name={"6"}
                      value="Mantieni dati precedenti"
                      onClick={() => {
                        this.mantieniDatiPrecedenti();
                      }}
                    >
                      Mantieni dati precedenti
                      <IconButton style={{ fontSize: "15px", margin: "0px 0px 0px 0px" }}>
                        <BuildIcon />
                      </IconButton>
                    </MenuItem>
                  </Select>
                </FormControl>
              </MuiPickersUtilsProvider>
            </GridItem>
          </FormControl>
          <GridItem xs={12} sm={12} md={12}>
            <FilterableMultiRequestsManagement
              allItemsList={this.state.customersList}
              headersList={headersList}
              fieldsList={fieldsList}
              totalElements={this.state.customersList.length}
              offset={this.state.offSet}
              pageNumber={this.state.pageNumber}
              listener={this}
              order={this.state.order}
              orderBy={this.state.orderBy}
              content={this.state.content}
              numberQueryResults={this.state.numberQueryResults}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  deniedOperation(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
    setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
  }

  setAllList() {
    //GlobalList.setAllList();
    GlobalList.getAllList();
    console.log(this.state.applicationFilterList);
    this.state.motivoList = JSON.parse(localStorage.getItem('motivoList'));
    this.state.filterExportList = JSON.parse(localStorage.getItem('filterExport'));
    this.state.applicationFilterList = JSON.parse(localStorage.getItem('applicationFilter'));
        console.log(this.state.applicationFilterList);

    this.state.filterDateList = JSON.parse(localStorage.getItem('filterDate'));
    this.state.filterSentList = JSON.parse(localStorage.getItem('filterSent'));
    this.state.filterStateList = JSON.parse(localStorage.getItem('filterState'));
    this.state.robotIdFilterList = JSON.parse(localStorage.getItem('robotIdFilter'));
    this.state.monitoraggioFilterList = JSON.parse(localStorage.getItem('monitoraggioFilter'));
    this.state.modalitaEvasioneFilterList = JSON.parse(localStorage.getItem('modalitaEvasioneFilter'));
  }



  sendSuspendedRequests(suspendedReqId, currentIncompletedRequestList) {
    let address = AuthService.getSuspendedReqAddress();

    axiosApiCaller
      .post(address, currentIncompletedRequestList, {
        params: {
          idRequest: suspendedReqId,
        },
      })
      .then((res) => {
        return (window.location.href = "richieste-monitoraggio");
      })
      .catch((error) => {
        console.log("dentro axios, catch");
        console.log(error);
      });
  }

  downloadOutput() {
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazioneMonitoraggio");
    axios
      .post(
        AuthService.getBackendApiForOutput(),
        {
          applicationName: "TEE",
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValueMonitoraggio"),
          sentValue: localStorage.getItem("sentValueMonitoraggio"),
          stateValue: localStorage.getItem("statoValueMonitoraggio"),
          country: localStorage.getItem("countryMonitoraggio"),
          dataValue: localStorage.getItem("dataValueMonitoraggio"),
          monitoraggioValue: localStorage.getItem("monitoraggioValue"),
          robotId: localStorage.getItem("robotIdMonitoraggio"),
          motivo: localStorage.getItem("motivoMonitoraggio"),
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasioneMonitoraggio"),
          rispostaMonitoraggio: true,
        },
        {
          responseType: "blob",

          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        this.hideAlert();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Richieste" + format(new Date(), "dd-MM-yyyy") + ".xlsx"
        );
        link.click();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForOutput(),
                  {
                    applicationName: "TEE",
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount,
                  },
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute(
                    "download",
                    "Richieste" + format(new Date(), "dd-MM-yyyy") + ".xlsx"
                  );
                  link.click();
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  sendJsonOutput() {
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazioneMonitoraggio");
    axios
      .post(
        AuthService.getBackendApiForSendMassiveJson(),
        {
          applicationName: "TEE",
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValueMonitoraggio"),
          sentValue: localStorage.getItem("sentValueMonitoraggio"),
          stateValue: localStorage.getItem("statoValueMonitoraggio"),
          country: localStorage.getItem("countryMonitoraggio"),
          monitoraggioValue: localStorage.getItem("monitoraggioValue"),
          robotId: localStorage.getItem("robotIdMonitoraggio"),
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasione"),
          rispostaMonitoraggio: true,
        },
        {
          //responseType: 'blob',

          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        let title = "";
        if (response.data.success == false) {
          title = "Errori in invio Json";
          this.errorSendAlert(title, response.data.message);
        } else {
          title = "File inviati con successo";
          this.redirectAfterSend(title, response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForSendMassiveJson(),
                  {
                    applicationName: "TEE",
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount,
                  },
                  {
                    //responseType: 'blob',
                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  let title = "";
                  if (response.data.success == false) {
                    title = "Errori in invio Json";
                    this.errorSendAlert(title, response.data.message);
                  } else {
                    title = "File inviati con successo";
                    this.redirectAfterSend(title, response.data.message);
                  }
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }
  handleFormChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleSentAndExported() {
    let temp = [];
    let selectedValue = localStorage.getItem("sentValueMonitoraggio");
    let exportedValue = localStorage.getItem("extractValueMonitoraggio");
    if (selectedValue == "Inviata") {
      if (exportedValue == "TUTTI") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            (cus.sent == true && cus.exported == true) ||
            cus.exported == false
          ) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.exported == true && cus.sent == true) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Non esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == true && cus.exported == false) {
            temp.push(cus);
          }
        }
      }
    } else if (selectedValue == "Non Inviata") {
      if (exportedValue == "TUTTI") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            (cus.sent == false && cus.exported == true) ||
            cus.exported == false
          ) {
            temp.push(cus);
          }
        }
      }
      if (exportedValue == "Esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == false && cus.exported == true) {
            temp.push(cus);
          }
        }
      }
      if (exportedValue == "Non esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == false && cus.exported == false) {
            temp.push(cus);
          }
        }
      }
    } else if (selectedValue == "TUTTI") {
      if (exportedValue == "TUTTI") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            cus.sent == true ||
            (cus.sent == false && cus.exported == true) ||
            cus.exported == false
          ) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Non esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (
            cus.sent == false ||
            (cus.sent == true && cus.exported == false)
          ) {
            temp.push(cus);
          }
        }
      } else if (exportedValue == "Esportata") {
        for (let cus of this.state.tmpCustomersList) {
          if (cus.sent == false || (cus.sent == true && cus.exported == true)) {
            temp.push(cus);
          }
        }
      }
    }
    temp = this.handleExportFilterAfterReload(temp);
    temp = this.handleSentFilterAfterReload(temp);
    this.setState({ customersList: temp });
  }

  handleStateFilter(event) {
    let stateValue = event.target.value;
    let state = this.state.filterStateList
    /*
        let state = [
          "TUTTI",
          "RICHIESTA",
          "APPROFONDIRE",
          "ELABORAZIONE",
          "ERRORE",
          "COMPLETATA",
          "ANNULLATA",
          "SOSPESA",
        ];
    */
    localStorage.setItem(
      "statoValueMonitoraggio",
      state[state.indexOf(stateValue)]
    );
  }

  handleDataFilter(event) {
    let dataValue = event.target.value;

    //let state = ["Data Creazione", "Data Modifica"];
    let state = JSON.parse(localStorage.getItem('filterDate'))
    localStorage.setItem(
      "dataValueMonitoraggio",
      state[state.indexOf(dataValue)]
    );
  }

  handleMonitoraggioFilter(event) {
    let monitoraggioValue = event.target.value;
    //let monitoraggio = this.state.monitoraggioFilterList;
    let monitoraggio = JSON.parse(localStorage.getItem('monitoraggioFilter'));
    /*let monitoraggio = [
      "TUTTI",
      "Monitoraggio Uguale",
      "Monitoraggio Differente",
    ];*/

    localStorage.setItem(
      "monitoraggioValue",
      monitoraggio[monitoraggio.indexOf(monitoraggioValue)]
    );
  }

  handleRobotIdFilter(event) {
    let robotIdValue = event.target.value;

    //let robotId = this.state.robotIdFilterList;
    console.log("robot")
    console.log(localStorage.getItem("robotIdFilter"))
    let robotId = ["TUTTI", "Con robotId", "Senza robotId"];


    localStorage.setItem(
      "robotIdMonitoraggio",
      robotId[robotId.indexOf(robotIdValue)]
    );
  }
  handleModalitaEvasioneFilter(event) {
    let modalitaEvasioneValue = event.target.value;

    let modalitaEvasione = this.state.modalitaEvasioneFilterList;
    //let modalitaEvasioneValue = ["NESSUNA","AUTOMATICA", "OPERATORE_COMPLESSO", "OPERATORE_SEMPLICE", "SCARTO"];
    //let modalitaEvasioneValue = this.state.modalitaEvasioneFilterList;
    localStorage.setItem("modalitaEvasioneMonitoraggio",modalitaEvasione[modalitaEvasione.indexOf(modalitaEvasioneValue)]);

  }
  handleMotivoFilter(event) {
    let motivo = event.target.value;
    let state = this.state.motivoList
    /*
        let state = [
          "NESSUNO",
          "DI",
          "SNC",
          "PNT",
          "PUC",
          "RAD",
          "LIQ",
          "DEN",
        ];
        */

    localStorage.setItem("motivoMonitoraggio", state[state.indexOf(motivo)]);
  }
  handleApplicationFilter(event) {

    //let app = ["TUTTI", "TEE", "VOLKSBANK", "TEE2"];
    let app = this.state.applicationFilterList;
    
    console.log(app)
    localStorage.setItem(
      "applicationValue",
       app[app.indexOf(event.target.value)]
       );
  }

  handleSentFilter(event) {
    let selectedValue = event.target.value;

    //let sent = ["TUTTI", "Inviata", "Non Inviata"];
    let sent = this.state.filterSentList

    let sent_enum = ["TUTTI", "INVIATA", "NON_INVIATA"];

    localStorage.setItem(
      "sentValueMonitoraggio",
      sent[sent.indexOf(selectedValue)]
    );
    //this.handleSentAndExported();

    // let tmp = [];
    // if (selectedValue === "Inviata") {

    //   for (let cus of this.state.tmpCustomersList) {
    //     if (cus.sent === true) {
    //       tmp.push(cus)
    //     }
    //   }
    // }
    // else if (selectedValue === "Non Inviata") {

    //   for (let cus of this.state.tmpCustomersList) {
    //     if (cus.sent === false || cus.sent==null) {
    //       tmp.push(cus)
    //     }
    //   }
    // } else {
    //   tmp = this.state.tmpCustomersList;
    // }
    // tmp=this.handleExportFilterAfterReload(tmp);
    // this.setState({ customersList: tmp });
  }

  handleSentFilterAfterReload(customers) {
    let sent = ["TUTTI", "Inviata", "Non Inviata"];
    let sent_enum = ["TUTTI", "INVIATA", "NON_INVIATA"];

    let selectedValue = localStorage.getItem("sentValueMonitoraggio");
    //  this.handleSentAndExported();

    let tmp = [];
    if (!selectedValue) {
      tmp = customers;
    } else if (selectedValue === "Inviata") {
      for (let cus of customers) {
        if (cus.sent === true) {
          tmp.push(cus);
        }
      }
    } else if (selectedValue === "Non Inviata") {
      for (let cus of customers) {
        if (cus.sent === false || cus.sent == null) {
          tmp.push(cus);
        }
      }
    } else {
      tmp = customers;
    }

    return tmp;
  }

  handleExportFilterAfterReload(customers) {
    let exported = ["TUTTI", "Esportata", "Non esportata"];
    let exported_enum = ["TUTTI", "ESPORTATA", "NON_ESPORTATA"];

    let selectedValue = localStorage.getItem("extractValueMonitoraggio");
    //  this.handleSentAndExported();

    let tmp = [];
    if (!selectedValue) {
      tmp = customers;
    } else if (selectedValue === "Esportata") {
      for (let cus of customers) {
        if (cus.exported === true) {
          tmp.push(cus);
        }
      }
    } else if (selectedValue === "Non esportata") {
      for (let cus of customers) {
        if (cus.exported === false) {
          tmp.push(cus);
        }
      }
    } else {
      tmp = customers;
    }
    return tmp;
  }

  handleExportFilter(event) {
    let selectedValue = event.target.value;
    let exported = this.state.filterExportList;

    //let exported = ["TUTTI", "Esportata", "Non esportato"];

    let exported_enum = ["TUTTI", "ESPORTATA", "NON_ESPORTATA"];

    localStorage.setItem(
      "extractValueMonitoraggio",
      exported[exported.indexOf(selectedValue)]
    );
    //this.handleSentAndExported();

    // let tmp = [];
    // if (selectedValue === "Esportata") {

    //   for (let cus of this.state.tmpCustomersList) {
    //     if (cus.exported === true) {
    //       tmp.push(cus)
    //     }
    //   }
    // }
    // else if (selectedValue === "Non esportato") {

    //   for (let cus of this.state.tmpCustomersList) {
    //     if (cus.exported === false) {
    //       tmp.push(cus)
    //     }
    //   }
    // } else {
    //   tmp = this.state.tmpCustomersList;
    // }

    // this.setState({ customersList: tmp });
  }

  extractAllCustomers() { }

  extractAllCustomersListFromBackend() {
    this.setState({ content: false });
    let querySearch = localStorage.getItem("denominazioneMonitoraggio"); //querySearch è quello che ha inserito l'utente in alto a sx (il periodo)
    let appName = AuthService.getApplicationName();
    if (
      appName === "ADMINISTRATION_PANEL" &&
      localStorage.getItem("applicationValue") !== "TUTTI" &&
      localStorage.getItem("applicationValue") !== null
    ) {
      appName = localStorage.getItem("applicationValue");
    }
    axiosApiCaller
      .post(AuthService.getBackendApiTeeAccountingList(), {
        applicationName: appName,
        querySearch: querySearch,
        start: this.state.startDate,
        end: this.state.endDate,
        exportedValue: localStorage.getItem("extractValueMonitoraggio"),
        sentValue: localStorage.getItem("sentValueMonitoraggio"),
        stateValue: localStorage.getItem("statoValueMonitoraggio"),
        country: localStorage.getItem("countryMonitoraggio"),
        dataValue: localStorage.getItem("dataValueMonitoraggio"),
        monitoraggioValue: localStorage.getItem("monitoraggioValue"),
        robotId: localStorage.getItem("robotIdMonitoraggio"),
        motivo: localStorage.getItem("motivoMonitoraggio"),
        rispostaMonitoraggio: true,
        numeroMonitoraggi: this.state.numeroMonitoraggi,
        modalitaEvasioneValue: localStorage.getItem("modalitaEvasioneMonitoraggio")
      })   
      .then((res) => {
        //il then/catch è quello della vecchia fetch, va cambiato (eventualmente)
        if (res.data.success === true) {
          this.state.content = true;

            this.requestHistory=JSON.stringify(res.data.payload);

          let customers = this.formatData(res.data.payload);

          customers = this.createOperationMenu(customers);
          customers = this.setResponseDownload(customers);
          customers = this.setSendJson(customers);

          // customers = this.setUploadIcon(customers)

          if (this.state.currentLoggedUserCanManageVisualizeResult) {
            customers = this.setRequestClickable(customers);
            customers = this.setIncompleteRequestButton(customers);
          }
          customers = this.handleExportFilterAfterReload(customers);
          customers = this.handleSentFilterAfterReload(customers);

          this.setState({ customersList: customers });
          this.setState({ tmpCustomersList: customers });
          this.setState({ totalElements: res.data.totalElements });
          this.setState({ numberQueryResults: res.data.totalElements });
          this.resetFilters({ offSet: res.data.offSet });
          this.resetFilters({ pageNumber: res.data.pageNumber });
          this.setState({ currentMinutesRefreshDelay: 0 });
          this.lastRefreshTime = new Date();
          // setInterval(() => {
          //   this.setState({
          //     currentMinutesRefreshDelay: Math.floor(
          //       (new Date() - this.lastRefreshTime) / 1000 / 60
          //     ),
          //   });
          // }, 30001);
          this.hideAlert();
        } else {
          this.state.content = true;
          this.deniedOperation(
            "Impossibile visualizzare le informazioni riguardanti i clienti.",
            "Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma."
          );
        }
      })
      .catch((error) => {
        this.state.content = true;
        console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
        console.log(JSON.stringify(error.message));
        this.deniedOperation(
          "Impossibile visualizzare le informazioni riguardanti i clienti.",
          "Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma."
        );
      });
  }

  // CLEAN DATA FROM NULL VALUE
  cleanData(rawData) {
    let cleanedData = [];
    if (!rawData || rawData == null) {
      return cleanedData;
    }
    rawData.forEach((rawElement) => {
      if (!rawElement.ragioneSociale || rawElement.ragioneSociale == null) {
        rawElement.ragioneSociale = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
      }
      if (!rawElement.indirizzo || rawElement.indirizzo == null) {
        rawElement.indirizzo = "";
      }
      if (!rawElement.localita || rawElement.localita == null) {
        rawElement.localita = "";
      }
      if (!rawElement.cap || rawElement.cap == null) {
        rawElement.cap = "";
      }
      if (!rawElement.paeseProvincia || rawElement.paeseProvincia == null) {
        rawElement.paeseProvincia = "";
      }
      if (!rawElement.partitaIva || rawElement.partitaIva == null) {
        rawElement.partitaIva = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
      }
      if (
        !rawElement.dataAggiornamento ||
        rawElement.dataAggiornamento == null
      ) {
        rawElement.dataAggiornamento = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
        rawElement.aggiornato = "No";
      } else {
        var d1 = Date.parse(rawElement.dataAggiornamento);
        if (
          AuthService.getLoggedUserPreviousSession() != null &&
          AuthService.getLoggedUserPreviousSession().startTime != null
        ) {
          var d2 = Date.parse(
            AuthService.getLoggedUserPreviousSession().startTime
          );
          rawElement.aggiornato = d1 > d2 ? "Si" : "No";
        } else {
          rawElement.aggiornato = "Si";
        }
        rawElement.dtAggiornamento = this.formatSessionDateTime(
          rawElement.dataAggiornamento
        );
      }
      if (!rawElement.monitoraggio || rawElement.monitoraggio == null) {
        rawElement.monitoraggio = "No";
      }

      if (
        !rawElement.foreignActualOwners ||
        rawElement.foreignActualOwners == null
      ) {
        rawElement.foreignActualOwnersCount = 0;
      } else {
        rawElement.foreignActualOwnersCount =
          rawElement.foreignActualOwners.length;
        rawElement.foreignActualOwners.forEach((ownerElement) => {
          if (!ownerElement.name || ownerElement.name == null) {
            ownerElement.name = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.role || ownerElement.role == null) {
            ownerElement.role = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.dateOfBirth || ownerElement.dateOfBirth == null) {
            ownerElement.dateOfBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (
            !ownerElement.appointedNotifiedOn ||
            ownerElement.appointedNotifiedOn == null
          ) {
            ownerElement.appointedNotifiedOn = "";
          }
          if (!ownerElement.nationality || ownerElement.nationality == null) {
            ownerElement.nationality = "";
          }
          if (
            !ownerElement.countryOfResidence ||
            ownerElement.countryOfResidence == null
          ) {
            ownerElement.countryOfResidence = "";
          }
          if (
            !ownerElement.societaCollegate ||
            ownerElement.societaCollegate == null
          ) {
            ownerElement.societaCollegate = "";
          }
          if (!ownerElement.note || ownerElement.note == null) {
            ownerElement.note = "";
          }
        });
      }
      cleanedData.push(rawElement);
    });
    return cleanedData;
  }

  formatData(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].creationDatetime = this.formatSessionDateTime(
        list[i].creationDatetime
      );
      list[i].lastEditDatetime = this.formatSessionDateTime(
        list[i].lastEditDatetime
      );
    }

    return list;
  }

  formatSessionDateTime(dateTime) {
    //console.log(JSON.stringify(dateTime));
    var date = new Date(dateTime);
    return new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(date);
  }

  setIncompleteRequestButton(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].incompletedRequestButton = (
        <div style={{ minWidth: "140px" }}>
          <RemoveRedEyeRounded
            fontSize="inherit"
            style={{
              fontSize: "30px",
              color: "#757575",
              float: "left",
              cursor: "pointer",
            }}
            onClick={() => {
              this.setCurrentIncompletedReqList(
                list[i].incompletedReqList,
                list[i].cancelMonitoringList,
                list[i].id
              );
            }}
            round
          />
          <span
            style={{
              paddingLeft: "9px",
              marginTop: "5px",
              display: "inline-block",
            }}
          >
            {list[i].incompletedRequests}&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </div>
      );
    }

    return list;
  }

  setCurrentIncompletedReqList(incompleteList, cancelMonitoringList, id) {
    this.setState(
      {
        currentIncompletedRequestList: incompleteList,
        canceldMonitoringList: cancelMonitoringList,
        currentIncompletedRequestId: id,
      },
      () => {
        this.setState({ incompletesReqDivIsVisible: true });
      }
    );
  }

  setRequestClickable(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].clickableId = (
        <Link
          to={{
            pathname: "/consolle/request-result",
            state: { idRequest: list[i].id },
          }}
        >
          <span>{list[i].id}</span>
        </Link>
      );
    }

    return list;
  }

  setResponseDownload(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].responseDownload = (
        <IconButton
          aria-label="download"
          onClick={() => {
            this.getFileWithResults(list[i].id);
          }}
        >
          <CloudDownloadIcon />
        </IconButton>
      );
    }

    return list;
  }

  setSendJson(list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].sent != undefined && list[i].sent == true) {
        //TODO Sostituire con spunta verde come esportata
        list[i].sendJson = (
          <span>
            <FontAwesomeIcon color="green" icon={faCheck} />
          </span>
        );
      } else {
        list[i].sendJson = (
          <IconButton
            aria-label="UnarchiveIcon"
            onClick={() => {
              this.sendJsonConfirmationAlert(list[i].id);
            }}
          >
            <CloudDownloadIcon />
          </IconButton>
        );
      }
    }

    return list;
  }

  createOperationMenu(list) {
    for (var i = 0; i < list.length; i++) {
      let id = list[i].id;
      list[i].operationMenu = (
        <OperationMenu
          menus={[
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => {
                this.downloadFileOfRequest(id);
              }}
            >
              {" "}
              INPUT
              <CloudDownloadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />{" "}
            </p>,

            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => {
                this.downloadInpuWithPlatformId(id);
              }}
            >
              {" "}
              INPUT CON PLATFORMID
              <CloudDownloadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />{" "}
            </p>,

            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.getFileWithResults(id)}
            >
              {" "}
              RISULTATI
              <CloudDownloadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>,
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.uploadAndSend("-partners", id)}
            >
              SOCI{" "}
              <CloudUploadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>,
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.uploadAndSend("-executives", id)}
            >
              EXECUTIVES
              <CloudUploadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>,
            <p
              style={{ fontSize: "14px", padding: "4px 3px" }}
              onClick={() => this.uploadAndSend("-elabora", id)}
            >
              ELABORA
              <CloudUploadIcon
                style={{ float: "right", marginTop: "-2px", color: "#757575" }}
              />
            </p>,
          ]}
          listener={this}
        ></OperationMenu>
      );
    }
    return list;
  }

  sendJsonConfirmationAlert(idRequest) {
    console.log(idRequest);
    let primaryMsg = "Sending Json file...";
    let secondaryMsg = "Are you sure you want to send the file?";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="success"
                  onClick={() => {
                    this.sendJsonOnS3(idRequest);
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="danger"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }

  redirectAfterSend(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    window.location.replace("/consolle/richieste-monitoraggio");
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }

  errorSendAlert(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="danger"
                  onClick={() => {
                    this.hideAlert();
                    window.location.replace("/consolle/richieste-monitoraggio");
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }
  sendJsonOnS3(idRequest) {
    axios
      .get(
        AuthService.getBackendApiTeeSendJsonOnS3() + "?idRequest=" + idRequest,
        {
          // responseType: "blob",
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          console.log("il campo success è true " + response.data.message);
          let title = "Json file sent";
          let message = response.data.message;
          this.redirectAfterSend(title, message);
        } else {
          let title = "Error sending Json";
          let message = response.data.message;
          this.errorSendAlert(title, message);

          console.log("il campo success è false " + response.data.message);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.getBackendApiTeeSendJsonOnS3() +
                  "?idRequest=" +
                  idRequest,
                  {
                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  if (response.data.success === true) {
                    let title = "Json file sent";
                    let message = response.data.message;
                    this.redirectAfterSend(title, message);
                  } else {
                  }
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  getFileWithResults(idRequest) {
    axios
      .get(
        AuthService.getBackendApiTeeResponseFileDownload() +
        "?idRequest=" +
        idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Analysis_results.xlsx");
        //document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.getBackendApiTeeResponseFileDownload() +
                  "?idRequest=" +
                  idRequest,
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "Analysis_results.xlsx");
                  link.click();
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  formatSessionDateTime(dateTime) {
    //console.log(JSON.stringify(dateTime));
    var date = new Date(dateTime);
    return new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(date);
  }

  uploadAndSend(contenutoFile, id) {
    //Mentre carico i soci (partners) non posso caricare executives finché il processo non è terminato
    this.setState({ buttonUploadDisabled: true });
    //this.state.buttonUploadDisabled = true;
    this.setUploadIcon(this.state.customersList);
    (async () => {
      const { value: file } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          // 'accept': 'text/*',
          accept: ".xlsx",
          "aria-label": "Upload file",
        },
      });

      if (file) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("idRichiesta", id);

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let address =
          AuthService.getBackendApiTeeExternalSources() + contenutoFile;
        let address2 =
          AuthService.getBackendApiTeeExternalSources() + "/new-file-upload"; //da cancellare
        let address3 =
          AuthService.getBackendApiTeeExternalSources() +
          "/file-upload" +
          contenutoFile; //da cancellare

        //Mentre carico i soci (partners) non posso caricare executives finché il processo non è terminato
        this.setState({ buttonUploadDisabled: true });
        this.state.buttonUploadDisabled = true;
        this.setUploadIcon(this.state.customersList);

        axios
          .post(address3, formData, {
            headers: headerWithAuth,
          })
          .then((res) => {
            //Quando mi arriva il risultato riabilito i pulsanti
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              if (this.state.alert !== null) {
                this.hideAlert();
              }
              this.showRedirectAlert(title, message);
            } else {
              console.log("CARICAMENTO DOCUMENTO FALLITO");
            }
          })
          .catch((error) => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
          });
      }
    })();
  }

  uploadAndSendMulti() {
    this.setState({ buttonUploadDisabled: true });
    this.setUploadIcon(this.state.customersList);
    (async () => {
      const { value: files } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          accept: ".xlsx",
          multiple: "multiple",
          "aria-label": "Upload file",
        },
      });

      if (files != null ? files.length > 0 : false) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }

        // formData.append("idRichiesta", id);

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let apiUrlToCall =
          AuthService.getBackendApiTeeExternalSources() +
          "/upload-soci-executives";
        this.hideAlert();
        this.caricamento("Upload in corso...");
        axiosApiCaller
          .post(apiUrlToCall, formData, {
            headers: headerWithAuth,
          })

          .then((res) => {
            this.hideAlert();
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              this.showRedirectAlert(title, message);
            } else {
              this.deniedOperation(
                "CARICAMENTO DOCUMENTO FALLITO",
                res.data.message
              );
            }
          })
          .catch((error) => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
            this.deniedOperation(
              "COMUNICATION ERROR WITH APP BACKEND SERVICE..",
              error.message
            );
          });
      }
    })();
  }

  uploadFileNuovoTracciato() {
    this.setState({ buttonUploadDisabled: true });
    this.setUploadIcon(this.state.customersList);
    (async () => {
      const { value: file } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          accept: ".xlsx",
          multiple: "multiple",
          "aria-label": "Upload file",
        },
      });

      if (file != null ? file.length > 0 : false) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        for (let i = 0; i < file.length; i++) {
          formData.append("file", file[i]);
          formData.append("idRichiestaInput", "");
          formData.append("caricamentoMonitoraggio", true);
        }

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let apiUrlToCall =
          AuthService.getBackendApiTeeExternalSources() +
          "/upload-file-nuovo-tracciato";
        this.hideAlert();
        this.caricamento("Upload in corso...");
        axiosApiCaller
          .post(apiUrlToCall, formData, {
            headers: headerWithAuth,
          })

          .then((res) => {
            this.hideAlert();
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              this.showRedirectAlert(title, message);
            } else {
              this.deniedOperation(
                "CARICAMENTO DOCUMENTO FALLITO",
                res.data.message
              );
            }
          })
          .catch((error) => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
            this.deniedOperation(
              "COMUNICATION ERROR WITH APP BACKEND SERVICE..",
              error.message
            );
          });
      }
    })();
  }

  upload(type) {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                {

                }
                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: (event) => {
                      this.handleChangeDownloadAmount(event);
                    },
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.caricamento("Download in corso...");

                    if (type === 'Lux')
                      this.searchLux();
                    else if (type === 'Uk')
                      this.searchUk();
                    else if (type === 'Ita')
                      this.searchIta();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }

  ricaricaPosizioniSelezionate() {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                {

                }
                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: (event) => {
                      this.handleChangeDownloadAmount(event);
                    },
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.ricalcolo("Ricalcolo posizioni in corso...");
                    this.invokeRicaricaPosizioniSelezionate();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }
  
  mantieniDatiPrecedenti() {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                {

                }
                <CustomInput    
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: (event) => {
                      this.handleChangeDownloadAmount(event);
                    },
                  }}
                />
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.invokeMantieniDatiPrecedenti();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>

              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }
  showTimeNoticeAlert(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: "28px" }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }

  showRedirectAlert(primaryMsg, secondaryMsg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Link to="/consolle/richieste-monitoraggio">
                  <Button
                    style={{ fontSize: "13px", margin: "0px 0px 0px 10px" }}
                    color="success"
                    onClick={() => {
                      this.hideAlert();
                    }}
                    round
                  >
                    <CheckCircle style={{ fontSize: "28px" }} />
                    &nbsp;Ok
                  </Button>
                </Link>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }

  uploadAndSend(contenutoFile, id) {
    (async () => {
      const { value: file } = await Swal.fire({
        title: "Upload file...",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Upload",
        reverseButtons: true,
        input: "file",
        inputAttributes: {
          // 'accept': 'text/*',
          accept: ".xlsx",
          "aria-label": "Upload file",
        },
      });

      if (file) {
        let msg1 = "Attenzione";
        let msg2 =
          "In base alla dimensione del file caricato, l'operazione potrebbe richiedere alcuni minuti.";

        this.showTimeNoticeAlert(msg1, msg2);
        let formData = new FormData();
        formData.append("file", file);
        formData.append("idRichiestaInput", id);
        formData.append("caricamentoMonitoraggio", true);

        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";

        let address =
          AuthService.getBackendApiTeeExternalSources() + contenutoFile;
        let address2 =
          AuthService.getBackendApiTeeExternalSources() + "/new-file-upload"; //da cancellare
        let address3 = "";
        if (contenutoFile === "-elabora") {
          address3 =
            AuthService.getBackendApiTeeExternalSources() +
            "/upload-file-nuovo-tracciato";
        } else {
          address3 =
            AuthService.getBackendApiTeeExternalSources() +
            "/file-upload" +
            contenutoFile; //da cancellare
        }

        //Mentre carico i soci (partners) non posso caricare executives finché il processo non è terminato
        this.setState({ buttonUploadDisabled: true });
        this.state.buttonUploadDisabled = true;
        this.setUploadIcon(this.state.customersList);

        axios
          .post(address3, formData, {
            headers: headerWithAuth,
          })
          .then((res) => {
            //Quando mi arriva il risultato riabilito i pulsanti
            this.setState({ buttonUploadDisabled: false });
            this.state.buttonUploadDisabled = false;
            this.setUploadIcon(this.state.customersList);
            if (res.data.success === true) {
              let title = res.data.message;
              let message = "";
              if (this.state.alert !== null) {
                this.hideAlert();
              }
              this.showRedirectAlert(title, message);
            } else {
              console.log("CARICAMETO DEL DOCUMENTO FALLITO");
            }
          })
          .catch((error) => {
            console.log("COMUNICATION ERROR WITH APP BACKEND SERVICE..");
            console.log(JSON.stringify(error.message));
            if (
              error.response &&
              error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
            ) {
              const originalRequest = error.config;
              originalRequest._retry = true;
              let refreshToken2 = localStorage.getItem("loggedRefreshToken");
              let tokenVecchio2 = localStorage.getItem("loggedToken");
              let username2 = localStorage.getItem("loginEmail");
              return axiosApiCaller
                .post(AuthService.getRefreshedToken(), {
                  refreshToken: refreshToken2,
                  tokenVecchio: tokenVecchio2,
                  username: username2,
                })
                .then((rest) => {
                  localStorage.removeItem("loggedToken");
                  localStorage.removeItem("loggedRefreshToken");
                  localStorage.setItem(
                    "loggedToken",
                    rest.data.payload.jwtToken
                  );
                  localStorage.setItem(
                    "loggedRefreshToken",
                    rest.data.payload.jwtTokenRefresh
                  );
                  AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
                  AuthService.setLoggedUserJwtTokenRefresh(
                    rest.data.payload.jwtTokenRefresh
                  );
                  originalRequest.headers["Authorization"] =
                    rest.data.payload.jwtToken;
                  originalRequest.headers["TokenRefresh"] =
                    rest.data.payload.jwtTokenRefresh;
                  axios.defaults.headers["Authorization"] =
                    rest.data.payload.jwtToken;
                  headerWithAuth = AuthService.getAuthHeaderAsObj();
                  axios
                    .post(address3, formData, {
                      headers: headerWithAuth,
                    })
                    .then((res) => {
                      //Quando mi arriva il risultato riabilito i pulsanti
                      this.setState({ buttonUploadDisabled: false });
                      this.state.buttonUploadDisabled = false;
                      this.setUploadIcon(this.state.customersList);
                      if (res.data.success === true) {
                        let title = res.data.message;
                        let message = "";
                        if (this.state.alert !== null) {
                          this.hideAlert();
                        }
                        this.showRedirectAlert(title, message);
                      } else {
                        console.log("CARICAMETO DEL DOCUMENTO FALLITO");
                      }
                    })
                    .catch((error) => {
                      console.log(error.response);
                      AuthService.setLoggedUserJwtToken(null);
                      AuthService.setLoggedUserJwtTokenRefresh(null);
                      return (window.location.href = "/home");
                    });
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            }
          });
      }
    })();
  }

  setUploadIcon(list) {
    for (let i = 0; i < list.length; i++) {
      list[i].uploadSoci = (
        <IconButton
          aria-label="upload soci"
          disabled={this.state.buttonUploadDisabled}
          onClick={(e) => {
            this.uploadAndSend("-partners", list[i].id);
          }}
        >
          <CloudUploadIcon />
        </IconButton>
      );

      list[i].uploadExecutives = (
        <IconButton
          aria-label="upload executives"
          disabled={this.state.buttonUploadDisabled}
          onClick={(e) => {
            this.uploadAndSend("-executives", list[i].id);
          }}
        >
          <CloudUploadIcon />
        </IconButton>
      );
    }

    return list;
  }

  downloadFileOfRequest(idRequest) {
    axios
      .get(
        AuthService.getBackendApiTeeRequestFileDownload() +
        "?idRequest=" +
        idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Input.xlsx");
        link.click();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.getBackendApiTeeRequestFileDownload() +
                  "?idRequest=" +
                  idRequest,
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "Input.xlsx");
                  link.click();
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  downloadInpuWithPlatformId(idRequest) {
    axios
      .get(
        AuthService.downloadInputWithPlatformId() + "?idRequest=" + idRequest,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Input_with_platformId.xlsx");
        link.click();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .get(
                  AuthService.downloadInputWithPlatformId() +
                  "?idRequest=" +
                  idRequest,
                  {
                    responseType: "blob",
                    headers: {
                      Accept: "application/octet-stream",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "Input_with_platformId.xlsx");
                  link.click();
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }
  handleSelected(param, filterType) {
    let tmp = [];
    if (param === "Esportata") {
      for (let cus of this.state.tmpCustomersList) {
        if (cus.exported === true) {
          tmp.push(cus);
        }
      }
    } else if (param === "Non esportata") {
      for (let cus of this.state.tmpCustomersList) {
        if (cus.exported === false) {
          tmp.push(cus);
        }
      }
    } else {
      tmp = this.state.tmpCustomersList;
    }

    this.setState({ customersList: tmp });
  }

  handleChangeStartDate(date) {
    this.setState({ startDate: date });
    localStorage.setItem("startDateMonitoraggio", date);
  }

  handleChangeEndDate(date) {
    this.setState({ endDate: date });
    localStorage.setItem("endDateMonitoraggio", date);
  }

  downloadOutputAlert() {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                {/* <CustomInput
                  // success={this.state.downoadAmount < 1000}
                  // error={this.state.downoadAmount > 1000}
                  labelText='Download Amount'
                  id = "downloadAmount"
                  value='100'
                  onChange={this.handleChangeDownloadAmount}
                  customStyle={{ minWidth: '200px', paddingTop: '5px' }}
                />*/}

                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: (event) => {
                      this.handleChangeDownloadAmount(event);
                    },
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.caricamento("Download in corso...");
                    this.downloadOutput();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }

  sendJsonAlert() {
    let primaryMsg = "Seleziona il numero di posizioni da estrarre";
    let secondaryMsg = "il valore predefinito è 100";
    this.setState({
      alert: (
        <SweetAlert
          info
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: "auto", textAlign: "center" }}>
                <CustomInput
                  labelText="100"
                  inputProps={{
                    type: "text",
                    onChange: (event) => {
                      this.handleChangeDownloadAmount(event);
                    },
                  }}
                />

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="success"
                  onClick={() => {
                    this.hideAlert();
                    this.caricamento("Invio in corso...");
                    this.sendJsonOutput();
                  }}
                  round
                >
                  <CheckCircle
                    style={{ fontSize: "28px" }}
                    disabled={this.state.downoadAmount > 1000}
                  />
                  &nbsp;Ok
                </Button>

                <Button
                  style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }}
                  color="cancel"
                  onClick={() => {
                    this.hideAlert();
                  }}
                  round
                >
                  &nbsp;Cancel
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}
          <br />
        </SweetAlert>
      ),
    });
  }

  handleExportFilter(event) {
    let selectedValue = event.target.value;

    console.log(event.target);
    let exported = ["TUTTI", "Esportata", "Non esportata"];
    let exported_enum = ["TUTTI", "ESPORTATA", "NON_ESPORTATA"];

    localStorage.setItem(
      "extractValueMonitoraggio",
      exported[exported.indexOf(selectedValue)]
    );

    // this.handleSentAndExported();
    /*  console.log('SELECTED VALUE è ' + selectedValue)
    let tmp = []
    if (selectedValue === 'Esportata') {
      for (let cus of this.state.tmpCustomersList) {
        if (cus.exported === true) {
          tmp.push(cus)
        }
      }
    } else if (selectedValue === 'Non esportato') {
      for (let cus of this.state.tmpCustomersList) {
        if (cus.exported === false) {
          tmp.push(cus)
        }
      }
    } else {
      tmp = this.state.tmpCustomersList
    }

    this.setState({ customersList: tmp })*/
  }

  handleChangeDownloadAmount(event) {
    localStorage.setItem("downoadAmount", event.target.value);
    this.setState({ downoadAmount: event.target.value });
  }

  handlePgeChanged(oldPage, newPage, rowsPerPage) {
    let customersFromStorage = JSON.parse(this.requestHistory
    );
    let offSet = newPage * rowsPerPage;
    if (newPage > oldPage) {
      if (
        newPage > 0 &&
        newPage % 2 === 0 &&
        customersFromStorage[newPage * (2 * rowsPerPage)] === undefined
      ) {
        this.getNextPageData(offSet);
      }
    }
    this.setState({ pageNumber: newPage });
  }
  getNextPageData(offSet) {
    let querySearch = this.state.name; //querySearch è quello che ha inserito l'utente in alto a sx
    let appName = AuthService.getApplicationName();
    axiosApiCaller
      .post(AuthService.getBackendApiTeeAccountingList(), {
        applicationName: appName,
        querySearch: querySearch,
        start: this.state.startDate,
        end: this.state.endDate,
        offSet: offSet,
        country: this.state.country,
        rispostaMonitoraggio: true,
        numeroMonitoraggi: this.state.numeroMonitoraggi,
      })
      .then((res) => {
        if (res.data.success === true) {
          let loadedRequest = JSON.parse(
            this.requestHistory
          );

          for (let data of res.data.payload) {
            loadedRequest.push(data);
          }

          this.requestHistory=JSON.stringify(loadedRequest);

          let customers = this.formatData(loadedRequest);

          customers = this.setResponseDownload(customers);

          customers = this.setUploadIcon(customers);
          if (this.state.currentLoggedUserCanManageVisualizeResult) {
            customers = this.setRequestClickable(customers);
            customers = this.setIncompleteRequestButton(customers);
          }
          this.setState({ customersList: customers });
          this.setState({ tmpCustomersList: customers });
          this.setState({ totalElements: res.data.totalElements });
          this.setState({ numberQueryResults: res.data.totalElements });
          this.resetFilters({ offSet: res.data.offSet });
          this.resetFilters({ pageNumber: res.data.pageNumber });
          this.setState({ currentMinutesRefreshDelay: 0 });
        }
      });
  }

  searchLux() {
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazioneMonitoraggio");
    axios
      .post(
        AuthService.getBackendApiForSearchRobot(),
        {
          applicationName: "TEE",
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValueMonitoraggio"),
          sentValue: localStorage.getItem("sentValueMonitoraggio"),
          stateValue: localStorage.getItem("statoValueMonitoraggio"),
          country: localStorage.getItem("countryMonitoraggio"),
          robotId: localStorage.getItem("robotIdMonitoraggio"),
          dataValue: localStorage.getItem("dataValueMonitoraggio"),
          motivo: localStorage.getItem("motivoMonitoraggio"),
          robotType: "LUX",
          rispostaMonitoraggio: true,
          numeroMonitoraggi: this.state.numeroMonitoraggi,
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasioneMonitoraggio")
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        this.hideAlert();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForSearchRobot(),
                  {
                    applicationName: "TEE",
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount,
                  },
                  {
                    responseType: "application/json",

                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  searchUk() {
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazioneMonitoraggio");
    axios
      .post(
        AuthService.getBackendApiForSearchRobot(),
        {
          applicationName: "TEE",
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValueMonitoraggio"),
          sentValue: localStorage.getItem("sentValueMonitoraggio"),
          stateValue: localStorage.getItem("statoValueMonitoraggio"),
          country: localStorage.getItem("countryMonitoraggio"),
          robotId: localStorage.getItem("robotIdMonitoraggio"),
          dataValue: localStorage.getItem("dataValueMonitoraggio"),
          motivo: localStorage.getItem("motivoMonitoraggio"),
          robotType: "UK",
          rispostaMonitoraggio: true,
          numeroMonitoraggi: this.state.numeroMonitoraggi,
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasioneMonitoraggio")
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        this.hideAlert();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForSearchRobot(),
                  {
                    applicationName: "TEE",
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount,
                  },
                  {
                    responseType: "application/json",

                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  searchIta() {
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazioneMonitoraggio");
    axios
      .post(
        AuthService.getBackendApiForSearchRobot(),
        {
          applicationName: "TEE",
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValueMonitoraggio"),
          sentValue: localStorage.getItem("sentValueMonitoraggio"),
          stateValue: localStorage.getItem("statoValueMonitoraggio"),
          country: localStorage.getItem("countryMonitoraggio"),
          robotId: localStorage.getItem("robotIdMonitoraggio"),
          dataValue: localStorage.getItem("dataValueMonitoraggio"),
          motivo: localStorage.getItem("motivoMonitoraggio"),
          robotType: "ITA",
          rispostaMonitoraggio: true,
          numeroMonitoraggi: this.state.numeroMonitoraggi,
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasioneMonitoraggio")
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        this.hideAlert();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForSearchRobot(),
                  {
                    applicationName: "TEE",
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount,
                  },
                  {
                    responseType: "application/json",

                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }


  invokeRicaricaPosizioniSelezionate() {
    let queryString = "?idRequest=" + this.state.name;
    let querySearch = localStorage.getItem("denominazioneMonitoraggio");
    axios
      .post(
        AuthService.getBackendApiForReloadPositions(),
        {
          applicationName: "TEE",
          start:
            this.state.startDate != null ? this.state.startDate : new Date(0),
          end: this.state.endDate != null ? this.state.endDate : new Date(),
          size: this.state.downoadAmount,
          querySearch: querySearch,
          exportedValue: localStorage.getItem("extractValueMonitoraggio"),
          sentValue: localStorage.getItem("sentValueMonitoraggio"),
          stateValue: localStorage.getItem("statoValueMonitoraggio"),
          country: localStorage.getItem("countryMonitoraggio"),
          robotId: localStorage.getItem("robotIdMonitoraggio"),
          dataValue: localStorage.getItem("dataValueMonitoraggio"),
          motivo: localStorage.getItem("motivoMonitoraggio"),
          rispostaMonitoraggio: true,
          numeroMonitoraggi: this.state.numeroMonitoraggi,
          modalitaEvasioneValue: localStorage.getItem("modalitaEvasioneMonitoraggio")
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: AuthService.getLoggedUserJwtToken(),
          },
        }
      )
      .then((response) => {
        this.hideAlert();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          let refreshToken2 = localStorage.getItem("loggedRefreshToken");
          let tokenVecchio2 = localStorage.getItem("loggedToken");
          let username2 = localStorage.getItem("loginEmail");
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2,
            })
            .then((rest) => {
              localStorage.removeItem("loggedToken");
              localStorage.removeItem("loggedRefreshToken");
              localStorage.setItem("loggedToken", rest.data.payload.jwtToken);
              localStorage.setItem(
                "loggedRefreshToken",
                rest.data.payload.jwtTokenRefresh
              );
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken);
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              );
              originalRequest.headers["Authorization"] =
                rest.data.payload.jwtToken;
              originalRequest.headers["TokenRefresh"] =
                rest.data.payload.jwtTokenRefresh;
              axios.defaults.headers["Authorization"] =
                rest.data.payload.jwtToken;
              axios
                .post(
                  AuthService.getBackendApiForReloadPositions(),
                  {
                    applicationName: "TEE",
                    start:
                      this.state.startDate != null
                        ? this.state.startDate
                        : new Date(0),
                    end:
                      this.state.endDate != null
                        ? this.state.endDate
                        : new Date(),
                    size: this.state.downoadAmount,
                  },
                  {
                    responseType: "application/json",

                    headers: {
                      Accept: "application/json",
                      Authorization: AuthService.getLoggedUserJwtToken(),
                    },
                  }
                )
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error.response);
                  AuthService.setLoggedUserJwtToken(null);
                  AuthService.setLoggedUserJwtTokenRefresh(null);
                  return (window.location.href = "/home");
                });
            })
            .catch((error) => {
              console.log(error.response);
              AuthService.setLoggedUserJwtToken(null);
              AuthService.setLoggedUserJwtTokenRefresh(null);
              return (window.location.href = "/home");
            });
        }
      });
  }

  invokeMantieniDatiPrecedenti() {
    const downoadAmount = localStorage.getItem("downoadAmount") || this.state.downoadAmount;
    for (let i = 0; i < downoadAmount; i++) {
      const customer=this.state.customersList[i];
      const idRequest=customer.id;
      axiosApiCaller
        .post(
          AuthService.ripristinoTeeDJS() +
            '?platformId=' + 
            '&idRichiesta='+
            idRequest
        )
        .then(res => {
          console.log(`Chiamata ${i + 1} completata`);
        })
        .catch(error => {
          console.error(`Errore nella chiamata ${i + 1}: `, error);
        });
    }
  
    window.location.href = 'richieste-monitoraggio';
  }  
}
export default withStyles(styles)(RequestHistoryMonitoraggio);
