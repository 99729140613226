import FormControl from '@material-ui/core/FormControl';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import ToggleOn from "@material-ui/icons/ToggleOn";
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import FilterableMultiCustomersManagement from "components/COMMON/FilterableMultiCustomersManagement";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import * as AuthService from 'services/AuthService.js';
import Process from '@material-ui/icons/Settings'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import DetailVisualizer from 'components/COMMON/DetailVisualizer.js'
import CompanyReportVisualizer from 'components/COMMON/CompanyReportVisualizer';
import ReactToPrint from 'react-to-print'
import Print from '@material-ui/icons/Print'
import IconButton from '@material-ui/core/IconButton'
import { Tooltip } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit'
import CachedIcon from '@material-ui/icons/Cached'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import axios from 'axios'
import ReactDOM from 'react-dom'
import { axiosApiCaller, HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED } from 'layouts/AxiosService'
import Swal from 'sweetalert2'
import * as CascoDashboard from 'views/TEE/Dashboard'


const localStyles = {
  icon: {
    verticalAlign: "middle",
    textAlign: "center",
    width: "23px",
    height: "23px",
    top: "0px",
    left: "2px",
    position: "relative"
  },
  actionButton: {
    margin: "5px 5px 5px 5px",
    padding: "0px",
    width: "30px",
    height: "30px",
    minWidth: "auto",
    fontSize: "20px"
  },
  dialogConfirmButton: {
    margin: "0 0 0 5px",
    padding: "20px",
    width: "80px",
    height: "auto",
    minWidth: "auto"
  },
  dialogCancelButton: {
    margin: "0 0 0 5px",
    padding: "7px",
    width: "auto",
    height: "auto",
    minWidth: "auto"
  }
}

const styles = {
  ...localStyles,
  ...dashboardStyles,
  ...checkBoxStyles
}

// COSTANTI PER I VALORI NON DEFINITI O NULLI
export const NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE = '';
export const NULL_STRATEGIA_SUBSTITUTION_VALUE = 'Nessuna Strategia';
export const NULL_STATO_SUBSTITUTION_VALUE = 'Nessun Stato di Elaborazione';
export const NULL_BOOLEAN_DATA_SUBSTITUTION_VALUE = false;

const AUTO_CLOSE_TIME_DENIED_OPERATION = 4000;


class RequestResult extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.lastRefreshTime = new Date();

    this.onKeyUp = this.onKeyUp.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleShowMonitoredChange = this.handleShowMonitoredChange.bind(this);
    this.deniedOperation = this.deniedOperation.bind(this);
    this.handleChangeSelectedElaborationState = this.handleChangeSelectedElaborationState.bind(this);
    
    //localStorage.setItem('itemsForDetail', JSON.stringify(this.props.itemObj))
    this.componentRef = React.createRef()

    if (this.state.itemForDetail)
      this.getListAnalysisRequestResultDocuments(this.state.itemForDetail.platformId);

    this.cancelMode = this.cancelMode.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.viewDocumentOfAnalysisRequestResult = this.viewDocumentOfAnalysisRequestResult.bind(this)
    this.downloadDocumentOfAnalysisRequestResult = this.downloadDocumentOfAnalysisRequestResult.bind(this)
    this.getDocumentTypeList = this.getDocumentTypeList.bind(this)
    this.openDialog = this.openDialog.bind(this)
    this.synchronizeData = this.synchronizeData.bind(this)
    this.hideAlert = this.hideAlert.bind(this);
    
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.extractAllCustomersListFromBackend();
    }
  }

  getInitialState() {
    // debugger;
    return {
      alert: null,
      usersSessionsList: [],
      customersList: [],
      usersSessionsChartLabels: [],
      usersSessionsChartSeries: [],
      usersSessionsChartMax: 100,
      currentMinutesRefreshDelay: 0,
      editMode: false,
      synchronized: false,
      isEditModeUsable: this.props.isEditModeUsable ? this.props.isEditModeUsable : false,

      currentSelectedElaborationState: null,
      itemForDetail: this.props.itemObj,
      isItemForDetailChanged: false,
      listaDocumenti: [],
      pdfData:null,
      ispdfDataChanged: false,
      societaCollegateList: [],
      tipoDocumentiList: [],

    };
  }

  hideAlert() {
    this.setState({ alert: null });
  };

  componentWillMount() {
    if (this.props.itemObj) {
      if (this.props.itemObj.codiceFiscale) {
        let positionDetail = this.extractPositionDetailFromBackend(
          this.props.itemObj.codiceFiscale
        )
      }
    }
  }

  getDocumentTypeList() {
    axiosApiCaller.get(AuthService.postBackendApiDocumenti())
      .then((res) => {
        if (res.data.success === true) {
          this.setState({ tipoDocumentiList: res.data.payload })
        }
        else
          this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');

      })
      .catch((error) => {
        this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
      });
  }

  componentDidMount() {
    this.extractAllCustomersListFromBackend();
    ReactDOM.findDOMNode(this).scrollIntoView()
    this.getDocumentTypeList()
  }


  componentWillUnmount() {
  }


  handleChangeName(event) {
    let newValue = event.target.value;
    this.setState({ name: newValue });
    if (newValue != null) {
      if (newValue.length < 3) {
        this.setState({ nameValid: false });
        this.setState({ errorMessage: 'La lunghezza della denominazione non è valida' });
      } else {
        this.setState({ nameValid: true });
        this.setState({ errorMessage: null });
      }
    } else {
      this.setState({ nameValid: false });
      this.setState({ errorMessage: 'La denominazione non può essere vuota' });
    }
  }
  handleShowMonitoredChange(event) {
    this.setState({ showMonitored: event.target.checked });
    if (event.target.checked) {
      this.showMonitoredLabel = "Includi le posizioni monitorate";
    } else {
      this.showMonitoredLabel = "Escludi le posizioni monitorate";
    }
  };


  handleFiltering(filter) {
  }

  resetFilters() {
    this.setState(
      {
        showMonitored: true
      }
    );
  }

  handleChangeSelectedElaborationState(event) {
    event.preventDefault();
    if (typeof event.target != "undefined") {
      const newValue = event.target.value;
      this.setState({ currentSelectedElaborationState: newValue });
      this.extractAllCustomersListFromBackend();
    }
  }
  changeMode() { this.setState({ editMode: !this.state.editMode }) }

  cancelMode() {
    // debugger;
    let tmpItemForDetail = JSON.parse(localStorage.getItem('itemsForDetail'))
    this.setState({
      isItemForDetailChanged: !this.state.isItemForDetailChanged,
      editMode: !this.state.editMode
    })
    this.setState({ itemForDetail: tmpItemForDetail })
  }
  saveData() {
    let teeDiscoveredJuridicalSubjecBoxDaModificare = {}
    let listaDocumentiDaEliminare = this.componentRef.current.listaDocumentiDaEliminare
    teeDiscoveredJuridicalSubjecBoxDaModificare.cap = this.componentRef.current.cleanedData.cap
    teeDiscoveredJuridicalSubjecBoxDaModificare.customerId = this.componentRef.current.cleanedData.customerId
    teeDiscoveredJuridicalSubjecBoxDaModificare.elaborationMessage = this.componentRef.current.cleanedData.elaborationMessage
    teeDiscoveredJuridicalSubjecBoxDaModificare.elaborationState = this.componentRef.current.cleanedData.elaborationState
    teeDiscoveredJuridicalSubjecBoxDaModificare.foreignActualOwners = this.componentRef.current.cleanedData.foreignActualOwners
    teeDiscoveredJuridicalSubjecBoxDaModificare.indirizzo = this.componentRef.current.cleanedData.indirizzo
    teeDiscoveredJuridicalSubjecBoxDaModificare.localita = this.componentRef.current.cleanedData.localita
    teeDiscoveredJuridicalSubjecBoxDaModificare.paeseProvincia = this.componentRef.current.cleanedData.paeseProvincia
    teeDiscoveredJuridicalSubjecBoxDaModificare.partitaIva = this.componentRef.current.cleanedData.partitaIva
    teeDiscoveredJuridicalSubjecBoxDaModificare.ragioneSociale = this.componentRef.current.cleanedData.ragioneSociale
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggioType = this.componentRef.current.cleanedData.monitoraggioType
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggioFlag = this.componentRef.current.cleanedData.monitoraggioFlag
    teeDiscoveredJuridicalSubjecBoxDaModificare.monitoraggio = this.componentRef.current.cleanedData.monitoraggio
    teeDiscoveredJuridicalSubjecBoxDaModificare.documentazione = this.componentRef.current.cleanedData.documentazione
    teeDiscoveredJuridicalSubjecBoxDaModificare.note = this.componentRef.current.cleanedData.note
    teeDiscoveredJuridicalSubjecBoxDaModificare.platformId = this.componentRef.current.cleanedData.platformId
    teeDiscoveredJuridicalSubjecBoxDaModificare.societaCollegate = this.componentRef.current.cleanedData.societaCollegate
    teeDiscoveredJuridicalSubjecBoxDaModificare.modalita_evasione = this.componentRef.current.cleanedData.modalita_evasione
    teeDiscoveredJuridicalSubjecBoxDaModificare.motivo = this.componentRef.current.cleanedData.motivo
    teeDiscoveredJuridicalSubjecBoxDaModificare.esito = this.componentRef.current.cleanedData.esito
    teeDiscoveredJuridicalSubjecBoxDaModificare.notaMancatoCalcoloTEE = this.componentRef.current.cleanedData.notaMancatoCalcoloTEE
    teeDiscoveredJuridicalSubjecBoxDaModificare.notaMancatoCalcoloCubo = this.componentRef.current.cleanedData.notaMancatoCalcoloCubo
    teeDiscoveredJuridicalSubjecBoxDaModificare.accordo = this.componentRef.current.cleanedData.accordo
    teeDiscoveredJuridicalSubjecBoxDaModificare.rispostaMonitoraggio = this.componentRef.current.cleanedData.rispostaMonitoraggio
    teeDiscoveredJuridicalSubjecBoxDaModificare.societaCollegateList = this.componentRef.current.cleanedData.societaCollegateList
    teeDiscoveredJuridicalSubjecBoxDaModificare.anagrafiche = this.componentRef.current.state.anagrphyList
    teeDiscoveredJuridicalSubjecBoxDaModificare.robotId = this.componentRef.current.cleanedData.robotId
    teeDiscoveredJuridicalSubjecBoxDaModificare.idRichiesta = this.componentRef.current.cleanedData.idRichiesta

    let apiUrlToCall = AuthService.getBackendApiTeeDiscoveredJuridicalSubjectBoxUpdateUrl()

    axiosApiCaller.post(apiUrlToCall, teeDiscoveredJuridicalSubjecBoxDaModificare).then(res => {
      apiUrlToCall = AuthService.postApiForDeleteDocumentsForTeeJuridicalSubject() + '?platformId=' +
        teeDiscoveredJuridicalSubjecBoxDaModificare.platformId + '&idRichiesta=' +
        teeDiscoveredJuridicalSubjecBoxDaModificare.idRichiesta
      axiosApiCaller.post(apiUrlToCall, listaDocumentiDaEliminare).then(res => {
        this.setState({ listaDocumenti: res.data })
        this.componentRef.current.listaDocumentiDaEliminare = []
      }).catch(error => { })

    }).catch(error => { })

    this.changeMode()
    //window.location.reload()
  }



  openDialog(primaryMsg, secondButton, synchronize) {
    this.setState({
      alert: (
        <SweetAlert info style={{ display: "block", marginTop: "140px" }} title={primaryMsg}
          customButtons=
          {
            <React.Fragment>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button style={{ fontSize: "13px", margin: "0px 0px 0px 0px" }} color="success" round
                  onClick={() => {
                    if (synchronize)
                      this.synchronizeData();
                    else
                      this.hideAlert();
                  }}>
                  <CheckCircle style={{ fontSize: "28px" }} disabled={this.state.downoadAmount > 1000} />
                  Ok
                </Button>

                {
                  secondButton &&
                  <Button style={{ fontSize: "13px", marginLeft: "2%" }} color="cancel" onClick={() => { this.hideAlert(); }} round>
                    Cancel
                  </Button>
                }

              </div>
            </React.Fragment>
          }>
        </SweetAlert>
      ),
    });
  }
  synchronizeData() {
    let apiUrlToCall = AuthService.synchronizeRequest()
    let jsonToSend = {
      idRequest: this.componentRef.current.cleanedData.idRichiesta,
      idPlatform: this.componentRef.current.cleanedData.platformId
    }

    axiosApiCaller.post(apiUrlToCall, jsonToSend).then(res => {
      console.log("success", res);
      this.openDialog(res.data.payload, false, false);
      this.setState({ synchronized: res.data.succes ? true : false });

    }).catch(error => {
      console.log(error);
      this.openDialog(error.data.payload, false, false);
    })

  }


  render() {
    const { classes } = this.props;
    let headersList = ["Dettaglio", "Ragione Sociale", "Data aggiornamento", "Stato di elaborazione", "Messaggio elaborazione"];
    let fieldsList = ["ragioneSociale", "dataAggiornamento", "elaborationState", "elaborationMessage"];

    let showMonitoredControl = null;
    if (this.state.showMonitored === true) {
      showMonitoredControl = (<a href="#"><ToggleOn onClick={(event) => this.handleShowNotMonitoredChange(event)} style={{ float: 'right', margin: '0px 0px 0px 0px', width: '34px', height: '34px' }} /></a>);
    }
    let labelMarginLeft = ''
    let labelBackgroundColor = ''
    let labelBorder = ''

    /*
            let monitoraggioInfo = null

        if (this.customersList.monitoraggioFlag === true) {
          monitoraggioInfo = (
            <h4
              style={{ margin: '0px 0px 15px 0px' }}
              className={classes.activatedCardTitle}
            >
              <b>Monitoraggio Attivo</b>
            </h4>
          )
        } else {
          monitoraggioInfo = (
            <h4
              style={{ margin: '0px 0px 15px 0px' }}
              className={classes.deactivatedCardTitle}
            >
              <b>Monitoraggio Non Attivo</b>
            </h4>
          )
        }*/

    switch (this.customersList?.elaborationState) {
      case 'COMPLETATA':
        labelMarginLeft = '86%'
        labelBackgroundColor = 'rgb(52, 168, 83)'
        labelBorder = '1px solid rgb(34, 112, 55)'
        break
      case 'APPROFONDIRE':
        labelMarginLeft = '88%'
        labelBackgroundColor = 'rgb(255, 152, 0)'
        labelBorder = '1px solid rgb(170, 101, 0)'
        break
      case 'ERRORE':
        labelMarginLeft = '90%'
        labelBackgroundColor = 'rgb(215, 2, 6)'
        labelBorder = '1px solid rgb(143, 1, 4)'
        break
      case 'ELABORAZIONE':
        labelMarginLeft = '81%'
        labelBackgroundColor = 'rgb(178, 178, 178)'
        labelBorder = '1px solid rgb(118, 118, 118)'
        break
      case 'RICHIESTA':
        labelMarginLeft = '84%'
        labelBackgroundColor = 'rgb(23, 105, 255)'
        labelBorder = '1px solid rgb(15, 70, 170)'
        break
      case 'SOSPESA':
        labelMarginLeft = '88%'
        labelBackgroundColor = 'rgb(103, 17, 141)'
        labelBorder = '1px solid rgb(68, 11, 94)'
        break
      case 'ANNULLATA':
        labelMarginLeft = '88%'
        labelBackgroundColor = 'rgb(5, 23, 56)'
        labelBorder = '1px solid rgb(3, 15, 37)'
        break
    }
    let labelStyle = {
      display: 'inline-block',
      display: 'inline-block',
      marginLeft: labelMarginLeft,
      padding: '6px',
      padding: '6px',
      background: labelBackgroundColor,
      border: labelBorder,
      borderRadius: '4px'
    }
    const leftDetailsColumn = [
      {
        inverted: false,
        badgeColor: 'danger',
        badgeIcon: Process,
        title: 'Dati di Processo',
        titleColor: 'warning',
        body: (
          <div>
            <h6 className={classes.cardTitle}>
              <b>Monitoraggio</b>: {this.state.customersList[0]?.monitoraggio}
            </h6>
            <h6 className={classes.cardTitle}>
              <b>Data di aggiornamento</b>:{' '}
              {this.state.customersList[0]?.dataAggiornamento}
            </h6>
          </div>
        )
      }
    ]


    const rightDetailsColumn = [
      {
        // Anagraphical data section
        inverted: false,
        badgeColor: 'success',
        badgeIcon: this.props.mainIcon,
        title: 'Dati Anagrafici',
        titleColor: 'success',
        body: (
          <div>
            <h6 style={labelStyle} className={classes.cardTitle}>
              <b style={{ color: 'white' }}>
                {' '}
                {this.state.customersList[0]?.elaborationState}
              </b>
            </h6>
            <div>
              <h3
                style={{ margin: '0px 0px 5px 0px', display: 'inline-block' }}
                className={classes.cardTitle}
              >
                <b>
                  <span>{this.state.customersList[0]?.ragioneSociale}</span>
                </b>
              </h3>
              {/*<span style={{ position: 'relative', bottom: '4px' }}><SmallMenu ragioneSociale={this.cleanedData.ragioneSociale} /></span> */}
            </div>
            <div>
              <h6 className={classes.cardTitle}>
                <b>Partita iva</b>: {this.state.customersList[0]?.partitaIva}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Sede</b>:{' '}
                {this.state.customersList[0]?.indirizzo +
                  ', ' +
                  this.state.customersList[0]?.localita +
                  ', ' +
                  this.state.customersList[0]?.cap +
                  ', ' +
                  this.state.customersList[0]?.paeseProvincia}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Note</b>: {this.state.customersList[0]?.note}
              </h6>

              <h6 className={classes.cardTitle}>
                <b>Stato elaborazione</b>:{' '}
                {this.state.customersList[0]?.elaborationState}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Modalita evasione</b>:{' '}
                {this.state.customersList[0]?.modalita_evasione}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Esito</b>: {this.state.customersList[0]?.esito}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Motivo</b>: {this.state.customersList[0]?.motivo}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Nota Mancato Calcolo TEE</b>:{' '}
                {this.state.customersList[0]?.notaMancatoCalcoloTEE}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Nota Mancato Calcolo Cubo</b>:{' '}
                {this.state.customersList[0]?.notaMancatoCalcoloCubo}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Accordo</b>:{' '}
                {this.state.customersList[0]?.accordo}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>RobotId</b>:{' '}
                {this.state.customersList[0]?.robotId}
              </h6>
              <h6 className={classes.cardTitle}>
                <b>Documentazione</b>:{' '}
                {this.state.customersList[0]?.documentazione
                  ? this.state.customersList[0]?.documentazione == true
                    ? 'Sì'
                    : 'No'
                  : 'No'}
              </h6>

              {this.state.customersList[0]?.numeroMonitoraggi != 0 &&
                <h6 className={classes.cardTitle}>
                  <b>Numero Monitoraggi</b>:{' '}
                  {this.state.customersList[0]?.numeroMonitoraggi}
                </h6>
              }
            </div>



          </div>
        )
      }
    ]

    // debugger;
    return (
      <div>
        {this.state.alert}
        <form id="create-search-form">
          <GridContainer>
            <FormControl fullWidth onKeyPress={this.onKeyUp}>
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}>
                <CustomInput success={this.state.nameValid == true} error={this.state.nameValid == false}
                  labelText="Cerca"
                  inputProps={{
                    value: this.state.name,
                    disabled: this.props.readOnly,
                    type: "text",
                    onChange: (event) => {
                      this.handleChangeName(event);
                    },
                  }}
                  customStyle={{ minWidth: "500px" }}
                />
                <Select MenuProps={{ className: classes.selectMenu, }}
                  classes={{ select: classes.select, }}
                  displayEmpty
                  value={this.state.currentSelectedElaborationState}
                  onChange={this.handleChangeSelectedElaborationState}
                  formControlProps={{ fullWidth: true, }}
                  style={{ marginLeft: "2%", padding: "2px" }}>
                  <MenuItem value={null} classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}>
                    {"Seleziona stato di elaborazione*"}
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value={"APPROFONDIRE"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"APPROFONDIRE"}
                  </MenuItem>
                  <MenuItem
                    key={2}
                    value={"SOSPESA"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"SOSPESA"}
                  </MenuItem>
                  <MenuItem
                    key={3}
                    value={"RICHIESTA"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"RICHIESTA"}
                  </MenuItem>
                  <MenuItem
                    key={4}
                    value={"COMPLETATA"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"COMPLETATA"}
                  </MenuItem>
                  <MenuItem
                    key={5}
                    value={"ERRORE"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"ERRORE"}
                  </MenuItem>
                  <MenuItem
                    key={6}
                    value={"ELABORAZIONE"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"ELABORAZIONE"}
                  </MenuItem>
                  <MenuItem
                    key={7}
                    value={"ANNULLATA"}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                  >
                    {"ANNULLATA"}
                  </MenuItem>
                </Select>
                <Button style={{ fontSize: "15px", margin: "0px 30px 0px 30px" }}
                  color="success" onK round
                  onClick={() => { this.extractAllCustomersListFromBackend(); }}>
                  Cerca
                  <SearchIcon style={{ fontSize: "28px", marginLeft: "10%" }} />
                </Button>
              </GridItem>
            </FormControl>

            <GridItem xs={12} sm={12} md={12}>



              {(this.state.customersList && this.state.customersList.length > 1) && <FilterableMultiCustomersManagement
                allItemsList={this.state.customersList}
                headersList={headersList}
                isEditModeUsable={true}
                fieldsList={fieldsList}
              />}



              {(this.state.customersList && this.state.customersList.length === 1) && this.state.customersList.map((elemento) => {
                localStorage.setItem('itemsForDetail', JSON.stringify(elemento))

                return (
                  <>
                  <div style={{margin:'20px',height: '50px'}}>

              <ReactToPrint trigger={() => {
              return (
                <a href='#'>
                  <Print style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '30px', height: '30px' }} />
                </a>
              )
              }}

              content={() => this.componentRef} />

          {(() => {
              if (this.state.editMode) {
                return (
                  <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }} aria-label='upload'
                    onClick={() => { this.uploadOnS3(this.componentRef.current.cleanedData.platformId, this.componentRef.current.cleanedData.idRichiesta) }}>
                    <CloudUploadIcon />
                  </IconButton>
                )
              }
            })()}
          {(() => {
              if (!this.state.editMode) {
                if (!this.getSendJson()) {
                  return (<div>

                    {
                      this.state.synchronized && AuthService.getApplicationName() === "ADMINISTRATION_PANEL" &&
                      <Tooltip title="Sincronizzazione avvenuta con successo">
                        <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                          color='primary' aria-label='download'>
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    }

                    {
                      !this.state.synchronized &&
                      <Tooltip title="Modifica">
                        <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                          aria-label='download' onClick={() => { this.changeMode() }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    }

                    {
                      !this.state.synchronized && AuthService.getApplicationName() === "ADMINISTRATION_PANEL" &&
                      <Tooltip title="Sincronizza dati">
                        <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                          aria-label='download'
                          onClick={() => { this.openDialog("Sei sicuro di voler sincronizzare le seguenti informazioni?", true, true) }} >
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    }

                    {(() => {
                      // debugger;
                      if (this.state.customersList[0].elaborationState != "RICHIESTA" && !this.state.synchronized) {
                        return (
                          <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                            aria-label='download' onClick={() => { this.downloadPdf(this.state.customersList[0].platformId, this.state.customersList[0].idRichiesta) }}>
                            <PdfIcon />
                          </IconButton>)
                      }
                    })()}
                    {(() => {
                      if (this.customersList?.elaborationState != "RICHIESTA" && !this.state.synchronized) {
                        return (
                          <IconButton style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                            aria-label='download' onClick={() => { this.sendJsonOnS3(this.state.customersList[0].idRichiesta) }}>
                            <UnarchiveIcon />
                          </IconButton>)
                      }
                    })()}
                  </div>
                  )
                }
              }
              else if (this.state.editMode) {
                return (
                  <IconButton color='secondary' style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                    aria-label='download' onClick={() => { this.cancelMode() }}>
                    <CancelIcon id="cancelIcon" />
                  </IconButton>
                )
              }
            })()}
{(() => {
              if (this.state.editMode) {
                return (
                  <IconButton color='primary' id="juridicalSubjectBoxUpdate" aria-label='download'
                    style={{ float: 'right', margin: '-20px 30px 10px 0px', width: '35px', height: '35px' }}
                    onClick={() => { this.saveData() }}>
                    <SaveIcon id="juridicalSubjectBoxUpdateSaveIcon" />
                  </IconButton>)
              }
            })()}
</div>
                    <CompanyReportVisualizer
                      key={1}
                      editMode={this.state.editMode}
                      itemForDetail={elemento}
                      errorMessage={null}
                      /*click={(event) => {
                        event.preventDefault()
                        this.setState({ editMode: true })
                      }}*/
                      click={this.viewDocumentOfAnalysisRequestResult}

                      download={this.downloadDocumentOfAnalysisRequestResult}

                      mainIcon={{}}
                      ref={this.componentRef}
                      listaDocumenti={elemento.documenti}
                      ispdfDataChanged={false}
                      societaCollegateList={elemento.societaCollegateList}
                      isItemForDetailChanged={this.state.isItemForDetailChanged}
                    />
                  </>

                )

              })

              }
              {/*DETTAGLIO DIRETTO WORK IN PROGRESS*/}

              {/* <DetailVisualizer
                simple
                leftDetails={leftDetailsColumn}
                rightDetails={rightDetailsColumn}
                showIcons={true}
                showTitles={true}
              /> */}

            </GridItem>
          </GridContainer>
        </form>
      </div >
    );
  }

  deniedOperation(primaryMsg, secondaryMsg) {
    this.setState({
      alert:
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "140px" }}
          title={primaryMsg}
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: "center" }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color="success"
                  onClick={() => { this.hideAlert() }}
                  round>
                  <CheckCircle style={{ fontSize: '28px' }} />&nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {secondaryMsg}<br />
        </SweetAlert>
    });
    setTimeout(this.hideAlert, AUTO_CLOSE_TIME_DENIED_OPERATION, false);
  }
  handleChangeItem(event) {
    if (event.target.id == 'partitaIva') {
      this.cleanedData.partitaIva = event.target.value
    } else if (event.target.id == 'indirizzo') {
      this.cleanedData.indirizzo = event.target.value
    } else if (event.target.id == 'localita') {
      this.cleanedData.localita = event.target.value
    } else if (event.target.id == 'cap') {
      this.cleanedData.cap = event.target.value
    } else if (event.target.id == 'provincia') {
      this.cleanedData.paeseProvincia = event.target.value
    } else if (event.target.id == 'note') {
      this.cleanedData.note = event.target.value
    } else if (event.target.id == 'robotId') {
      this.cleanedData.robotId = event.target.value
    } else if (event.target.id == 'documentazione') {
      this.cleanedData.documentazione = event.target.checked
      this.setState({ documentazione: event.target.checked }) //Cambiamento di stato
    } else if (event.target.id == 'numeroMonitoraggi') {
      this.cleanedData.numeroMonitoraggi = event.target.value
    }
  }

  extractAllCustomersListFromBackend() {
    let query = this.state.name ? '&querySearch=' + this.state.name : '';
    query = this.state.currentSelectedElaborationState ? query + '&elaborationState=' + this.state.currentSelectedElaborationState : query;
    axiosApiCaller.get(AuthService.getBackendApiTeeRequestResult() + '?idRequest=' + this.props.location.state.idRequest + query)
      .then((res) => {
        if (res.data.success === true) {
          // debugger;

          let customers = this.cleanData(res.data.payload);
          this.setState({ customersList: customers });
          localStorage.setItem("idRichiesta", this.props.location.state.idRequest);
          localStorage.setItem("ValoreSent", this.props.location.state.sendJson);
          console.log(this.state.customersList);
          console.log(this.state.customersList[0].monitoraggio);
          console.log(this.state.customersList[0].dataAggiornamento);
          console.log(this.state.customersList[0].elaborationState);

          // debugger;
          localStorage.setItem("monitoraggioFlag", this.state.customersList[0].monitoraggioFlag);
          localStorage.setItem("elaborationState", this.state.customersList[0].elaborationState);


          this.resetFilters();
          this.setState({ currentMinutesRefreshDelay: 0 });
          // debugger;
          let a = this.customersList?.elaborationState
          this.lastRefreshTime = new Date();
          setInterval(() => {
            this.setState({ currentMinutesRefreshDelay: Math.floor((((new Date()) - this.lastRefreshTime) / 1000) / 60) });
          }, 30001);
          this.hideAlert();
        } else {
          this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.');
        }
      })
      .catch((error) => {
        this.deniedOperation('Impossibile visualizzare le informazioni riguardanti i clienti.', 'Errore nella risposta dal server di analisi, contattare un amministratore di piattaforma.');
      });
  }

  // CLEAN DATA FROM NULL VALUE
  cleanData(rawData) {
    let cleanedData = [];
    if (!rawData || (rawData == null)) {
      return cleanedData;
    }
    rawData.forEach(rawElement => {
      if (!rawElement.ragioneSociale || (rawElement.ragioneSociale == null)) {
        rawElement.ragioneSociale = "Denominazione non disponibile";
      }
      if (!rawElement.indirizzo || (rawElement.indirizzo == null)) {
        rawElement.indirizzo = "";
      }
      if (!rawElement.localita || (rawElement.localita == null)) {
        rawElement.localita = "";
      }
      if (!rawElement.cap || (rawElement.cap == null)) {
        rawElement.cap = "";
      }
      if (!rawElement.paeseProvincia || (rawElement.paeseProvincia == null)) {
        rawElement.paeseProvincia = "";
      }
      if (!rawElement.partitaIva || (rawElement.partitaIva == null)) {
        rawElement.partitaIva = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
      }
      if (!rawElement.elaborationState || (rawElement.elaborationState == null)) {
        rawElement.elaborationState = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
      }
      if (!rawElement.elaborationMessage || (rawElement.elaborationMessage == null)) {
        rawElement.elaborationMessage = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
      }
      if (!rawElement.dataAggiornamento || (rawElement.dataAggiornamento == null)) {
        rawElement.dataAggiornamento = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
        rawElement.aggiornato = 'No';
      } else {
        var d1 = Date.parse(rawElement.dataAggiornamento);
        if (AuthService.getLoggedUserPreviousSession() != null && AuthService.getLoggedUserPreviousSession().startTime != null) {
          var d2 = Date.parse(AuthService.getLoggedUserPreviousSession().startTime);
          rawElement.aggiornato = d1 > d2 ? 'Si' : 'No';
        } else {
          rawElement.aggiornato = 'Si';
        }
        rawElement.dataAggiornamento = this.formatSessionDateTime(rawElement.dataAggiornamento);
      }
      if (!rawElement.monitoraggio || (rawElement.monitoraggio == null)) {
        rawElement.monitoraggio = "No";
      }

      if (!rawElement.foreignActualOwners || (rawElement.foreignActualOwners == null)) {
        rawElement.foreignActualOwnersCount = 0;
      } else {
        rawElement.foreignActualOwnersCount = rawElement.foreignActualOwners.length;
        rawElement.foreignActualOwners.forEach(ownerElement => {
          if (!ownerElement.name || (ownerElement.name == null)) {
            ownerElement.name = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.bnfcOwnerType || (ownerElement.bnfcOwnerType == null)) {
            ownerElement.bnfcOwnerType = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.bnfcOwnerLegalForm || (ownerElement.bnfcOwnerLegalForm == null)) {
            ownerElement.bnfcOwnerLegalForm = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.bnfcTown || (ownerElement.bnfcTown == null)) {
            ownerElement.bnfcTown = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.countryOfResidence || (ownerElement.countryOfResidence == null)) {
            ownerElement.countryOfResidence = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.nationality || (ownerElement.nationality == null)) {
            ownerElement.nationality = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.bnfcDateBirth || (ownerElement.bnfcDateBirth == null)) {
            ownerElement.bnfcDateBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.bnfcPostalCode || (ownerElement.bnfcPostalCode == null)) {
            ownerElement.bnfcPostalCode = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.appointedNotifiedOn || (ownerElement.appointedNotifiedOn == null)) {
            ownerElement.appointedNotifiedOn = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.documentazione || (ownerElement.documentazione == null)) {
            ownerElement.documentazione = NULL_BOOLEAN_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.note || (ownerElement.note == null)) {
            ownerElement.note = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.role || (ownerElement.role == null)) {
            ownerElement.role = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.dateOfBirth || (ownerElement.dateOfBirth == null)) {
            ownerElement.dateOfBirth = NULL_TEXTUAL_DATA_SUBSTITUTION_VALUE;
          }
          if (!ownerElement.appointedNotifiedOn || (ownerElement.appointedNotifiedOn == null)) {
            ownerElement.appointedNotifiedOn = "";
          }
          if (!ownerElement.nationality || (ownerElement.nationality == null)) {
            ownerElement.nationality = "";
          }
          if (!ownerElement.countryOfResidence || (ownerElement.countryOfResidence == null)) {
            ownerElement.countryOfResidence = "";
          }
          if (!ownerElement.societaCollegate || (ownerElement.societaCollegate == null)) {
            ownerElement.societaCollegate = "";
          }
          if (!ownerElement.note || (ownerElement.note == null)) {
            ownerElement.note = "";
          }
        });
      }
      cleanedData.push(rawElement);
    });
    return cleanedData;
  }
  downloadPdf(platformId, idRichiesta) {
   
    let headerWithAuth = AuthService.getAuthHeaderAsObj();
    const { modelloApplicativo: modelloApplicativo } = Swal.fire({
      title: 'Seleziona un modello applicativo',
      input: 'select',
      inputOptions: {
        'ModelloApplicativo': {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
          7: '7',
          8: '8',
          9: '9',
          10: '10'
        },
      },
      inputPlaceholder: '',
      showCancelButton: true,
      inputValidator: (modelloApplicativo) => {
        return new Promise((resolve) => {
          if (modelloApplicativo) {
            let queryString = '?platformId=' + platformId + '&idRichiesta=' + idRichiesta + '&modelloApplicativo=' + modelloApplicativo;
            axios.post(AuthService.postApiForDownloadDocumentForTeeJuridicalSubject() + queryString, { platformId, idRichiesta,modelloApplicativo }, {
              headers: headerWithAuth
            })
              .then(res => {
                console.log(res.data)
              })
              .catch(error => {
                this.deniedOperation(
                  'Impossibile visualizzare le informazioni',
                  'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.'
                )
              })
              resolve()
          } else {
            resolve('Devi selezionare un modello applicativo')
          }
        })
      }
    })
    
    if (modelloApplicativo) {
      
    }

  
  }
  sendJsonOnS3(idRequest) {
    axios.get(AuthService.getBackendApiTeeSendJsonOnS3() + '?idRequest=' + idRequest,
      {
        headers: {
          Accept: 'application/json',
          Authorization: AuthService.getLoggedUserJwtToken()
        }
      }
    )
      .then(response => {
        if (response.data.success === true) {
          let title = 'Json file sent'
          let message = response.data.message
          this.redirectAfterSend(title, message)
        } else {
          let title = 'Error sending Json'
          let message = response.data.message
          this.errorSendAlert(title, message)
        }
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === HTTP_GONE_ERROR_CODE_FOR_TOKEN_EXPIRED
        ) {
          const originalRequest = error.config
          originalRequest._retry = true
          let refreshToken2 = localStorage.getItem('loggedRefreshToken')
          let tokenVecchio2 = localStorage.getItem('loggedToken')
          let username2 = localStorage.getItem('loginEmail')
          return axiosApiCaller
            .post(AuthService.getRefreshedToken(), {
              refreshToken: refreshToken2,
              tokenVecchio: tokenVecchio2,
              username: username2
            })
            .then(rest => {
              localStorage.removeItem('loggedToken')
              localStorage.removeItem('loggedRefreshToken')
              localStorage.setItem('loggedToken', rest.data.payload.jwtToken)
              localStorage.setItem(
                'loggedRefreshToken',
                rest.data.payload.jwtTokenRefresh
              )
              AuthService.setLoggedUserJwtToken(rest.data.payload.jwtToken)
              AuthService.setLoggedUserJwtTokenRefresh(
                rest.data.payload.jwtTokenRefresh
              )
              originalRequest.headers['Authorization'] =
                rest.data.payload.jwtToken
              originalRequest.headers['TokenRefresh'] =
                rest.data.payload.jwtTokenRefresh
              axios.defaults.headers['Authorization'] =
                rest.data.payload.jwtToken
              axios
                .get(
                  AuthService.getBackendApiTeeSendJsonOnS3() +
                  '?idRequest=' +
                  idRequest,
                  {
                    headers: {
                      Accept: 'application/json',
                      Authorization: AuthService.getLoggedUserJwtToken()
                    }
                  }
                )
                .then(response => {
                  if (response.data.success === true) {
                    let title = 'Json file sent'
                    let message = response.data.message
                    this.redirectAfterSend(title, message)
                  } else {
                    let title = 'Error sending Json'
                    let message = response.data.message
                    this.errorSendAlert(title, message)
                  }
                })
            })
            .catch(error => {
              console.log(error.response)
              AuthService.setLoggedUserJwtToken(null)
              AuthService.setLoggedUserJwtTokenRefresh(null)
              return (window.location.href = '/home')
            })
        }
      })
  }
  formatSessionDateTime(dateTime) {
    var date = new Date(dateTime);
    return (new Intl.DateTimeFormat("it", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(date));
  }
  errorSendAlert(primaryMsg, secondaryMsg) {
    Swal.fire({
      title: primaryMsg,
      text: secondaryMsg,
      icon: "error",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      reverseButtons: true,
    })
  }

  redirectAfterSend(primaryMsg, secondaryMsg) {
    Swal.fire({
      title: primaryMsg,
      text: secondaryMsg,
      icon: "success",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      reverseButtons: true,
    }).then(() => {
      // debugger;
      if (this.state.customersList[0]?.rispostaMonitoraggio) {
        window.location.replace('/consolle/richieste-monitoraggio');
      } else {
        window.location.replace('/consolle/storico-richieste');
      }
    });
  }
  getSendJson() {
    if (localStorage.getItem('ValoreSent') === 'true') {
      return true
    }
    return false
  }

  uploadOnS3(platformId, idRichiesta) {
    let options = ""
    for (let tipoDocument of this.state.tipoDocumentiList) {
      options = options + '<option value="' + tipoDocument.nome_documento + '">' + tipoDocument.nome_documento + '</option>'
    }
    ; (async () => {
      const { value: file } = await Swal.fire({
        title: 'Upload File',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Upload',
        reverseButtons: true,
        input: 'file',
        html:
          '<div style="margin-left:-122px"><p style="padding-right:5px;display:inline-block">Choose document type</p><select style="font-size:large" name="doc_type" id="swal-input1" >' + options + '</select></div>',

      })

      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        // formData.append("tipoDocumento",document.getElementById('swal-input1').value)
        let headerWithAuth = AuthService.getAuthHeaderAsObj();
        headerWithAuth["Content-Type"] = "multipart/form-data";
        let queryString = '?platformId=' + platformId + '&idRichiesta=' + idRichiesta + '&tipoDocumento=' + document.getElementById('swal-input1').value;
        axios.post(AuthService.postApiForUploadDocumentForTeeJuridicalSubject() + queryString, formData, {
          headers: headerWithAuth
        })
          .then(res => {
            this.state.listaDocumenti.push(res.data)
            this.setState({ listaDocumenti: this.state.listaDocumenti })
            //this.showRedirectAlert(title, message);
          })
          .catch(error => {
            this.deniedOperation(
              'Impossibile visualizzare le informazioni riguardanti gli approfondimenti.',
              'Errore di comunicazione con il server di analisi, la sessione potrebbe essere scaduta. Effettuare un nuovo login, se il problema persiste contattare un amministratore di piattaforma.'
            )
          })
      }
    })()
  }


  downloadDocumentOfAnalysisRequestResult(documentoDaScaricare) {
    let queryString =
      '?nameOnS3=' +
      documentoDaScaricare.nameOnS3 +
      '&nomeDefaultForDownloadDialogWindows=' +
      documentoDaScaricare.filename
    axios
      .get(
        AuthService.getBackendApiForAnalysisRequestResultFileDownload() +
        queryString,
        {
          responseType: 'blob',
          headers: {
            Authorization: AuthService.getLoggedUserJwtToken()
          }
        }
      )
      .then(response => {
        var headers = new Headers(response.headers)
        var contentDisposition = headers.get('Content-Disposition')
        var indiceFilename = contentDisposition.search('=')
        var nomeDefault = contentDisposition.substring(
          indiceFilename + 1,
          contentDisposition.length
        )
        var extension = nomeDefault.substring(nomeDefault.lastIndexOf('.') + 1)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nomeDefault)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => console.log(error))
  }

  viewDocumentOfAnalysisRequestResult(documentoDaScaricare) {
    let queryString = '?nameOnS3=' + documentoDaScaricare.nameOnS3 + '&nomeDefaultForDownloadDialogWindows='
      + documentoDaScaricare.filename
    axios.get(AuthService.getBackendApiForAnalysisRequestResultFileDownloadBase64() + queryString,
      {
        headers: {
          Authorization: AuthService.getLoggedUserJwtToken()
        }
      }
    )
      .then(response => {
        var headers = new Headers(response.headers)
        var contentDisposition = headers.get('Content-Disposition')
        var indiceFilename = contentDisposition.search('=') //filename=nomeFileIniziale...Mi prendo l'indice dell'uguale e faccio substring
        var nomeDefault = contentDisposition.substring(
          indiceFilename + 1,
          contentDisposition.length
        )
        var extension = nomeDefault.substring(nomeDefault.lastIndexOf('.') + 1)
        if (extension && extension.localeCompare('pdf') !== 0) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', nomeDefault)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setState({ pdfData: response.data })
          this.setState({ ispdfDataChanged: !this.state.ispdfDataChanged })
        }
      })
      .catch(error => console.log(error))
  }

  extractPositionDetailFromBackend(positionFiscalCode) {
    let appName = AuthService.getApplicationName()
    let apiUrlToCall = ''

    if (this.props.itemObj.personaFisica && this.props.itemObj.personaFisica === true)
      apiUrlToCall = AuthService.getBackendApiUtpPersonDetail()
    else
      apiUrlToCall = AuthService.getBackendApiUtpCompanyDetail()

    axiosApiCaller.post(apiUrlToCall, { applicationName: appName, fiscalCode: positionFiscalCode }).then(res => {
      if (res.data.success === true) {
        this.setState({ itemForDetail: res.data.payload })
        if (
          res.data.payload.personaFisica &&
          res.data.payload.personaFisica === true
        ) {
          this.setState({
            typeForDetail: CascoDashboard.PERSONA_FISICA_LABEL
          })
        } else {
          this.setState({
            typeForDetail: CascoDashboard.PERSONA_GIURIDICA_LABEL
          })
        }
        this.setState({ errorMessage: null })
      }
      else
        this.setState({ errorMessage: res.data.message })
    })
      .catch(error => {
        this.setState({ errorMessage: error.message })
      })
  }

}

export default withStyles(styles)(RequestResult);
