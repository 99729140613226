import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import styles from "assets/jss/material-dashboard-pro-react/components/accordionStyle.js";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";



const useStyles = makeStyles(styles);

export default function AnagraphyAccordion(props) {
  const [active, setActive] = React.useState(props.active);


  const [anagraphy, setAnagraphy] = React.useState(      {"pk" : "",
  "ragione_sociale" : "",
  "stato" : "",
  "indirizzo" : "",
  "citta" : "",
  "piva" : "",
  "descrizione_forma_giuridica" : "",
  "forma_giuridica" : "",
  "post_code" : "",
  "province" : ""});
  const [isPkEditable, setIsPkEditable] = useState(true);
  const [isRagioneSocialeEditable, setIsRagioneSocialeEditable] = useState(true);
  const pk = useRef(null);
  const ragione_sociale = useRef(null);
  const forma_giuridica = useRef(null);
  const descrizione_forma_giuridica = useRef(null);
  const piva = useRef(null);
  const indirizzo = useRef(null);
  const stato = useRef(null);
  const citta = useRef(null);
  const post_code = useRef(null);
  const province = useRef(null);
  const listaPk = []

  const [ragioneSocialeModificata, setRagioneSocialeModificata] = useState(false);  // Stato per tracciare il cambiamento

  for (let i=0;i<props.collapses.length;i++){
    listaPk.push(props.collapses[i].pk)
  }
  useEffect(() => {
    debugger
    if (anagraphy.pk !== "") {
      setIsRagioneSocialeEditable(false); // Disabilita "ragione_sociale" se "pk" è non vuoto
    } else {
      setIsRagioneSocialeEditable(true);  // Riabilita "ragione_sociale" se "pk" è vuoto
    }

    if (anagraphy.ragione_sociale !== "") {
      setIsPkEditable(false);  // Disabilita "pk" se "ragione_sociale" ha un valore
    } else {
      setIsPkEditable(true);   // Riabilita "pk" se "ragione_sociale" è vuoto
    }
  }, [anagraphy.pk, anagraphy.ragione_sociale]); 
  
const addAnagraphy= <form id="create-search-form">
<div style={{ height: "fit-content", display: "flex" }} id="addForm">
  <div style={{ float: "left", paddingLeft: "2%" }} class="col">
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}}  placeholder="Pk*" ref={pk} id="pk" label="Id*" onChange={(event) => changeAnagraphy(event)}  />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={ragione_sociale} id="ragione_sociale" label="Ragione sociale*" placeholder="Ragione sociale*" onChange={(event) => changeAnagraphy(event)} />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={forma_giuridica} id="forma_giuridica" placeholder="Forma giuridica" onChange={(event) => changeAnagraphy(event)} />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={descrizione_forma_giuridica} id="descrizione_forma_giuridica" placeholder="Descrizione forma guridica" onChange={(event) => changeAnagraphy(event)} />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px" }} ref={piva} id="piva" placeholder="Partita IVA" onChange={(event) => changeAnagraphy(event)}  />

  </div>
  <div style={{ float: "right", paddingLeft: "2%" }} class="col">

    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={indirizzo} id="indirizzo" placeholder="Indrizzo" onChange={(event) => changeAnagraphy(event)}  />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={stato} id="stato" placeholder="Stato" onChange={(event) => changeAnagraphy(event)}  />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={citta} id="citta" placeholder="Citta" onChange={(event) => changeAnagraphy(event)}  />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={post_code} id="post_code" placeholder="cap" onChange={(event) => changeAnagraphy(event)}  />
    <input style={{ width: "90%", margin: '2%',border:"none", borderBottom:"1px solid #858585",minHeight: "40px"}} ref={province} id="province" placeholder="Provincia" onChange={(event) => changeAnagraphy(event)}  />
  </div>
</div>
<div style={{ padding: "40px", display: "flex", justifyContent: "center" }}>
  {props.editMode ? <Button
    style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
    color="success"
    onClick={(event) => onClick(event,anagraphy)}
    round
  >
    Salva</Button> : null}

</div>
</form>


  const handleChange = panel => (event, expanded) => {
    setActive(expanded ? panel : -1);
  };
  const classes = useStyles();
  let { collapses } = props;

  useEffect(() => {

  },[collapses])

function onClick(event,ag){
   if(pk.current.value != "" && ragione_sociale.current.value != "" && !listaPk.includes(pk.current.value)){
    props.listener.createAnagraphy(event, ag);

    pk.current.value = ""
    ragione_sociale.current.value = ""
    forma_giuridica.current.value = ""
    descrizione_forma_giuridica.current.value = ""
    piva.current.value = ""
    indirizzo.current.value = ""
    stato.current.value = ""
    citta.current.value = ""
    post_code.current.value = ""
    province.current.value = ""
    setAnagraphy({"pk" : "",
    "ragione_sociale" : "",
    "stato" : "",
    "indirizzo" : "",
    "citta" : "",
    "piva" : "",
    "descrizione_forma_giuridica" : "",
    "forma_giuridica" : "",
    "post_code" : "",
    "province" : ""})
   }
 };

 function setHover(event){

 }
 function changeAnagraphy(event) {
  const { id, value } = event.target;
  setAnagraphy(prev => ({
      ...prev,
      [id]: value
  }));
}


   function handleReset() {
    setAnagraphy({"pk" : "",
    "ragione_sociale" : "",
    "stato" : "",
    "indirizzo" : "",
    "citta" : "",
    "piva" : "",
    "descrizione_forma_giuridica" : "",
    "forma_giuridica" : "",
    "post_code" : "",
    "province" : ""})
  };

  return (
    <div className={classes.root}>
      {
      collapses!=null?
      collapses.map((prop, key) => {
        return (
          <ExpansionPanel defaultExpanded={false}>
            <ExpansionPanelSummary
              classes={{
                content: classes.expansionPanelSummaryContent,
                expandIcon: classes.expansionPanelSummaryExpandIcon
              }}
            >

              <h4 style={{ display: "inline", float: "right" }}>{prop.ragione_sociale}</h4>

            </ExpansionPanelSummary>


            <form id="create-search-form">
              <div style={{ height: "fit-content", display: "flex" }} id="addForm">
                <div style={{ float: "left", paddingLeft: "2%" }} class="col">
                  <TextField style={{ width: "100%", margin: '2%' }} id="pk" value={prop.pk} label="Id*" onChange={(event) => {
    props.listener.handleEditAnagrphyForm(event, key, props.mode);
    if (event.target.value !== "") {
      setIsRagioneSocialeEditable(false); // Disabilita ragione_sociale
    } else {
      setIsRagioneSocialeEditable(true); // Riabilita ragione_sociale
    }
  }} disabled={!props.editMode  || !isPkEditable}  />
                  <TextField 
  style={{ width: "100%", margin: '2%' }} 
  id="ragione_sociale" 
  value={prop.ragione_sociale} 
  label="Ragione sociale*" 
  onChange={(event) => {
    props.listener.handleEditAnagrphyForm(event, key, props.mode);
    
    // Imposta lo stato che traccia il cambiamento di ragione_sociale
    setRagioneSocialeModificata(true);  // Ragione sociale è stata modificata

    if (event.target.value !== "") {
      setIsPkEditable(false); // Disabilita pk
    } else {
      setIsPkEditable(true); // Riabilita pk
    }
  }} 
  disabled={!props.editMode || !isRagioneSocialeEditable}
/>

                  <TextField style={{ width: "100%", margin: '2%' }} id="forma_giuridica" value={prop.forma_giuridica} label="Forma giuridica" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)}  disabled={!props.editMode}/>
                  <TextField style={{ width: "100%", margin: '2%' }} id="descrizione_forma_giuridica" value={prop.descrizione_forma_giuridica} label="Descrizione forma giuridica" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)} disabled={!props.editMode} />
                  <TextField style={{ width: "100%", margin: '2%' }} id="piva" value={prop.piva} label="Partita IVA" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)} disabled={!props.editMode} />

                </div>
                <div style={{ float: "right", paddingLeft: "2%" }} class="col">

                  <TextField style={{ width: "100%", margin: '2%' }} id="indirizzo" value={prop.indirizzo} label="Indrizzo" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)} disabled={!props.editMode} />
                  <TextField style={{ width: "100%", margin: '2%' }} id="stato" value={prop.stato} label="Stato" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)} disabled={!props.editMode} />
                  <TextField style={{ width: "100%", margin: '2%' }} id="citta" value={prop.citta} label="Citta" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)} disabled={!props.editMode} />
                  <TextField style={{ width: "100%", margin: '2%' }} id="post_code" value={prop.post_code} label="cap" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)} disabled={!props.editMode} />
                  <TextField style={{ width: "100%", margin: '2%' }} id="province" value={prop.province} label="Provincia" onChange={(event) => props.listener.handleEditAnagrphyForm(event, key, props.mode)} disabled={!props.editMode} />
                </div>
              </div>
              <div style={{ padding: "40px", display: "flex", justifyContent: "center" }}>
                {props.editMode && !props.addMode ? <Button
                  style={{ fontSize: '15px', margin: '0px 30px 0px 0px', backgroundColor: "red" }}
                  color="warning"
                  onClick={() => props.listener.deleteAnagraphy(key)}
                  round>
                  Elimina
                                        </Button> : null}

                {props.editMode ? <Button
                  style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
                  color="success"
                  onClick={() => {
                    // Se la ragione sociale è stata modificata, esegui il salvataggio e il refresh
                    if (ragioneSocialeModificata) {
                      props.listener.updateAnagraphy(key)
                        .then(() => {
                          // Refresh della pagina solo se ragione_sociale è stata modificata
                          window.location.reload();
                        })
                        .catch((error) => {
                          console.error("Errore durante l'aggiornamento:", error);
                          // Mostra una notifica o un messaggio di errore, se necessario
                        });
                    } else {
                      // Se non è stata modificata, esegui comunque il salvataggio ma senza refresh
                      props.listener.updateAnagraphy(key)
                        .catch((error) => {
                          console.error("Errore durante l'aggiornamento:", error);
                        });
                    }
                  }}
                  round
                >
                  Salva</Button> : null}

              </div>
            </form>

          </ExpansionPanel>
        );
      }):null}



      {props.editMode ? <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary
          classes={{
            content: classes.expansionPanelSummaryContent,
            expandIcon: classes.expansionPanelSummaryExpandIcon
          }}
        >


          {/* <Typography className={classes.heading}> */}
          <FontAwesomeIcon id="aggiungiSocieta" color="green" icon={faPlusSquare} size="3x" style={{ display: "inline", float: "left", paddingRight: "10px", paddingTop: "13px" }} />  <h4 style={{ display: "inline", float: "right" }}>Aggiungi società</h4>
          {/* </Typography> */}
        </ExpansionPanelSummary>


        {addAnagraphy}

      </ExpansionPanel> : null}

    </div>
  );
}



AnagraphyAccordion.defaultProps = {
  active: -1
};

AnagraphyAccordion.propTypes = {
  // index of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired
};
