import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select/Select'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Cancel from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import styles from 'assets/jss/material-dashboard-pro-react/components/accordionStyle.js'
import Button from 'components/CustomButtons/Button.js'
import SocietaCollegateEditForm from 'components/Forms/SocietaCollegateEditForm.js'
import moment from 'moment'
import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

const useStyles = makeStyles(styles)

class TitolareEffettivoAccordion1 extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      completeName: props.foreignActualOwner
        ? props.foreignActualOwner.completeName
        : '',
      role: props.foreignActualOwner ?props.foreignActualOwner.role :'',
      share: props.foreignActualOwner ?props.foreignActualOwner.share ? props.foreignActualOwner.share : props.foreignActualOwner.role:'',
      tipoTee: props.foreignActualOwner ? props.foreignActualOwner.tipoTEE : '',
      dateOfBirth: props.foreignActualOwner
        ? props.foreignActualOwner.dateOfBirth
        : '',
      statoDiNascita: props.foreignActualOwner
        ? props.foreignActualOwner.stato_di_nascita
        : '',
      luogoDiNascita: props.foreignActualOwner
        ? props.foreignActualOwner.luogo_di_nascita
        : '',
      cittadinanza: props.foreignActualOwner
        ? props.foreignActualOwner.cittadinanza
        : '',
      appointedNotifiedOn: props.foreignActualOwner
        ? props.foreignActualOwner.appointedNotifiedOn
        : '',
      statoDiResidenza: props.foreignActualOwner
        ? props.foreignActualOwner.statoDiResidenza
        : '',
      livello: props.foreignActualOwner ? props.foreignActualOwner.livello : '',
      documentazione: props.foreignActualOwner
        ? props.foreignActualOwner.documentazione
        : null,
      note: props.foreignActualOwner ? props.foreignActualOwner.note : '',
      societaCollegateList:
        props.foreignActualOwner &&
        props.foreignActualOwner.catenaSocietaCollegate
          ? props.foreignActualOwner.catenaSocietaCollegate
          : [],
      alert: null,
      anagrphyList: props.anagrphyList != null ? props.anagrphyList : [],
      indexSocietaCollegata: ''
    }
    this.hideAlert = this.hideAlert.bind(this)
    this.reset = this.reset.bind(this)
    this.deleteSocietaCollegateAlert = this.deleteSocietaCollegateAlert.bind(
      this
    )
  }

  handleFormChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    if (name == 'documentazione') {
      this.setState({ [name]: event.target.checked })
    } else if (name == 'share') {
                 this.setState({ share: value })
                 this.setState({ role: value })
    }else{
      this.setState({
        [name]: value
      })
    }
  }

  addSocietaCollegate = societaCollegate => {
    let societaCollegateList = this.state.societaCollegateList
    societaCollegateList.push(societaCollegate)
    this.setState({ societaCollegateList: societaCollegateList })
  }

  deleteSocietaCollegate = index => {
    let sl = this.state.societaCollegateList
    sl.splice(this.state.indexSocietaCollegata, 1)
    this.setState({ societaCollegateList: sl })
    this.hideAlert()
  }

  hideAlert () {
    this.setState({ alert: null })
  }

  deleteSocietaCollegateAlert (event, index) {
    this.setState({indexSocietaCollegata: index})
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '140px' }}
          title='Sei sicuro di voler eliminare questo articolo'
          customButtons={
            <React.Fragment>
              <div style={{ margin: 'auto', textAlign: 'center' }}>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 0px' }}
                  color='danger'
                  onClick={() => {
                    this.hideAlert()
                  }}
                  round
                >
                  <Cancel style={{ fontSize: '28px' }} />
                  &nbsp;Annulla
                </Button>
                <Button
                  style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                  color='success'
                  onClick={() => {
                    this.deleteSocietaCollegate(event, index)
                  }}
                  round
                >
                  <CheckCircle style={{ fontSize: '28px' }} />
                  &nbsp;Ok
                </Button>
              </div>
            </React.Fragment>
          }
        >
          {/* elimina prima i Titolari effettivi esteri collegati a questa Società */}
          <br />
        </SweetAlert>
      )
    })
  }

  formatSessionDateTime (dateTime) {
    var formats = [
      moment.ISO_8601,
      "DD/MM/YYYY",
      "MM/DD/YYYY",
      "YYYY/MM/DD",
      "DD/MM/YYYY,HH:mm:ss",
      "MM/DD/YYYY,HH:mm:ss",
      "YYYY/MM/DD,HH:mm:ss",
  ]
    if (dateTime != null && dateTime != undefined) {
      var date = new Date(dateTime)
      var isValid = moment(date,formats, true).isValid()
      if (isValid) {
        return new Intl.DateTimeFormat('it', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }).format(date)
      }
    }
  }

  handleDelete = event => {
    let tee = {}
    tee.completeName = this.state.completeName
    tee.role = this.state.role
    tee.share = this.state.share
    tee.tipoTEE = this.state.tipoTee
    tee.dateOfBirth =this.state.dateOfBirth
    tee.appointedNotifiedOn = this.state.appointedNotifiedOn
    tee.cittadinanza = this.state.cittadinanza
    tee.luogo_di_nascita = this.state.luogoDiNascita
    tee.stato_di_nascita = this.state.statoDiNascita
    tee.statoDiResidenza = this.state.statoDiResidenza
    tee.livello = this.state.livello
    tee.documentazione = this.state.documentazione
    tee.note = this.state.note
    tee.catenaSocietaCollegate = this.state.societaCollegateList
    this.props.listener.deleteTeeAlert(tee,this.props.index)
    if (this.props.index == -1) {
      this.reset()
    }
  }

  handleSubmit = event => {
    let tee = {}
  
    tee.completeName = this.state.completeName
    tee.role = this.state.role
    tee.share = this.state.share
    tee.tipoTEE = this.state.tipoTee
    tee.dateOfBirth = this.state.dateOfBirth
    tee.appointedNotifiedOn = this.state.appointedNotifiedOn
    tee.cittadinanza = this.state.cittadinanza
    tee.luogo_di_nascita = this.state.luogoDiNascita
    tee.stato_di_nascita = this.state.statoDiNascita
    tee.statoDiResidenza = this.state.statoDiResidenza
    tee.livello = this.state.livello
    tee.documentazione = this.state.documentazione
    tee.note = this.state.note
    tee.catenaSocietaCollegate = this.state.societaCollegateList
  
    // Mostra un alert se il tipo TEE è 'P' e il livello è "" o diverso da 0
    if (this.state.tipoTee === 'P' && (this.state.livello === "" || this.state.livello != 0)) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Attenzione"
            customButtons={
              <React.Fragment>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                  <Button
                    style={{ fontSize: '13px', margin: '0px 0px 0px 10px' }}
                    color='error'
                    onClick={() => {
                      this.hideAlert()
                    }}
                    round
                  >
                    <CheckCircle style={{ fontSize: '28px' }} />
                    &nbsp;Ok
                  </Button>
                </div>
              </React.Fragment>
            }
          >
            Il livello è pari a {this.state.livello || "vuoto"}. Controlla i dati inseriti.
          </SweetAlert>
        )
      });
  
      // Chiude l'alert automaticamente dopo 3 secondi
      setTimeout(() => {
        this.hideAlert();
      }, 3000);  // 3000 millisecondi = 3 secondi
    }
  
    // Procede comunque al salvataggio
    this.props.listener.updateTee(tee, this.props.index)
    if (this.props.index == -1) {
      this.reset()
    }
  }
  
  

hideAlert() {
    this.setState({ alert: null });
}




  reset () {
    this.setState({
      completeName: '',
      share: '',
      tipoTee: '',
      dateOfBirth: '',
      appointedNotifiedOn: '',
      cittadinanza: '',
      luogoDiNascita: '',
      statoDiNascita: '',
      statoDiResidenza: '',
      livello: '',
      documentazione: '',
      note: '',
      societaCollegateList: [],
      indexSocietaCollegata: ''
    })
  }

  handleChangeDateOfBirth (date) {
    this.setState({ dateOfBirth: date })
  }

  render () {
    var self = this
    return (
      <form name='create-search-form'>
        <div style={{ display: 'flex' }} name='addForm'>
          {this.state.alert}

          <Table aria-label='simple table'>
            <TableBody>
              <TableRow>

                <TableCell component='td' scope='row'>
                  <label>Nome*</label>
                  <input
                    id= {`nome${this.props.index }`}
                    style={{
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                    }}
                    name='completeName'
                    label='completeName*'
                    value={this.state.completeName}
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
                {
                  this.props.editMode &&
                  <TableCell colSpan={2}>
                    <label>Ruolo*</label>
                    <input
                      id= {`ruolo${this.props.index }`}
                      style={{
                        width: '85%',
                        margin: '2%',
                        border: 'none',
                        borderBottom: '1px solid #858585'
                      }}
                      name='share'
                      label='Ruolo*'
                      value={this.state.share}
                      onChange={this.handleFormChange}
                      disabled={!this.props.editMode}
                    />
                  </TableCell>
                }
                {!this.props.editMode &&
                  <TableCell colSpan={2}>
                    <label>Ruolo*</label>
                    <input
                      id= {`ruolo${this.props.index }`}
                      style={{
                        width: '85%',
                        margin: '2%',
                        border: 'none',
                        borderBottom: '1px solid #858585'
                        // minHeight: '40px'
                      }}
                      // placeholder='Ruolo*'
                      name='role'
                      label='Ruolo*'
                      value={this.state.role}
                      onChange={this.handleFormChange}
                      disabled={!this.props.editMode}
                    />
                  </TableCell>
                }
                <TableCell>
                  <label>Tipo TEE*</label>
                  <Select
                    id= {`tipoTEE${this.props.index }`}
                    labelId='demo-simple-select-label'
                    label='Tipo TEE'
                    name='tipoTee'
                    value={this.state.tipoTee}
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                    style={{ width: '50px', marginLeft: '12px' }}
                  >
                    <MenuItem key={1} value={'Q'}>
                      {'Q'}
                    </MenuItem>
                    <MenuItem key={2} value={'P'}>
                      {'P'}
                    </MenuItem>
                  </Select>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component='td' scope='row'>
                  <label>Data di nascita</label>
                  <input
                    id= {`dataDiNascita${this.props.index }`}
                    style={{
                      // width: '90%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    name='dateOfBirth'
                    // placeholder='Data di nascita'
                    value={this.state.dateOfBirth}
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />

                  {
                    
                  }
                </TableCell>
                <TableCell component='td' scope='row'>
                  <label>Stato di nascita</label>
                  <input
                    id= {`statoDiNascita${this.props.index }`}
                    style={{
                      // width: '90%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    value={this.state.statoDiNascita}
                    name='statoDiNascita'
                    // placeholder='Stato di nascita'
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
                <TableCell component='td' scope='row'>
                  <label>Luogo di nascita</label>
                  <input
                    id= {`luogoDiNascita${this.props.index }`}
                    style={{
                      // width: '90%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    value={this.state.luogoDiNascita}
                    name='luogoDiNascita'
                    // placeholder='Luogo di nascita'
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
                <TableCell component='td' scope='row'>
                  <label>Cittadinanza</label>
                  <input
                    id= {`cittadinanza${this.props.index }`}
                    style={{
                      // width: '90%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    value={this.state.cittadinanza}
                    name='cittadinanza'
                    // placeholder='Cittadinanza'
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component='td' scope='row'>
                  <label>Notificato Il</label>
                  <input
                    id= {`notificatoIl${this.props.index }`}
                    style={{
                      // width: '90%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    value={this.state.appointedNotifiedOn}
                    name='appointedNotifiedOn'
                    // placeholder='Notificato Il'
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
                <TableCell component='td' scope='row'>
                  <label>Stato di residenza</label>
                  <input
                    id= {`statoDiResidenza${this.props.index }`}
                    style={{
                      // width: '90%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    value={this.state.statoDiResidenza}
                    name='statoDiResidenza'
                    // placeholder='Stato di residenza'
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
                <TableCell component='td' scope='row'>
                  <label>Livello</label>
                  <input
                    id= {`livello${this.props.index }`}
                    style={{
                      // width: '90%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    value={this.state.livello}
                    name='livello'
                    // placeholder='Livello'
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
                <TableCell component='td' scope='row'>
                  <label>Documentazione</label>
                  <FormControlLabel
                    id= {`documentazione${this.props.index }`}
                    control={
                      <Checkbox
                        name='documentazione'
                        color='primary'
                        onChange={this.handleFormChange}
                      />
                    }
                    checked={this.state.documentazione}
                    // label='Documentazione'
                    labelPlacement='top'
                    fontSize={10}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component='td' scope='row' colspan={4}>
                  <label>Note</label>
                  <input
                    id= {`note${this.props.index }`}
                    style={{
                      width: '92.5%',
                      margin: '2%',
                      border: 'none',
                      borderBottom: '1px solid #858585'
                      // minHeight: '40px'
                    }}
                    value={this.state.note}
                    name='note'
                    // placeholder='Note'
                    onChange={this.handleFormChange}
                    disabled={!this.props.editMode}
                  />
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </div>

        <div style={{ width: '100%', display: 'flex', marginTop: '23px' }}>
          <div
            style={{
              display: 'inline-block',
              marginLeft: '17px',
              float: 'left',
              marginTop: '10px'
            }}
          >
            <label>Societa Collegate</label>
          </div>
          <div
            style={{
              width: '86.5%',
              display: 'inline-block',
              marginLeft: '10px',
              float: 'left'
            }}
          >
            {this.state.societaCollegateList
              ? this.state.societaCollegateList.map((societa, index) => {
                  let sc = this.props.anagrphyList.find(
                    elem => elem.ragione_sociale == societa.ragioneSociale
                  )
                  return (
                    <SocietaCollegateEditForm
                      id= {`editForm${this.props.index+index}`}
                      anagrphyList={self.props.anagrphyList}
                      societaCollegate={societa}
                      disabled={true}
                      editMode={self.props.editMode}
                      index={index}
                      indexParent={this.props.index}
                      societaCollegateAnagraphy={sc}
                      deleteSocietaCollegateAlert={
                        this.deleteSocietaCollegateAlert
                      }
                    />
                  )
                })
              : null}

            {this.props.editMode ? (
              <SocietaCollegateEditForm
                id= {`viewForm${this.props.index }`}
                index={-1}
                indexParent={this.props.index}
                anagrphyList={this.props.anagrphyList}
                editMode={this.props.editMode}
                addSocietaCollegate={this.addSocietaCollegate}
              />
            ) : null}
          </div>
        </div>

        <div
          style={{
            padding: '40px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {this.props.editMode ? (
            <Button
              id= {`elimina${this.props.index }`}
              style={{
                fontSize: '15px',
                margin: '0px 30px 0px 0px',
                backgroundColor: 'red'
              }}
              color='warning'
              onClick={this.handleDelete}
              round
              disabled={
                this.state.completeName == '' ||
                this.state.completeName == undefined ||
                this.state.role == '' ||
                this.state.role == undefined ||
                ((this.state.tipoTee == '' ||
                  this.state.tipoTee == undefined) &&
                  this.props.index == -1)
              }
            >
              Elimina
            </Button>
          ) : null}

          {this.props.editMode ? (
            <Button
              id= {`salva${this.props.index }`}
              style={{ fontSize: '15px', margin: '0px 30px 0px 0px' }}
              color='success'
              onClick={this.handleSubmit}
              round
              disabled={
                this.state.completeName == '' ||
                this.state.completeName == undefined ||
                this.state.role == '' ||
                this.state.role == undefined ||
                ((this.state.tipoTee == '' ||
                  this.state.tipoTee == undefined) &&
                  this.props.index == -1)
              }
            >
              Salva
            </Button>
          ) : null}
        </div>
      </form>
    )
  }
}
export default TitolareEffettivoAccordion1
